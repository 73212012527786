import React from "react";
import { NavLink } from "react-router-dom";

// import AOS from "aos";
// import "aos/dist/aos.css";
import whoweare from "../assets/images/about/who-we-are.webp";
import whatwedo from "../assets/images/about/what-we-do.webp";
import Careers from "../assets/images/about/careers.webp";
import Culture from "../assets/images/about/culture.webp";
import Growth from "../assets/images/about/growth.webp";
import Diversity from "../assets/images/about/diversity.webp";
import Community from "../assets/images/about/community.webp";
import { Helmet } from "react-helmet";
import Svg from "./svg";

function AboutUs() {
  const ServiceCard = ({ icon, title, details }) => {
    return (
      <div className="mb-3 md:mb-3 xl:mb-5 bg-white shadow-md hover:shadow-xl md:px-0 xl:px-0 border-2  transition duration-300 ease-in-out transform hover:scale-105">
        <div className="md:flex justify-start mb-4 items-start top-1/2">
          <img src={icon} alt="Service Icon" className="w-full" />
        </div>
        <div className="ml-2 mr-2">
          <h1 className="ml-2 mr-2 mb-2 text-2xl font-light text-black text-left">
            {title}
          </h1>
          <p className="ml-2 mr-2 mb-6  text-black">{details}</p>
        </div>
      </div>
    );
  };

  return (
    <main className="aboutus_main">
      <Helmet>
        {/* Meta Title: About TechSmew Technologies | Innovative Solutions Provider */}
        <title>
          About TechSmew Technologies | Innovative Solutions Provider
        </title>

        {/* Meta Description: Learn more about TechSmew Technologies, a leading provider of innovative solutions in design, IT consulting, cloud services and IT infrastructure. */}
        <meta
          name="description"
          content="Learn more about TechSmew Technologies, a leading provider of innovative solutions in design, IT consulting, cloud services and IT infrastructure."
        />

        {/* Meta Keywords (for reference): About us, company overview, mission, vision, values, history, team, awards */}
        {/* Note: Meta keywords are not used by major search engines for SEO. */}
        <meta
          name="keywords"
          content="About us, company overview, mission, vision, values, history, team, awards"
        />
      </Helmet>
      <div className="relative overflow-hidden bg-[#492196]  pt-[120px] md:pt-[130px] lg:pt-[140px]">
        <div className="max-w-7xl mx-auto">
          <div className=" flex flex-wrap items-center">
            <div className="w-full px-4">
              <div className="hero-content mx-auto text-center">
                <h1 className="mb-8 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug">
                  Design Services for Exceptional User Experiences!
                </h1>
                <p className=" px-4 mx-auto mb-10 font-normal text-base text-[#e4e4e4] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed">
                  Elevate your product's success with our comprehensive design
                  services. Our team of skilled designers and user experience
                  experts will collaborate closely with you to craft innovative
                  solutions that captivate your audience, streamline user
                  journeys and maximize user satisfaction. From user research
                  to prototyping and usability testing, our tailored approach
                  will help you create intuitive and visually stunning
                  experiences that resonate with your target users.{" "}
                </p>
              </div>
            </div>
            
            <Svg />

            <div className="flex items-center justify-center absolute bottom-0 z-20  bg-gradient-to-r from-indigo-500 to-orange-500 px-6 pt-1 md:py-2  rounded-t-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8">
              <nav className="flex " aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                  <li>
                    <div className="flex items-center">
                      <NavLink
                        className="text-[9px] md:text-sm font-medium text-gray-100 hover:text-[#f89539] "
                        to="/"
                      >
                        Home
                      </NavLink>
                    </div>
                  </li>
                  <li aria-current="page" className="h-full">
                    <div className="flex items-center text-white ">
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-medium  md:ml-2">
                        About
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-white">
        <div className="max-w-7xl mx-auto my-10 md:pb-18">
          <div className="py-6 md:py-10 mx-6 grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 gap-6 xl:mx-0 lg:mx-10 md:mx-8">
            <div className="flex justify-center items-center">
              <div className="xl:p-0 pt-6">
                <h1 className="text-[2rem] text-black font-light mb-1">
                  Who we are
                </h1>
                <div className="flex flex-nowrap flex-cols-3">
                  <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
                  <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
                  <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
                </div>
                <p className="text-base text-black pt-6">
                  TechSmew is a India-based IT consulting and application
                  development company founded in 2013. We are a team of 50
                  employees, including technical experts and BAs.
                </p>
                <ol className="relative mt-8">
                  <li className="mb-8 ml-0 md:ml-0">
                    <div className="flex items-center border-l-4 h-10 border-[#888686] ">
                      <p className="text-base pl-2 ml-2 text-black ">
                        Supporting clients to expand their business.
                      </p>
                    </div>
                  </li>
                  <li className="mb-8 ml-0 md:ml-0">
                    <div className="flex items-center border-l-4 h-10  border-[#e2f72d]">
                      <p className="text-base ml-2 pl-2 text-black ">
                        Build long-term relationship with our client base.
                      </p>
                    </div>
                  </li>
                  <li className="mb-8 ml-0 md:ml-0">
                    <div className="flex items-center border-l-4 h-10 border-[#e75828] ">
                      <p className="text-base pl-2 ml-2 text-black">
                        Keeping clients updated with trend.
                      </p>
                    </div>
                  </li>
                </ol>
              </div>
            </div>
            <div className="md:flex items-center rounded-lg mx-0 xl:p-0 xl:mx-0 lg:mr-0 md:mx-8 space-y-4 ">
              <div className="relative lg:justify-end">
                <img src={whoweare} alt="who we are" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-[#f2f4fa]">
        <div className="max-w-7xl mx-auto my-10 md:pb-18">
          <div className="py-6 md:py-10 mx-6 grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 gap-8 xl:mx-0 lg:mx-10 md:mx-8 ">
            <div className=" md:flex items-center rounded-lg mx-0 xl:p-0 xl:mx-0 lg:mx-10 md:mx-8 space-y-4 ">
              <div className="relative">
                <img src={whatwedo} alt="whta we do" />
              </div>
            </div>
            <div className="flex justify-center items-center xl:mt-6">
              <div className="xl:p-0 pt-6">
                <h1 className="text-[2rem] text-black font-light mb-1">
                  What we do
                </h1>
                <div className="flex flex-nowrap flex-cols-3">
                  <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
                  <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
                  <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
                </div>
                <ol className="relative mt-10">
                  <li className="mb-8 ml-0 md:ml-0">
                    <div className="flex items-center ">
                      <p className="text-base text-black">
                        We focus on delivering value to clients through
                        integrated, reliable, responsive and cost-effective
                        modern solutions.
                      </p>
                    </div>
                  </li>
                  <li className="mb-8 ml-0  md:ml-0">
                    <div className="flex items-center">
                      <p className="text-base text-black">
                        We hold intellectual experience and expertise in
                        providing solutions for complex IT solution architecting
                        and implementation to projects.
                      </p>
                    </div>
                  </li>
                  <li className="mb-8 ml-0 md:ml-0">
                    <div className="flex items-center">
                      <p className="text-base text-black">
                        With our dynamic, scalable, trusted and responsive
                        solutions, we ensure growth and high value for our
                        clients.
                      </p>
                    </div>
                  </li>
                </ol>
                <div className="flex mb-3 mt-3 text-[#e75828]">
                  <NavLink to="/contact">
                    <button className="bg-[#e75828] border-2 border-[#e75828] hover:bg-orange-700  hover:text-white text-white text-sm md:text-base  font-normal py-2 px-6 flex items-center rounded-full">
                      <span>Schedule Demo</span>
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="max-w-7xl mx-auto my-6 md:pb-18">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8">
            <h1 className="text-[2rem] text-black font-light mb-1">
              How We Connect
            </h1>
            <div className="flex flex-nowrap flex-cols-3 mb-3 ">
              <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
              <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
              <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
            </div>
            <p className="text-base text-black">
              At TechSmew, we believe in the power of connection. Our culture is
              built on fostering equal opportunities for all and providing a
              platform where every voice is heard. We encourage open dialogue,
              contributions, shared moments of joy and a sense of pride and
              empathy that binds us together. 
            </p>
          </div>
          <div className="py-6 md:py-8 mx-6 grid grid-cols-1 xl:grid-cols-4 md:grid-cols-2 gap-6 xl:mx-0 lg:mx-10 md:mx-8">
            <div>
              <ServiceCard
                icon={Culture}
                title="Culture"
                details="We encourage and support everyone to have equal opportunities and time to talk, contribute, joy, pride and Empathy among them."
              />
            </div>
            <div>
              <ServiceCard
                icon={Growth}
                title="Growth"
                details="We grow further together by creating and propelling individual ability and by further developing portrayal at all levels."
              />
            </div>
            <div>
              <ServiceCard
                icon={Diversity}
                title="Diversity"
                details="We create, prioritise and implement the diversity, equity and inclusion (DEI) and pay much greater attention to all of these."
              />
            </div>
            <div>
              <ServiceCard
                icon={Community}
                title="Community"
                details="We, The family who supports not only technical challenges but also non profit supports by extending our hands together."
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-[#492196]">
        <div className="max-w-7xl mx-auto my-10 md:pb-18">
          <div className="py-6 md:py-10 mx-6 grid grid-cols-1 xl:grid-cols-2 md:grid-cols-2 gap-6 xl:mx-0 lg:mx-10 md:mx-8">
           
            <div className="flex items-center">
              <div className=" xl:p-0 pt-6">
                <h1 className="text-[2rem] text-white font-light mb-1">
                  Careers
                </h1>
                <p className="text-base py-3 mb-5 text-white">
                  Join our dynamic team as we work together to craft the next
                  generation of digital experiences. We believe in the power of
                  collaboration and we invite you to bring your unique skills
                  and perspectives to the table. With us, you won't just be an
                  employee; you'll be be a valued contributor to a community
                  that thrives on innovation.
                </p>
                <div className="flex mb-3 mt- text-[#e75828]">
                  <NavLink to="/contact">
                    <button className="bg-[#e75828] hover:bg-orange-700 hover:border-orange-700 border-2 border-[#e75828] text-white hover:text-white text-sm md:text-base py-2 px-6 flex items-center rounded-full">
                      <span>Explore assignments</span>
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>

            <div className=" md:flex items-center rounded-lg mx-0 xl:p-0 xl:mx-0 lg:mr-0 md:mx-8 space-y-4 ">
              <div className="relative justify-end">
                <img src={Careers} className="" alt="carees" />
              </div>
            </div>
            
          </div>
        </div>
      </section>
      <section className="container mb-5 mx-auto max-w-7xl xl:px-0 pb-10">
        <div className="bg-[#f3f6ff] rounded-xl overflow-hidden p-10 mx-6 xl:mx-0 lg:mx-10 md:mx-8">
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 xl:w-4/12 md:offset-5 md:pl-18 m-auto">
              <div className="pt-5 pb-3 pb-md-5 md:px-0 text-center md:text-left">
                <p className="text-[1.5rem] font-normal text-black mb-3">
                  Ready to get started?
                </p>
                <h2 className="md:text-[2rem] text-3xl font-normal text-black pb-3">
                  Are you looking for world-class IT support that helps your{" "}
                  <span className="text-[#0d6efd]">business</span> grow?{" "}
                </h2>
                <NavLink
                  to="/contact"
                  className="bg-[#0d6efd] hover:bg-[#0d6dfdb7] mt-6 mb-2 text-white py-3 text-base px-6 inline-block transition duration-300 ease-in-out rounded-full"
                >
                  Work with us
                </NavLink>
              </div>
            </div>
            <div className="w-full md:w-1/2 xl:w-7/12">
              <div className="relative flex items-center justify-center md:h-full">
                <svg
                  className="hidden md:block absolute top-1/2 left-20 transform -translate-y-1/2"
                  width="868"
                  height="868"
                  viewBox="0 0 868 868"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    opacity="0.15"
                    cx="434"
                    cy="434"
                    r="434"
                    fill="#6366F1"
                  ></circle>
                </svg>
                <img
                  src="/assets/images/main-mail.webp"
                  className="relative z-10 mb-2 my-lg-4"
                  width="382"
                  alt="Illustration"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default AboutUs;
