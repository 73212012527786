import React from 'react'
import { Link } from 'react-router-dom';


const ServiceCardSecondary = ({ image, title, detail, border ,path }) => {
    return (

        <Link
            to={`/case-studies/blog/${path}`}>

            <div className={`transition duration-100 ease-in-out transform hover:scale-105
                            border-b-4 ${border} flex shadow-xl rounded-b-lg rounded-sm items-center
                            md:flex-row md:max-w-lg
                            lg:flex-col xl:border-0 
                            xl:rounded-lg xl:flex-row`}>

                <img className={`hidden object-cover w-full
                                md:rounded-l-lg md:w-48
                                lg:w-full lg:h-[120px] xl:flex lg:rounded-b-none
                                xl:border-r-4 ${border} xl:w-[30%] xl:rounded-l-lg  xl:rounded-tr-none `}
                    src={image} alt="sub-page" />

                <div className={`border-b-1 ${border} xl:w-[70%] flex flex-col justify-between p-2 xl:p-1 xl:pl-2 lg:p-4 leading-normal`}>
                    <h5 className="mb-2   font-light tracking-tight text-gray-900 dark:text-white line-clamp-2">{title}</h5>
                    <p className=" text-gray-700  line-clamp-2">{detail}</p>
                </div>
            </div>
        </Link>
    );
};

export default ServiceCardSecondary;