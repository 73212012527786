import {
  ChartBarSquareIcon,
  CheckBadgeIcon,
  CheckIcon,
  ClipboardDocumentCheckIcon,
  LockClosedIcon,
  ShieldCheckIcon,
  SparklesIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { NavLink } from "react-router-dom";
import CTA from "../../assets/images/cta.webp";
import WorkFlow from "../../assets/images/work_flow.webp";
import LogoDesign from "../../assets/images/digital-marketing/logo-designing-and-branding-services/logo-design.webp";
import BrandStrategy from "../../assets/images/digital-marketing/logo-designing-and-branding-services/brand-strategy.webp";
import VisualIdentity from "../../assets/images/digital-marketing/logo-designing-and-branding-services/visual-identity.webp";
import BrandCollateral from "../../assets/images/digital-marketing/logo-designing-and-branding-services/brand-collateral.webp";
import { Helmet } from 'react-helmet';
import Svg from "../svg";


function Logodesignandbranding() {
  const ServiceCard = ({ icon, title, details }) => {
    return (
      <div className="mb-3 md:mb-3 xl:mb-5 bg-white shadow-md hover:shadow-xl md:px-0 xl:px-0 border-2  transition duration-300 ease-in-out transform hover:scale-105">
        <div className="md:flex justify-start mb-4 items-start top-1/2">
          <img src={icon} alt="Service Icon" className="w-full" />
        </div>
        <div className="ml-2 mr-2">
          <h1 className="ml-2 mr-2 mb-2 text-2xl font-light text-black text-left">
            {title}
          </h1>
          <p className="ml-2 mr-2 mb-6  text-black">{details}</p>
        </div>
      </div>
    );
  };

  return (
    <main className="digitalmarketing_logo_design_and_branding">
      <Helmet>
        {/* Meta Title: Logo Design and Branding | TechSmew Technologies */}
        <title>Logo Design and Branding | TechSmew Technologies</title>

        {/* Meta Description: TechSmew Technology offers logo design and branding services. Create a distinctive logo for your brand. */}
        <meta name="description" content="TechSmew Technology offers logo design and branding services. Create a distinctive logo for your brand." />

        {/* Meta Keywords (for reference): Logo design, branding services, logo creation */}
        {/* Note: Meta keywords are not used by major search engines for SEO. */}
        <meta name="keywords" content="Logo design, branding services, logo creation" />
      </Helmet>
      <div className="relative overflow-hidden bg-[#492196]  pt-[120px] md:pt-[130px] lg:pt-[160px]">
        <div className="max-w-7xl mx-auto">
          <div className=" flex flex-wrap items-center">
            <div className="w-full px-4">
              <div className="hero-content mx-auto text-center">
                <h1 className="mb-8 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug">
                  Elevate Your Brand's Identity with Our Exceptional Logo Design and Branding Solutions
                </h1>
                <p className=" px-4 mx-auto mb-10 font-normal text-base text-[#e4e4e4] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed">
                  Enhance your brand's visibility and recognition with our
                  professional logo design and branding services. At TechSmew
                  Technologies understands the importance of a strong brand.
                  identity in today's competitive market.
                </p>
              </div>
            </div>

            <Svg />

            <div className="flex items-center justify-center absolute bottom-0 z-20  bg-gradient-to-r from-indigo-500 to-orange-500 px-6 pt-1  md:py-2  rounded-t-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8">
              <nav className="flex " aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                  <li>
                    <div className="flex items-center">
                      <NavLink
                        className="text-[9px] md:text-sm font-medium text-gray-100 hover:text-[#f89539] "
                        to="/"
                      >
                        Home
                      </NavLink>
                    </div>
                  </li>
                  <li aria-current="page" className="h-full">
                    <div className="flex items-center text-white ">
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-medium md:ml-2">
                        Solutions
                      </span>
                    </div>
                  </li>
                  <li aria-current="page">
                    <NavLink
                      to="/solutions/digital-marketing"
                      className="flex items-center text-white"
                    >
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-medium md:ml-2">
                        Digital Marketing
                      </span>
                    </NavLink>
                  </li>
                  <li aria-current="page">
                    <div className="flex items-center  text-white">
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-medium md:ml-2">
                        LG&B
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-white">
        <div className="max-w-7xl mx-auto my-6 md:pb-18">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8">
            <h1 className="text-[2rem] text-black text-left font-light mb-1">
              Expert Logo Design and Branding Services
            </h1>
            <div className="flex flex-nowrap flex-cols-3 mb-3 ">
              <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
              <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
              <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
            </div>
            <p className="text-base font-normal text-black">
              Elevate your brand's identity with our expert Logo Design and
              Branding services. Our creative team crafts impactful logos that
              resonate with your audience, while our comprehensive branding
              strategies ensure a consistent and memorable brand presence across
              all platforms. Let us help you make a lasting impression that sets
              you apart.
            </p>
          </div>
          <div className="py-6 md:py-8 mx-6 grid grid-cols-1 xl:grid-cols-4 md:grid-cols-2 gap-6 xl:mx-0 lg:mx-10 md:mx-8">
            <ServiceCard
              icon={LogoDesign}
              title="Logo Design"
              details="We specialise in crafting unique and memorable logos that capture the essence of your brand. Our designers will collaborate with you to understand your vision and create a logo that stands out."
            />
            <ServiceCard
              icon={BrandStrategy}
              title="Brand Strategy"
              details=" We develop comprehensive brand strategies to define your brand's personality, positioning and messaging. This strategy serves as a foundation for all branding efforts."
            />
            <ServiceCard
              icon={VisualIdentity}
              title="Visual Identity"
              details="Our designers will create a cohesive visual identity system, including colour palettes, typography and graphic elements, that aligns with your brand strategy and evokes the desired emotions."
            />
            <ServiceCard
              icon={BrandCollateral}
              title="Brand Collateral"
              details="Enhance your brand presence with professionally designed collateral such as business cards, letterheads, brochures and social media graphics. We ensure that every piece reflects your brand's identity."
            />
          </div>
        </div>
      </section>
      <section className="bg-gray-100 py-10">
        <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-1 gap-0 lg:gap-4 ">
          <div className="md:flex justify-start md:pr-4 xl:pl-0 items-center rounded-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8">
            <div className="col-span-1">
              <img src={WorkFlow} alt="work flow" className="w-full" />
              <div className="pt-4">
                <h1 className="text-[2rem] font-light mb-1">Our Workflow</h1>
              </div>
              <div className="flex flex-nowrap flex-cols-3">
                <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
                <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
                <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
              </div>
              <div className="py-3">
                <h1 className=" text-black">
                  Our website content workflow begins with a deep dive into your
                  business, audience and objectives, shaping our design
                  approach. Our designers then craft diverse logo concepts,
                  tailored to embody your brand. Through iterative
                  collaboration, we refine the selected concept, ensuring it
                  mirrors your identity.
                </h1>
              </div>
            </div>
          </div>
          <div className="col-span-2 mx-12 xl:mx-0 lg:mx-10 md:mx-16 pt-8 xl:ml-10">
            <ol className="relative text-black border-l border-gray-300 dark:border-gray-700 ">
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <CheckIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  We start by getting to know your business, target audience and
                  brand goals. This allows us to understand your unique
                  requirements and design preferences.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <ShieldCheckIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Our designers will create multiple logo concepts based on the
                  information gathered during the discovery phase. We focus on
                  capturing your brand's essence and delivering options that
                  align with your vision.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <LockClosedIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  We work closely with you to refine the chosen logo concept,
                  incorporating your feedback and ensuring it perfectly
                  represents your brand identity.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <ChartBarSquareIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Once the logo is finalised, we develop a comprehensive visual
                  identity system and brand collateral to extend your brand's
                  reach across various platforms.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <SparklesIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Once our QA process is complete, we provide you with a
                  detailed report summarising our findings, recommendations and
                  actionable steps. We collaborate with your team to implement
                  the necessary enhancements, ensuring that your design reaches
                  its full potential.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="max-w-7xl mx-auto my-6 md:pb-18">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8">
            <h1 className="text-[2rem] text-black text-left mb-1 font-light">
              Our Unique Value Proposition
            </h1>
            <div className="flex flex-nowrap flex-cols-3 mb-3 ">
              <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
              <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
              <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
            </div>
            <p className="text-base font-normal text-black">
              Welcome to our world of creativity, where brands come to life
              through the art of logo design and branding. We are your partners
              in shaping perceptions, telling stories and leaving lasting
              impressions.
            </p>
          </div>
          <div className="py-6 md:py-10 grid grid-cols-1 md:grid-cols-3 gap-6 mx-6 lg:mx-10 md:mx-8 xl:mx-0">
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#492196] rounded-lg w-12 h-12">
                  <CheckBadgeIcon className="h-8 w-8 text-white stroke-[0.9]" />
                </div>
              </div>
              <h3 className="text-2xl font-light text-black mb-2 text-center">
                Creativity and Expertise
              </h3>
              <p className="text-black font-normal text-base  text-center">
                Our team of experienced designers combines creativity and
                industry expertise to craft visually appealing and impactful
                logos and brand identities.
              </p>
            </div>
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#492196] rounded-lg w-12 h-12">
                  <ClipboardDocumentCheckIcon className="h-8 w-8 text-white stroke-[0.9]" />
                </div>
              </div>
              <h3 className="text-2xl font-light text-black mb-2 text-center">
                Strategic Approach
              </h3>
              <p className="text-black font-normal text-base  text-center">
                We understand the importance of aligning branding efforts with
                your business goals. Our strategic approach ensures that your
                brand identity resonates with your target audience and supports
                your overall marketing objectives.
              </p>
            </div>
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#492196] rounded-lg w-12 h-12">
                  <UserIcon className="h-8 w-8 text-white stroke-[0.9]" />
                </div>
              </div>
              <h3 className="text-2xl font-light text-black mb-2 text-center">
                Consistency and Versatility
              </h3>
              <p className="text-black font-normal text-base  text-center">
                We create brand identities that are adaptable to various
                applications and mediums, ensuring consistency across all
                channels.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-100">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 py-10 mx-6 lg:mx-10 md:mx-8 xl:mx-0">
            <div className="justify-center items-center pt-5 md:col-span-2">
              <div className="pt-5 pb-3 pb-md-5 md:px-0 text-center md:text-left">
                <p className="text-[1.5rem] font-normal text-black mb-3">
                  Ready to get started?
                </p>
                <h2 className="text-[2rem] font-normal text-black pb-3">
                  Crafting Logos That Tell Your Story
                  {" "}
                  Design a logo that encapsulates your brand's essence.
                </h2>
                <NavLink
                  to="/contact"
                  className="bg-[#0d6efd] hover:bg-[#0d6dfdb7] mt-6 mb-2 text-white py-3 text-base px-6 inline-block transition duration-300 ease-in-out rounded-full"
                >
                  Create Logo
                </NavLink>
              </div>
            </div>
            <div className="md:flex justify-end items-center md:col-span-1">
              <img src={CTA} alt="CTA" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Logodesignandbranding;
