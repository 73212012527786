import { createRoot } from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navigation from "./components/Navigation";
import Home from "./components/Home";
import Footer from "./components/Footer";
import Design from './components/design/design'; 
import UiUxDesign from './components/design/ui-ux-design'; 
import GraphicDesign from './components/design/graphic-design'; 
import WebDesign from './components/design/web-design'; 
import MobileAppDesign from './components/design/mobile-app-design'; 
import UserResearch from './components/design/user-research'; 
import Prototyping from './components/design/prototyping'; 
import InteractionDesign from './components/design/interaction-design'; 
import BrandingandIdentityDesign from './components/design/branding-and-identity-design'; 
import UsabilityConsulting from './components/design/usability-consulting'; 
import DesignStrategy from './components/design/design-strategy'; 
import VisualPrototyping from './components/design/visual-prototyping'; 
import DesignReviewandQA from './components/design/design-review-and-qa'; 
import Platform from './components/platform/platform'; 
import AWSCloudServices from './components/platform/aws-cloud-services'; 
import GoogleCloudServices from './components/platform/google-cloud-services'; 
import MicrosoftAzure from './components/platform/microsoft-azure'; 
import DigitalMarketing from './components/digitalMarketing/digitalMarketing';
import BrandEquityandGrowthOptimization from './components/digitalMarketing/brand-equity-and-growth-optimization';
import BrandStrategyandDevelopment from './components/digitalMarketing/brand-strategy-and-development';
import MarketResearchandAnalysis from './components/digitalMarketing/market-research-and-analysis';
import DigitalMarketingCampaigns from './components/digitalMarketing/digital-marketing-campaigns';
import SearchEngineOptimization from './components/digitalMarketing/search-engine-optimization';
import PayperclickAdvertising from './components/digitalMarketing/pay-per-click-advertising';
import ContentMarketingandCopywriting from './components/digitalMarketing/content-marketing-and-copywriting';
import EmailMarketingCampaigns from './components/digitalMarketing/email-marketing-campaigns';
import InfluencerMarketingandPartnerships from './components/digitalMarketing/influencer-marketing-and-partnerships';
import BrandIdentityDesignandDevelopment from './components/digitalMarketing/brand-identity-design-and-development';
import LogoDesignandBranding from './components/digitalMarketing/logo-design-and-branding';
import CreativeAdvertisingCampaigns from './components/digitalMarketing/creative-advertising-campaigns';
import PublicRelationsandMediaRelations from './components/digitalMarketing/public-relations-and-media-relations';
import EventMarketingandPromotions from './components/digitalMarketing/event-marketing-and-promotions';
import BrandMonitoringandReputationManagement from './components/digitalMarketing/brand-monitoring-and-reputation-management';
import SocialMediaMarketing from './components/digitalMarketing/social-media-marketing';
import ItConsulting from './components/itConsulting/itConsulting';
import AIandAutomation from './components/itConsulting/ai-and-automation';
import DigitalStrategyandTransformation from './components/itConsulting/digital-strategy-and-transformation';
import DigitalOperations from './components/itConsulting/digital-operations';
import BigDataandAnalytics from './components/itConsulting/big-data-and-analytics';
import ERPPlatform from './components/itConsulting/erp-epm-crm-platform-integrations';
import ECommerce from './components/itConsulting/ecommerce-web-portals';
import ProductDevelopment from './components/itConsulting/product-development-and-maintenance';
import DataAnalytics from './components/itConsulting/data-and-analytics';
import CloudSecurity from './components/itConsulting/cloud-security';
import QEA from './components/itConsulting/quality-engineering-and-assurance';
import Enterprise from './components/itConsulting/enterprise-applications';
import Infrastructure from './components/itConsulting/infrastructure-management-services';
import ItSupport from './components/itInfrastructure/itInfrastructure';
import Procurement from './components/itInfrastructure/procurement';
import HelpDeskSupport from './components/itInfrastructure/help-desk-support';
import NetworkDesignandImplementation from './components/itInfrastructure/network-design-and-implementation';
import SecurityConsulting from './components/itInfrastructure/security-consulting';
import HardwareSupportandMaintenance from './components/itInfrastructure/hardware-support-and-maintenance';
import BackupandDisasterRecovery from './components/itInfrastructure/backup-and-disaster-recovery';
import ITAssetManagement from './components/itInfrastructure/it-asset-management';
import SecurityPatchManagement from './components/itInfrastructure/security-patch-management';
import ServerManagementSupport from './components/itInfrastructure/on-premise-server-management-and-support';
import VendorManagement from './components/itInfrastructure/vendor-management';
import CaseStudies from './components/caseStudies/caseStudies'
import AnimationStudio from './components/caseStudies/sub-pages/animation-studio';
import Asteroid from './components/caseStudies/sub-pages/asteroid';
import B2bEcommerce from './components/caseStudies/sub-pages/b2b-ecommerce';
import CabVendor from './components/caseStudies/sub-pages/cab-vendor';
import CinomaBooking from './components/caseStudies/sub-pages/cinema-booking';
import CleaningCompany from './components/caseStudies/sub-pages/cleaning-company';
import CleaningService from './components/caseStudies/sub-pages/cleaning-service';
import CyberSecurity from './components/caseStudies/sub-pages/cyber-securlty';
import FashionAccesory from './components/caseStudies/sub-pages/fashion-accessory';
import ItInfrastructure from './components/caseStudies/sub-pages/it-infrastructure';
import MobileBanking from './components/caseStudies/sub-pages/mobile-banking';
import Neptune from './components/caseStudies/sub-pages/neptune';
import OnlineCource from './components/caseStudies/sub-pages/online-courses';
import RevampedNetwork from './components/caseStudies/sub-pages/revamped-network';
import TimelessGemstone from './components/caseStudies/sub-pages/timeless-gemstone';
import Products from './components/products/products'
import ProductNeptune from './components/products/productNeptune';
import ProductAstroid from './components/products/productAsteroid'
import Careers from './components/careers/careers';
import JobDetails from './components/careers/jobDetails';
import ScrollToTop from './components/scrollTop';
import Contact from "./components/Contact";
import AboutUs from "./components/AboutUs";
import NotFound from './components/notFound';
import CookiesNoticePage from './components/terms/cookiesNotice';
import TermsPage from './components/terms/termsAndCondition';
import PrivacyPolicyPage from './components/terms/websitePrivacyNotice';

createRoot(document.getElementById('root')).render(
  <Router>
    <Navigation />
    <ScrollToTop/>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/solutions/design" element={<Design />} /> 
      <Route path="/solutions/design/ui-ux-design" element={<UiUxDesign />} /> 
      <Route path="/solutions/design/graphic-design" element={<GraphicDesign />} /> 
      <Route path="/solutions/design/web-design" element={<WebDesign />} /> 
      <Route path="/solutions/design/mobile-app-design" element={<MobileAppDesign />} /> 
      <Route path="/solutions/design/user-research" element={<UserResearch />} /> 
      <Route path="/solutions/design/prototyping" element={<Prototyping />} /> 
      <Route path="/solutions/design/interaction-design" element={<InteractionDesign />} /> 
      <Route path="/solutions/design/branding-and-identity-design" element={<BrandingandIdentityDesign />} /> 
      <Route path="/solutions/design/usability-consulting" element={<UsabilityConsulting />} /> 
      <Route path="/solutions/design/design-strategy" element={<DesignStrategy />} /> 
      <Route path="/solutions/design/visual-prototyping" element={<VisualPrototyping />} /> 
      <Route path="/solutions/design/design-review-and-qa" element={<DesignReviewandQA />} /> 
      <Route path="/solutions/platform" element={<Platform />} /> 
      <Route path="/solutions/platform/aws-cloud-services" element={<AWSCloudServices />} /> 
      <Route path="/solutions/platform/google-cloud-services" element={<GoogleCloudServices />} /> 
      <Route path="/solutions/platform/microsoft-azure" element={<MicrosoftAzure />} /> 
      <Route path="/solutions/digital-marketing" element={<DigitalMarketing />} />
      <Route path="/solutions/digital-marketing/brand-equity-and-growth-optimization" element={<BrandEquityandGrowthOptimization />} />
      <Route path="/solutions/digital-marketing/brand-strategy-and-development" element={<BrandStrategyandDevelopment />} />
      <Route path="/solutions/digital-marketing/market-research-and-analysis" element={<MarketResearchandAnalysis />} />
      <Route path="/solutions/digital-marketing/digital-marketing-campaigns" element={<DigitalMarketingCampaigns />} />
      <Route path="/solutions/digital-marketing/search-engine-optimization" element={<SearchEngineOptimization />} />
      <Route path="/solutions/digital-marketing/pay-per-click-advertising" element={<PayperclickAdvertising />} />
      <Route path="/solutions/digital-marketing/content-marketing-and-copywriting" element={<ContentMarketingandCopywriting />} />
      <Route path="/solutions/digital-marketing/email-marketing-campaigns" element={<EmailMarketingCampaigns />} />
      <Route path="/solutions/digital-marketing/influencer-marketing-and-partnerships" element={<InfluencerMarketingandPartnerships />} />
      <Route path="/solutions/digital-marketing/brand-identity-design-and-development" element={<BrandIdentityDesignandDevelopment />} />
      <Route path="/solutions/digital-marketing/logo-design-and-branding" element={<LogoDesignandBranding />} />
      <Route path="/solutions/digital-marketing/creative-advertising-campaigns" element={<CreativeAdvertisingCampaigns />} />
      <Route path="/solutions/digital-marketing/public-relations-and-media-relations" element={<PublicRelationsandMediaRelations />} />
      <Route path="/solutions/digital-marketing/event-marketing-and-promotions" element={<EventMarketingandPromotions />} />
      <Route path="/solutions/digital-marketing/brand-monitoring-and-reputation-management" element={<BrandMonitoringandReputationManagement />} />
      <Route path="/solutions/digital-marketing/social-media-marketing" element={<SocialMediaMarketing />} />
      <Route path="/solutions/it-consulting" element={<ItConsulting />} />
      <Route path="/solutions/it-consulting/ai-and-automation" element={<AIandAutomation />} />
      <Route path="/solutions/it-consulting/digital-strategy-and-transformation" element={<DigitalStrategyandTransformation />} />
      <Route path="/solutions/it-consulting/digital-operations" element={<DigitalOperations  />} />
      <Route path="/solutions/it-consulting/big-data-and-analytics" element={<BigDataandAnalytics  />} />
      <Route path="/solutions/it-consulting/erp-epm-crm-platform-integrations" element={<ERPPlatform  />} />
      <Route path="/solutions/it-consulting/ecommerce-web-portals" element={<ECommerce  />} />
      <Route path="/solutions/it-consulting/product-development-and-maintenance" element={<ProductDevelopment  />} />
      <Route path="/solutions/it-consulting/data-and-analytics" element={<DataAnalytics  />} />
      <Route path="/solutions/it-consulting/cloud-security" element={<CloudSecurity  />} />
      <Route path="/solutions/it-consulting/quality-engineering-and-assurance" element={<QEA  />} />
      <Route path="/solutions/it-consulting/enterprise-applications" element={<Enterprise  />} />
      <Route path="/solutions/it-consulting/infrastructure-management" element={<Infrastructure  />} />
      <Route path="/solutions/it-infrastructure" element={<ItSupport />} />
      <Route path="/solutions/it-infrastructure/procurement" element={<Procurement />} />
      <Route path="/solutions/it-infrastructure/help-desk-support" element={<HelpDeskSupport />} />
      <Route path="/solutions/it-infrastructure/network-design-and-implementation" element={<NetworkDesignandImplementation />} />
      <Route path="/solutions/it-infrastructure/security-consulting" element={<SecurityConsulting />} />
      <Route path="/solutions/it-infrastructure/hardware-support-and-maintenance" element={<HardwareSupportandMaintenance />} />
      <Route path="/solutions/it-infrastructure/backup-and-disaster-recovery" element={<BackupandDisasterRecovery />} />
      <Route path="/solutions/it-infrastructure/it-asset-management" element={<ITAssetManagement />} />
      <Route path="/solutions/it-infrastructure/security-patch-management" element={<SecurityPatchManagement />} />
      <Route path="/solutions/it-infrastructure/on-premise-server-management-and-support" element={<ServerManagementSupport />} />
      <Route path="/solutions/it-infrastructure/vendor-management" element={<VendorManagement />} />
      <Route path="/case-studies" element={<CaseStudies />} />
      <Route path="/case-studies/blog/animation-studio" element={<AnimationStudio />} />
      <Route path="/case-studies/blog/asteroid" element={<Asteroid />} />
      <Route path="/case-studies/blog/b2b-ecommerce" element={<B2bEcommerce />} />
      <Route path="/case-studies/blog/cab-vendor" element={<CabVendor />} />
      <Route path="/case-studies/blog/cinema-booking" element={<CinomaBooking />} />
      <Route path="/case-studies/blog/cleaning-company" element={<CleaningCompany />} />
      <Route path="/case-studies/blog/cleaning-service" element={<CleaningService />} />
      <Route path="/case-studies/blog/cyber-securlty" element={<CyberSecurity />} />
      <Route path="/case-studies/blog/fashion-accessory" element={<FashionAccesory />} />
      <Route path="/case-studies/blog/it-infrastructure" element={<ItInfrastructure />} />
      <Route path="/case-studies/blog/mobile-banking" element={<MobileBanking />} />
      <Route path="/case-studies/blog/neptune" element={<Neptune />} />
      <Route path="/case-studies/blog/online-courses" element={<OnlineCource />} />
      <Route path="/case-studies/blog/revamped-network" element={<RevampedNetwork />} />
      <Route path="/case-studies/blog/timeless-gemstone" element={<TimelessGemstone />} />
      <Route path="/products" element={<Products />} />
      <Route path="/products/neptune" element={<ProductNeptune />} />
      <Route path="/products/asteroid" element={<ProductAstroid />} />
      <Route path="/careers" element={<Careers />} />
      <Route path="/careers/job-details/:id" element={<JobDetails />}  />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/terms-and-condition" element={<TermsPage />} />
      <Route path="/cookies-notice" element={<CookiesNoticePage />} />
      <Route path="/policy-policy" element={<PrivacyPolicyPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
    <Footer />
  </Router>,
);
