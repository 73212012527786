import React, { useEffect, useState } from 'react';
import Svg from '../svg';
import { NavLink } from 'react-router-dom';
import {
    ClockIcon,
    MapPinIcon,
    CalendarIcon
} from '@heroicons/react/24/outline';
import { Helmet } from 'react-helmet';
import InternshipModal from '../internshipModal';

const Careers = () => {
    const [locationValue, setLocationValue] = useState('');
    const [functionValue, setFunctionValue] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [jobsData, setJobsData] = useState([]);
    const [apiJobData, setApiJobData] = useState([]);

    useEffect(() => {

        const apiUrl = `${process.env.REACT_APP_HOST}jobs`

        fetch(apiUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((responseData) => {
                const data = responseData.data.jobs;
                setJobsData(data);
                setApiJobData(data);
            })
            .catch((error) => {
                console.log(error.message);
            });
    }, []);



    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    const handleLocationChange = (event) => {
        setLocationValue(event.target.value);
    };

    const handleFunctionChange = (event) => {
        setFunctionValue(event.target.value);
    };

    const handleSearch = () => {
        let filteredJobs = apiJobData;
        if (searchValue !== "") {
            filteredJobs = filteredJobs.filter((job) =>
                job?.job_title.toLowerCase().includes(searchValue.toLowerCase())
            );
        }

        if (locationValue !== "") {
            if (locationValue === "Remote") {
                filteredJobs = filteredJobs.filter((job) =>
                    job?.job_location.toLowerCase().includes(locationValue.toLowerCase()) ||
                    job?.job_location.toLowerCase().includes("anywhere")
                );
            } else {
                filteredJobs = filteredJobs.filter((job) =>
                    job?.job_location.toLowerCase().includes(locationValue.toLowerCase())
                );
            }
        }

        if (functionValue !== "") {
            filteredJobs = filteredJobs.filter((job) =>
                job?.job_department.toLowerCase().includes(functionValue.toLowerCase())
            );
        }

        setJobsData(filteredJobs);
    };


    const CareerCard = ({ index, title, required, time, location, dateDetails, id }) => {
        const recuirements = required?.split(",");
        return (
            <div key={index} className="flex items-center justify-center ">
                <Helmet>
                    <title>Join Our Team | Careers at TechSmew Technologies</title>
                    <meta name="description" content="Explore career opportunities at TechSmew Technologies. Join our team and be part of our mission to deliver innovative solutions." />
                    <meta name="keywords" content="Careers, job openings, employment, career opportunities, join our team" />
                </Helmet>
                <div className="w-full p-6 hover:bg-[#f3f6ff] border border-gray-300 hover:shadow-lg shadow-md ">
                    <h5 className="text-base lg:text-xl font-semibold text-gray-800">
                        {title}
                    </h5>
                    <div className='flex gap-2 mt-3 md:mt-6'>
                        {recuirements?.map((data, subIndex) => (
                            <div key={subIndex} className="border px-2 text-xs md:text-sm bg-gray-200 border-gray-200 rounded-xl">
                                {data}
                            </div>
                        ))}
                    </div>
                    <div className='flex justify-between  items-center w-full'>
                        <div className='flex justify-between items-center text-sm my-4 md:my-6 w-full'>
                            <p className='text-xs'>
                                <ClockIcon className='w-4 md:w-6 md:pr-1 md:inline-block' />
                                {time}
                            </p>
                            <p className='text-xs'>
                                <MapPinIcon className='w-4 md:w-6 md:pr-1 md:inline-block' />
                                {location}
                            </p>
                            <p className='text-xs'>
                                <CalendarIcon className='w-4 md:w-6 md:pr-1 md:inline-block' />
                                {dateDetails && dateDetails.substr(0, 10)}
                            </p>
                            <div className='hidden xl:block'>
                                <button className=" bg-[#492196] text-white rounded-full text-sm xl:text-base py-1 px-4 lg:py-2 lg:px-6 hover:bg-[#E75828] hover:text-white active:bg-[#E75828] active:text-white "
                                >
                                    <NavLink to={`/careers/job-details/${id}`}>
                                        Apply Now
                                    </NavLink>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='flex xl:hidden justify-start '>
                        <button className=" bg-[#492196] text-white rounded-full text-sm xl:text-base py-1 px-4 lg:py-2 lg:px-6  hover:bg-[#E75828] hover:text-white active:bg-[#E75828] active:text-white "
                        >
                            <NavLink to={`/careers/job-details/${id}`}>
                                Apply Now
                            </NavLink>
                        </button>
                    </div>
                </div>
            </div>
        );
    };


    return (
        <>

            <section className="relative overflow-hidden bg-[#492196]  pt-[80px] md:pt-[50px] lg:pt-[100px]">
                <div className=" flex flex-wrap items-center max-w-7xl mx-auto">
                    <div className="w-full px-4">
                        <div className="hero-content mx-auto text-center">
                            <h1 className="mb-8 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug md:mt-12">
                                Collaborate with visionaries and innovators to shape tomorrow's world
                            </h1>
                            <p className=" px-4 mx-auto mb-10 font-normal text-base text-[#e4e4e4] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed">
                                In the realm of technology, we pioneer innovations,
                                Creating solutions that redefine what's possible.
                                Our team's dedication drives progress each day,
                                Together, we shape the future with endless capabilities,
                                Join us to be part of this transformative journey.
                            </p>
                        </div>
                        <div className='w-full flex justify-center mx-auto xl:mb-16'>

                            <div className="w-full px-4 py-4 md:mx-8 bg-white 
                            rounded md:rounded-full text-gray-900 overflow-hidden 
                            md:inline-flex pr-4  shadow-md">

                                <input value={searchValue}
                                    onChange={handleSearchChange} type="text" className="mb-2 md:mb-0 md:w-1/3 w-full pl-6  py-2 md:border-r pr-4 border-gray-200  focus:outline-none" placeholder="Job Tittle" />


                                <select
                                    value={locationValue}
                                    onChange={handleLocationChange}
                                    name="location"
                                    className="mb-2 md:mb-0 md:w-1/3 w-full pl-4 py-2 pr-20 mr-4 text-gray-400 focus:outline-none"
                                >
                                    <option value="">Location</option>
                                    <option value="Coimbatore">Coimbatore</option>
                                    <option value="chennai">Chennai</option>

                                </select>
                                <select
                                    value={functionValue}
                                    onChange={handleFunctionChange}
                                    name="functions"
                                    className="mb-2 md:mb-0 md:w-1/3 w-full text-gray-400 md:border-l border-gray-200 focus:outline-none pl-4 py-2 pr-20 mr-4"
                                >
                                    <option value="Departments">Departments</option>
                                    <option value="Design">Design</option>
                                    <option value="IT Consulting">IT Consulting</option>
                                    <option value="Cloud">Cloud</option>
                                    <option value="Digital Marketing">Digital Marketing</option>
                                    <option value="IT Infrastructure">IT Infrastructure</option>


                                </select>
                                <button
                                    onClick={handleSearch}
                                    type="button"
                                    className="bg-[#E75828] rounded-3xl  mr-auto hover:font-medium
                                     text-white md:px-10 px-4 py-2 ml-3 md:ml-0"
                                >
                                    Search
                                </button>
                            </div>
                        </div>

                    </div>
                    <Svg />

                    <div className=" flex items-center justify-center absolute bottom-0 z-20  bg-gradient-to-r from-indigo-500 to-orange-500 px-6 py-2  rounded-t-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8 ">
                        <nav className="flex " aria-label="Breadcrumb">
                            <ol className="inline-flex items-center space-x-1 md:space-x-3">
                                <li>
                                    <div className="flex items-start">
                                        <NavLink className="text-sm mx-1 md:mx-2 font-medium text-gray-100 hover:text-[#f89539] " to={`/`}>Home</NavLink>
                                    </div>
                                </li>
                                <li aria-current="page" className="text-white">
                                    /
                                    <span className="mx-1 md:mx-2 text-sm font-medium text-gray-100 hover:text-[#f89539] ">
                                        Careers
                                    </span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>

            <section className="relative overflow-hidden bg-gray-200 pt-4  pb-4">
                <div className='max-w-7xl px-6 md:px-8 lg:px-10 text-black text-md font-semibold mx-auto'>
                Seize your opportunity to learn, grow, and innovate – Apply now and join TechSmew Technologies for an internship that shapes your IT future! <InternshipModal />
                </div>
            </section>

            <section className="Asteroid bg-white">
                <div className='relative overflow-hidden max-w-7xl mx-auto px-6 md:px-8 lg:px-10 xl:px-0 py-20'>
                    <div className='w-fit mx-auto mb-6'>
                        <h3 className='text-lg lg:text-2xl text-black text-center  pb-2 lg:pb-4 xl:text-4xl '>
                            Looking for New Team Members
                        </h3>
                    </div>
                    <p className=' mx-auto text-sm lg:text-lg text-center pb-6 pr-6  mb-6'>
                        We're currently seeking new team members to help us achieve our
                        ambitious goals. As a member of our team, you'll have the chance
                        to work on cutting-edge projects, collaborate with industry experts,
                        and contribute to innovative solutions that have a global reach.
                        Whether you're a seasoned professional or a rising star in the tech
                        world, we have opportunities for you to grow, learn and excel.
                    </p>
                    <div className="grid grid-cols-2 gap-4 md:grid-cols-2 md:gap-8">
                        {jobsData &&
                            jobsData.length !== 0 &&
                            jobsData.map((data, index) => (
                                <div key={index} className='col-span-2 md:col-span-1 '>
                                    <CareerCard
                                        index={index}
                                        id={data.id}
                                        title={data.job_title}
                                        required={data.job_skills}
                                        time={data.job_type}
                                        location={data.job_location}
                                        dateDetails={data.updated_at} />
                                </div>
                            ))}
                    </div>
                </div>
            </section >
        </>
    )
}
export default Careers

