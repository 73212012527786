import React, { useState } from "react";
import caseData from "./data/caseData.json";
import { NavLink } from "react-router-dom";
import Svg from "../svg";
import { Helmet } from 'react-helmet';


function CaseStudies() {
  const ServiceCard = ({ image, title, details, Label, path }) => {
    return (
      <div
        className="relative mb-3 md:mb-3 xl:mb-5 rounded-md bg-white shadow-md hover:shadow-xl 
                      xl:h-[442px] lg:h-[450px] md:h-[550] h-[450px] 
                      md:px-0 xl:px-0 border-2 transition duration-300 ease-in-out transform hover:scale-105"
      >
        <div className="md:flex justify-start mb-4 items-start top-1/2">
          <img src={image} alt="Service Icon" className="w-full" />
        </div>
        <div className="ml-2 mr-2">
          <h1 className="ml-2 mr-2 mb-2 text-[1.25rem] font-light text-black text-left transition-colors hover:text-[#492196]">
            {title}
          </h1>
          <p className=" ml-2 mr-2 mb-6 pb-6 xl:pb-10  text-black">{details}</p>
        </div>
        <div className="absolute bottom-0 right-0 mb-4 mr-4">
          <NavLink to={`/case-studies/blog/${path}`}>
            <button
              className=" bg-[#492196] text-white rounded-full text-sm xl:text-base py-1 px-4 lg:py-2 lg:px-6
         hover:bg-[#E75828] hover:text-white active:bg-[#E75828] active:text-white "
            >
              Read More...
            </button>
          </NavLink>
        </div>
        <div className="absolute top-0 right-0 bg-[#e75828] text-white text-xs w-24 flex justify-center items-center  p-1 px-1">
          {Label}
        </div>
      </div>
    );
  };

  const EcommerceData = caseData.filter(
    (content) => content.category === "E-commerce"
  );
  const WebAppData = caseData.filter(
    (content) => content.category === "Web App"
  );
  const MobileAppData = caseData.filter(
    (content) => content.category === "Mobile App"
  );
  const SeoData = caseData.filter((content) => content.category === "SEO");
  const CloudData = caseData.filter((content) => content.category === "Cloud");
  const CyberSecurityData = caseData.filter(
    (content) => content.category === "Cyber Securlty"
  );
  const AiData = caseData.filter((content) => content.category === "AI");

  const [activeButton, setActiveButton] = useState("ShowAll");

  const handleClickEcommerce = () => {
    if (activeButton === "Ecommerce") {
      setActiveButton("ShowAll");
    } else {
      setActiveButton("Ecommerce");
    }
  };

  const handleClickWebApp = () => {
    if (activeButton === "WebApp") {
      setActiveButton("ShowAll");
    } else {
      setActiveButton("WebApp");
    }
  };

  const handleClickMobileApp = () => {
    if (activeButton === "MobileApp") {
      setActiveButton("ShowAll");
    } else {
      setActiveButton("MobileApp");
    }
  };

  const handleClickSEO = () => {
    if (activeButton === "SEO") {
      setActiveButton("ShowAll");
    } else {
      setActiveButton("SEO");
    }
  };

  const handleClickCloud = () => {
    if (activeButton === "Cloud") {
      setActiveButton("ShowAll");
    } else {
      setActiveButton("Cloud");
    }
  };

  const handleClickAI = () => {
    if (activeButton === "AI") {
      setActiveButton("ShowAll");
    } else {
      setActiveButton("AI");
    }
  };

  const handleClickCyberSecurity = () => {
    if (activeButton === "CyberSecurity") {
      setActiveButton("ShowAll");
    } else {
      setActiveButton("CyberSecurity");
    }
  };

  return (
    <>
      <section className="relative overflow-hidden bg-[#492196]  pt-[80px] md:pt-[50px] lg:pt-[100px]">
        <Helmet>
          {/* Meta Title: Case Studies | Success Stories | TechSmew Technologies */}
          <title>Case Studies | Success Stories | TechSmew Technologies</title>

          {/* Meta Description: Explore our case studies and success stories, showcasing how TechSmew Technologies transformed businesses with innovative solutions. */}
          <meta name="description" content="Explore our case studies and success stories, showcasing how TechSmew Technologies transformed businesses with innovative solutions." />

          {/* Meta Keywords (for reference): Case studies, success stories, client testimonials, project outcomes, business transformation */}
          {/* Note: Meta keywords are not used by major search engines for SEO. */}
          <meta name="keywords" content="Case studies, success stories, client testimonials, project outcomes, business transformation" />
        </Helmet>
        <div className=" flex flex-wrap items-center max-w-7xl mx-auto xl:!h-[540px]">
          <div className="w-full px-20">
            <div className="hero-content mx-auto text-center">
              <h1 className="mb-8 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug md:mt-12">
                Success Stories with Our Solutions. Discover How We Transformed Businesses Across Industries
              </h1>
              <p className=" px-4 mx-auto  font-normal text-base text-[#e4e4e4] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed">
                We're dedicated to driving innovation and empowering businesses to thrive in today's
                dynamic digital landscape. Our commitment to excellence is not just a promise; it's a
                track record of delivering tailored software solutions that solve real-world challenges.
                Dive into our case studies and witness how our cutting-edge software
                solutions have revolutionized businesses across diverse industries.
              </p>
            </div>
          </div>
          <Svg />
          <div className=" flex items-center justify-center absolute bottom-0 z-20  bg-gradient-to-r from-indigo-500 to-orange-500 px-6 py-2  rounded-t-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8 ">
            <nav className="flex " aria-label="Breadcrumb">
              <ol className="inline-flex items-center space-x-1 md:space-x-3">
                <li>
                  <div className="flex items-start">
                    <a
                      className="text-sm mx-1 md:mx-2 font-medium text-gray-100 hover:text-[#f89539] "
                      href="/"
                    >
                      <NavLink to={`/`}>Home</NavLink>
                    </a>
                  </div>
                </li>
                <li aria-current="page" className="text-white">
                  /
                  <span className="mx-1 md:mx-2 text-sm font-medium text-gray-100 hover:text-[#f89539] ">
                    Case Studies
                  </span>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <div className="max-w-7xl mx-auto md:pb-18">
        <div className="mx-6  pt-6 xl:mx-0 lg:mx-10 md:mx-8">
          <h1 className="text-[2rem] text-black text-left font-light mb-1">
            Case Studies
          </h1>
          <div className="flex flex-nowrap flex-cols-3 mb-3 ">
            <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
            <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
            <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
          </div>
          <p className=" text-black">
            Our in-depth case studies explore diverse industries and challenges,
            showcasing how strategic approaches lead to success. Discover
            actionable strategies, lessons learned and practical takeaways that
            can inform your decision-making and drive your own endeavors
            forward.
          </p>
        </div>

        <div className="flex items-center mx-6 pt-6 xl:mx-0 lg:mx-10 mb-2 md:mx-8">
          <div className="flex flex-wrap pb-4 md:pb-6">
            <p className="text-base md:text-xl lg:text-[2rem] pr-2 text-black text-left font-light">
              Category:
            </p>
            <button
              className={`sm:py-1 px-2 sm:px-4  m-1 ${activeButton === "WebApp" ? "" : "hover:text-[#e75828]"
                } hover:border-[#e75828]  ${activeButton === "WebApp"
                  ? "bg-[#e75828] text-white"
                  : "bg-white text-black border border-gray-200"
                }`}
              onClick={handleClickWebApp}
            >
              Web App
            </button>
            <button
              className={`sm:py-1 px-2 sm:px-4 m-1  ${activeButton === "CyberSecurity" ? "" : "hover:text-[#e75828]"
                }  hover:border-[#e75828]  ${activeButton === "CyberSecurity"
                  ? "bg-[#e75828] text-white"
                  : "bg-white text-black border border-gray-200"
                }`}
              onClick={handleClickCyberSecurity}
            >
              Cyber Security
            </button>
            <button
              className={`sm:py-1 px-2 sm:px-4 m-1  ${activeButton === "AI" ? "" : "hover:text-[#e75828]"
                }  hover:border-[#e75828]  ${activeButton === "AI"
                  ? "bg-[#e75828] text-white"
                  : "bg-white text-black border border-gray-200"
                }`}
              onClick={handleClickAI}
            >
              AI
            </button>
            <button
              className={`sm:py-1 px-2 sm:px-4 m-1 ${activeButton === "SEO" ? "" : "hover:text-[#e75828]"
                }  hover:border-[#e75828]  ${activeButton === "SEO"
                  ? "bg-[#e75828] text-white"
                  : "bg-white text-black border border-gray-200"
                }`}
              onClick={handleClickSEO}
            >
              SEO
            </button>
            <button
              className={`sm:py-1 px-2 sm:px-4 m-1 ${activeButton === "Cloud" ? "" : "hover:text-[#e75828]"
                }   hover:border-[#e75828]  ${activeButton === "Cloud"
                  ? "bg-[#e75828] text-white"
                  : "bg-white text-black border border-gray-200"
                }`}
              onClick={handleClickCloud}
            >
              Cloud
            </button>
            <button
              className={`sm:py-1 px-2 sm:px-4  m-1 ${activeButton === "Ecommerce" ? "" : "hover:text-[#e75828]"
                } hover:border-[#e75828]  ${activeButton === "Ecommerce"
                  ? "bg-[#e75828] text-white"
                  : "bg-white text-black border border-gray-200"
                }`}
              onClick={handleClickEcommerce}
            >
              E-commerce
            </button>
            <button
              className={`sm:py-1 px-2 sm:px-4  m-1 ${activeButton === "MobileApp" ? "" : "hover:text-[#e75828]"
                } hover:border-[#e75828]  ${activeButton === "MobileApp"
                  ? "bg-[#e75828] text-white"
                  : "bg-white text-black border border-gray-200"
                }`}
              onClick={handleClickMobileApp}
            >
              Mobile App
            </button>
          </div>
        </div>
      </div>
      <div className="mx-6 md:mx-8 lg:mx-10 ">
        <div className=" grid grid-cols-1 sm:grid-cols-2 sm:gap-2 md:grid-cols-2 lg:grid-cols-3 gap-6   max-w-7xl mx-auto">
          {activeButton === "Ecommerce" && (
            <>
              {EcommerceData.map((data) => (
                <div key={data.id}>
                  <ServiceCard
                    image={data.url}
                    title={data.title}
                    details={data.detail}
                    Label={data.category}
                    path={data.path}
                  />
                </div>
              ))}
            </>
          )}
          {activeButton === "WebApp" && (
            <>
              {WebAppData.map((data) => (
                <div key={data.id}>
                  <ServiceCard
                    image={data.url}
                    title={data.title}
                    details={data.detail}
                    Label={data.category}
                    path={data.path}
                  />
                </div>
              ))}
            </>
          )}
          {activeButton === "MobileApp" && (
            <>
              {MobileAppData.map((data) => (
                <div key={data.id}>
                  <ServiceCard
                    image={data.url}
                    title={data.title}
                    details={data.detail}
                    Label={data.category}
                    path={data.path}
                  />
                </div>
              ))}
            </>
          )}
          {activeButton === "SEO" && (
            <>
              {SeoData.map((data) => (
                <div key={data.id}>
                  <ServiceCard
                    image={data.url}
                    title={data.title}
                    details={data.detail}
                    Label={data.category}
                    path={data.path}
                  />
                </div>
              ))}
            </>
          )}
          {activeButton === "Cloud" && (
            <>
              {CloudData.map((data) => (
                <div key={data.id}>
                  <ServiceCard
                    image={data.url}
                    title={data.title}
                    details={data.detail}
                    Label={data.category}
                    path={data.path}
                  />
                </div>
              ))}
            </>
          )}
          {activeButton === "AI" && (
            <>
              {AiData.map((data) => (
                <div key={data.id}>
                  <ServiceCard
                    image={data.url}
                    title={data.title}
                    details={data.detail}
                    Label={data.category}
                    path={data.path}
                  />
                </div>
              ))}
            </>
          )}
          {activeButton === "CyberSecurity" && (
            <>
              {CyberSecurityData.map((data) => (
                <div key={data.id}>
                  <ServiceCard
                    image={data.url}
                    title={data.title}
                    details={data.detail}
                    Label={data.category}
                    path={data.path}
                  />
                </div>
              ))}
            </>
          )}
          {activeButton === "ShowAll" && (
            <>
              {caseData.map((data) => (
                <div key={data.id}>
                  <ServiceCard
                    image={data.url}
                    title={data.title}
                    details={data.detail}
                    Label={data.category}
                    path={data.path}
                  />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default CaseStudies;
