import React from 'react';

const CookiesNoticePage = () => {
  return (
      <section className=" overflow-hidden bg-[#492196]  pt-[80px] md:pt-[100px] ">
  <div className="max-w-7xl mx-auto">
    <div className=" flex flex-wrap items-center">
      <div className="w-full px-6 md:px-10 lg:px-0">
        <div className=" mx-auto text-left">
          <h1 className="mb-8 text-3xl font-bold  text-white sm:text-4xl ">
          Cookie Notice
          </h1>
        </div>
      </div>
    </div>
  </div>

    <div className="bg-gray-100 p-8">
      <div className="max-w-7xl mx-auto">
       

        <section className="mb-6">
          <h2 className="text-xl font-semibold">Introduction</h2>
          <p>Welcome to TechSmew's Cookies Notice page. This notice explains how we use cookies and similar technologies on our website.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold">What are Cookies?</h2>
          <p>Cookies are small text files that are placed on your computer or mobile device when you visit a website. They are widely used to make websites work more efficiently.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold">Types of Cookies</h2>
          <p>There are different types of cookies, including essential cookies, functional cookies and third-party cookies. Each serves a specific purpose.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold">Why We Use Cookies</h2>
          <p>We use cookies to improve your experience on our website, analyze site traffic and personalize content. Cookies also help us provide you with relevant advertisements.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold">Your Cookie Choices</h2>
          <p>You have the option to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold">Cookie Settings</h2>
          <p>You can manage your cookie preferences by visiting our Cookie Settings page...</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold">Changes to This Notice</h2>
          <p>We may update this Cookies Notice from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold">Contact Us</h2>
          <p>If you have any questions or concerns about our use of cookies, please contact us.</p>
        </section>

      
      </div>
    </div></section>
  );
};

export default CookiesNoticePage;
