import {
  BoltIcon,
  ClipboardDocumentCheckIcon,
  DocumentTextIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  LinkIcon,
  ShieldCheckIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { NavLink } from "react-router-dom";
import CTA from "../../assets/images/cta.webp";
import WorkFlow from "../../assets/images/work_flow.webp";
import AWSCloudSupport from "../../assets/images/platform/aws-cloud-services/aws-cloud-support.webp";
import ArchitectureDesignandImplementation from "../../assets/images/platform/aws-cloud-services/architecture-design-and-implementation.webp";
import MigrationandDeployment from "../../assets/images/platform/aws-cloud-services/migration-and-deployment.webp";
import CostOptimization from "../../assets/images/platform/aws-cloud-services/cost-optimization.webp";
import { Helmet } from 'react-helmet';
import Svg from "../svg";


function AWScloudservices() {
  const ServiceCard = ({ icon, title, details }) => {
    return (
      <div className="mb-3 md:mb-3 xl:mb-5 bg-white shadow-md hover:shadow-xl md:px-0 xl:px-0 border-2  transition duration-300 ease-in-out transform hover:scale-105">
        <div className="md:flex justify-start mb-4 items-start top-1/2">
          <img src={icon} alt="Service Icon" className="w-full" />
        </div>
        <div className="ml-2 mr-2">
          <h1 className="ml-2 mr-2 mb-2 text-2xl font-light text-black text-left">
            {title}
          </h1>
          <p className="ml-2 mr-2 mb-6  text-black">{details}</p>
        </div>
      </div>
    );
  };

  return (
    <main className="platform_aws_cloud">
      <Helmet>
        {/* Meta Title: AWS Cloud Services | TechSmew Technologies */}
        <title>AWS Cloud Services | TechSmew Technologies</title>
        
        {/* Meta Description: TechSmew Technology provides AWS cloud services. Harness the power of Amazon Web Services for your business. */}
        <meta name="description" content="TechSmew Technology provides AWS cloud services. Harness the power of Amazon Web Services for your business." />
        
        {/* Meta Keywords (for reference): AWS cloud services, Amazon Web Services, cloud solutions */}
        {/* Note: Meta keywords are not used by major search engines for SEO. */}
        <meta name="keywords" content="AWS cloud services, Amazon Web Services, cloud solutions" />
      </Helmet>

      <div className="relative overflow-hidden bg-[#492196]  pt-[120px] md:pt-[130px] lg:pt-[160px]">
        <div className="max-w-7xl mx-auto">
          <div className=" flex flex-wrap items-center">
            <div className="w-full px-4">
              <div className="hero-content mx-auto text-center">
                <h1 className="mb-8 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug">
                  Elevate Your Business with Cutting-edge AWS Cloud Services
                </h1>
                <p className="mb-12 md:ml-10 px-4 mx-auto font-normal text-base text-[#e4e4e4] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed">
                  Proactively optimize your business processes by harnessing the
                  potential of AWS Cloud Services. Identify opportunities,
                  enhance scalability and ensure seamless operations to drive
                  growth and innovation.
                </p>
              </div>
            </div>

         <Svg/>

            <div className="flex items-center justify-center absolute bottom-0 z-20  bg-gradient-to-r from-indigo-500 to-orange-500 px-2 md:px-6 pt-1 md:py-2 rounded-t-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8 mr-10">
              <nav className="flex " aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                  <li>
                    <div className="flex items-center">
                      <NavLink
                        className="text-[9px] md:text-sm font-medium text-gray-100 hover:text-[#f89539]"
                        to="/"
                      >
                        Home
                      </NavLink>
                    </div>
                  </li>
                  <li aria-current="page" className="h-full">
                    <div className="flex items-center text-white ">
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-medium md:ml-2">
                        Solutions
                      </span>
                    </div>
                  </li>
                  <li aria-current="page">
                    <NavLink
                      to="/solutions/platform"
                      className="flex items-center text-white"
                    >
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-medium md:ml-2 ">
                        Platform
                      </span>
                    </NavLink>
                  </li>
                  <li aria-current="page">
                    <div className="flex items-center  text-white">
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-medium  md:ml-2 ">
                        AWSCS
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-white">
        <div className="max-w-7xl mx-auto my-6 md:pb-18">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8">
            <h1 className="text-[2rem] text-black text-left font-light mb-1">
              Expert AWS Cloud Solutions for Unmatched Performance
            </h1>
            <div className="flex flex-nowrap flex-cols-3 mb-3 ">
              <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
              <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
              <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
            </div>
            <p className="text-base font-normal text-black">
              Experience unparalleled performance with our expert AWS Cloud
              Services. Our dedicated team is here to assist you in leveraging
              the full capabilities of Amazon Web Services. Trust us for
              tailor-made solutions to match your unique business requirements.
            </p>
          </div>
          <div className="py-6 md:py-8 mx-6 grid grid-cols-1 xl:grid-cols-4 md:grid-cols-2 gap-6 xl:mx-0 lg:mx-10 md:mx-8">
            <ServiceCard
              icon={AWSCloudSupport}
              title="24/7 AWS Cloud Support"
              details="Round-the-clock assistance to ensure your AWS infrastructure runs smoothly and efficiently."
            />
            <ServiceCard
              icon={ArchitectureDesignandImplementation}
              title="Architecture Design and Implementation"
              details="Crafting and implementing AWS architectures that align with your business objectives for optimal performance."
            />
            <ServiceCard
              icon={MigrationandDeployment}
              title="Migration and Deployment"
              details=" Seamlessly migrate your applications and data to AWS while ensuring minimal disruption."
            />
            <ServiceCard
              icon={CostOptimization}
              title="Cost Optimization"
              details=" Expert guidance to manage and optimize your AWS resources, resulting in cost savings."
            />
          </div>
        </div>
      </section>
      <section className="bg-gray-100 py-10">
        <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-1 gap-0 lg:gap-4 ">
          <div className="md:flex justify-start md:pr-4 xl:pl-0 items-center rounded-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8">
            <div className="col-span-1">
              <img src={WorkFlow} alt="work flow" className="w-full" />
              <div className="pt-4">
                <h1 className="text-[2rem] font-light mb-1">Our Workflow</h1>
              </div>
              <div className="flex flex-nowrap flex-cols-3">
                <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
                <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
                <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
              </div>
              <div className="py-3">
                <h1 className=" text-black">
                  Efficiently strategize AWS solutions tailored to your business
                  objectives, followed by robust architecture design and
                  deployment.
                </h1>
              </div>
            </div>
          </div>
          <div className="col-span-2 mx-12 xl:mx-0 lg:mx-10 md:mx-16 pt-8 xl:ml-10">
            <ol className="relative text-black border-l border-gray-300 dark:border-gray-700 ">
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <DocumentTextIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Collaborative consultation to strategize the best AWS
                  solutions aligned with your business goals.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <BoltIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Designing and deploying AWS architectures that are resilient,
                  scalable and optimized.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <LinkIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Seamlessly migrating your applications and data to the AWS
                  cloud environment, followed by thorough integration.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <InformationCircleIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Continuous monitoring of AWS performance to proactively
                  address any potential issues and optimize resources.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <ShieldCheckIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Enhancing AWS security through advanced measures while
                  ensuring adherence to compliance standards.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="max-w-7xl mx-auto my-6 md:pb-18">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8">
            <h1 className="text-[2rem] text-black text-left mb-1 font-light">
              Our Unique Value Proposition
            </h1>
            <div className="flex flex-nowrap flex-cols-3 mb-3 ">
              <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
              <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
              <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
            </div>
            <p className="text-base font-normal text-black">
              Experience the ultimate in AWS Cloud Services. Empower your
              business with our unmatched expertise and leverage the full
              potential of cloud computing. Your success is our top priority.
            </p>
          </div>
          <div className="py-6 md:py-10 grid grid-cols-1 md:grid-cols-3 gap-6 mx-6 lg:mx-10 md:mx-8 xl:mx-0">
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#492196] rounded-lg w-12 h-12">
                  <GlobeAltIcon className="h-8 w-8 text-white stroke-[1]" />
                </div>
              </div>
              <h3 className="text-2xl font-light text-black mb-2 text-center">
                Agile Scalability
              </h3>
              <p className="text-black font-normal text-base  text-center">
                Seamlessly scale your resources up or down based on demand,
                ensuring cost-efficiency.
              </p>
            </div>
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#492196] rounded-lg w-12 h-12">
                  <ClipboardDocumentCheckIcon className="h-8 w-8 text-white stroke-[1]" />
                </div>
              </div>
              <h3 className="text-2xl font-light text-black mb-2 text-center">
                Strategic Insights
              </h3>
              <p className="text-black font-normal text-base  text-center">
                Deep insights into your business processes to deliver AWS
                solutions that align with your goals.
              </p>
            </div>
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#492196] rounded-lg w-12 h-12">
                  <UsersIcon className="h-8 w-8 text-white stroke-[1]" />
                </div>
              </div>
              <h3 className="text-2xl font-light text-black mb-2 text-center">
                Customer-Centric Approach
              </h3>
              <p className="text-black font-normal text-base  text-center">
                Transparent communication and continuous support to ensure your
                satisfaction.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-100">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 py-10 mx-6 lg:mx-10 md:mx-8 xl:mx-0">
            <div className="justify-center items-center pt-5 md:col-span-2">
              <div className="pt-5 pb-3 pb-md-5 md:px-0 text-center md:text-left">
                <p className="text-[1.5rem] font-normal text-black mb-3">
                  Ready to get started?
                </p>
                <h2 className="text-[2rem] font-normal text-black pb-3">
                Unleash the Power of AWS Cloud
                {" "}
                Leverage the capabilities of Amazon Web Services for your success.
                </h2>
                <NavLink
                  to="/contact"
                  className="bg-[#0d6efd] hover:bg-[#0d6dfdb7] mt-6 mb-2 text-white py-3 text-base px-6 inline-block transition duration-300 ease-in-out rounded-full"
                >
                  Explore AWS
                </NavLink>
              </div>
            </div>
            <div className="md:flex justify-end items-center md:col-span-1">
              <img src={CTA} alt="CTA" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default AWScloudservices;
