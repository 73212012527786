import React from "react";
import { NavLink } from "react-router-dom";

import {
  ClockIcon,
  AdjustmentsHorizontalIcon,
  BoltIcon,
  HashtagIcon,
  ChatBubbleLeftRightIcon,
  BanknotesIcon,
  ClipboardDocumentIcon,
  CommandLineIcon,
  CodeBracketIcon,
  ClipboardDocumentListIcon,
  FaceSmileIcon,
} from "@heroicons/react/24/outline";
import CTA from "../../assets/images/cta.webp";
import neptunemain from "../../assets/images/products/neptune/neptune-main.webp";
import Banner from "../../assets/images/products/neptune/banner.webp";
import { Helmet } from "react-helmet";
import PriceTable from "./priceTable";

const ProductNeptune = () => {
  return (
    <>
      {/* section-1 */}
      <section className="relative overflow-hidden bg-[#492196] pt-[80px] md:pt-[50px] lg:pt-[80px] ">
        <Helmet>
          {/* Meta Title: Neptune Software Product | TechSmew Technologies */}
          <title>Neptune Software Product | TechSmew Technologies</title>

          {/* Meta Description: Discover Neptune, TechSmew Technologies' innovative software product. Unlock its features and benefits to streamline your business operations. */}
          <meta
            name="description"
            content="Discover Neptune, TechSmew Technologies' innovative software product. Unlock its features and benefits to streamline your business operations."
          />

          {/* Meta Keywords (for reference): Neptune software, business operations, software features, Neptune benefits */}
          {/* Note: Meta keywords are not used by major search engines for SEO. */}
          <meta
            name="keywords"
            content="Neptune software, business operations, software features, Neptune benefits"
          />
        </Helmet>
        <div className=" max-w-7xl mx-auto px-6 md:px-10 xl:px-0  ">
          <div className="grid grid-cols-5 gap-4 xl:mb-10">
            <div className="col-span-5 lg:col-span-3 my-auto xl:max-w-xl ">
              <div className="text-white">
              <div className="mx-auto text-left">
              <h1 className="mb-8 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug md:mt-12">
              Transform Customer Engagement with Neptune <br />
               Your AI Conversational Chatbot
              </h1>
              <p className=" mb-16 lg:mb-24  font-normal text-base text-[#e4e4e4]   sm:leading-relaxed md:text-base md:leading-relaxed">
              An AI conversational chatbot powered by NLP, AI and ML.
                  Neptune enhances customer support on the bank's social media,
                  delivering real-time assistance and elevating satisfaction
                  through advanced technology. Discover Neptune's key features,
                  benefits and impact on social media management.
              </p>
            </div>
              </div>
            </div>
            <div className="col-span-2 ml-auto hidden lg:block my-auto">
              <img
                src={neptunemain}
                className="my-auto  md:mt-6 lg:mt-0  md:px-2 lg:px-10 xl:px-0  "
                alt="neptune-main"
              />
            </div>
            <div className="flex items-center justify-center absolute bottom-0 z-20  bg-gradient-to-r from-indigo-500 to-orange-500 
            px-6 py-2  rounded-t-lg mx-6 md:mx-2 lg:mx-10 xl:mx-0  ">
              <nav className="flex " aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                  <li>
                    <div className="flex items-start">
                      <a
                        className="text-sm mx-1 md:mx-2 font-medium text-gray-100 hover:text-[#f89539] "
                        href="/"
                      >
                        <NavLink to={`/`}>Home</NavLink>
                      </a>
                    </div>
                  </li>
                  <li aria-current="page" className="text-white">
                    /
                    <span className="mx-1 md:mx-2 text-sm font-medium text-gray-100 hover:text-[#f89539] ">
                      <NavLink to={`/products`}>Products</NavLink>
                    </span>
                  </li>
                  <li aria-current="page" className="text-white">
                    /
                    <span className="mx-1 md:mx-2 text-sm font-medium text-gray-100 hover:text-[#f89539] ">
                      Neptune
                    </span>
                  </li>
                </ol>
              </nav>
            </div>
          </div>

        </div>
      </section>

      {/* section-2 */}
      <section className="bg-[#f3f6ff]">
        <div className="relative overflow-hidden max-w-7xl mx-auto md:px-8 lg:px-10 xl:px-0">

          <div className="mx-auto pb-12 pt-20">
            <div className="grid grid-cols-1 lg:grid-cols-2  md:gap-6 px-6 md:px-0">
              <div className="col-span-1">
                <h3 className="text-[2rem] font-light text-black text-left  pb-2 lg:pb-4 ">
                 
                  Transform Customer Engagement with Neptune, Your AI Conversational Chatbot

                </h3>
                <div className="md:ml-auto w-[100%] ">
                  <div className="flex flex-nowrap flex-cols-3 pb-4 ">
                    <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
                    <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
                    <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
                  </div>
                </div>
                <img
                  src={Banner}
                  className="w-full h-auto object-cover max-w-xl md:hidden"
                  alt="chat-bot"
                />
                <p className=" mx-auto text-lg text-left pb-8">
                  Experience a revolutionary way to engage with your customers and
                  streamline your operations with Neptune, our AI conversational chatbot
                  powered by state-of-the-art Natural Language Processing (NLP),
                  Artificial Intelligence (AI) and Machine Learning (ML) technologies.
                  Neptune empowers your business to deliver exceptional customer
                  experiences while enhancing efficiency.
                </p>
                <div className="flex items-center justify-start text-[#e75828]">
                  <NavLink to="/contact" className="flex my-1 text-[#e75828]">
                    <button className="bg-[#e75828] border-2 border-[#e75828] hover:bg-orange-700 text-white hover:text-white py-2 px-6 flex items-center rounded-full">
                      <span className="flex  items-center justify-center">
                        Explore Now
                      </span>
                    </button>
                  </NavLink>
                </div>
              </div>
              <div className="col-span-1 hidden md:flex">
                <img
                  src={Banner}
                  className="w-full h-auto object-cover"
                  alt="chat-bot"
                />
              </div>

            </div>
          </div>
        </div>
      </section>

      {/* section-3 */}
      <section className="bg-white">
        <div className="relative overflow-hidden max-w-7xl mx-auto   lg:px-0 md:mt-6 xl:mt-16">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8">
            <h1 className="text-[2rem] font-light text-black text-left  pb-2 lg:pb-4 xl:text-4xl ">
              Empower your enterprise by harnessing the potential of Conversational AI
              {/* The Benefits of AI-Powered Chat in Banking Customer Services */}
            </h1>
            <div className="flex flex-nowrap flex-cols-3 pb-4 ">
              <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
              <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
              <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
            </div>
            <p className=" mx-auto text-lg text-left pb-4 md:pb-6">
              In today's dynamic business landscape, enterprises need more than just a chatbot.
              They require a powerful conversational AI solution that empowers them to excel.
              Neptune is designed to redefine your enterprise.
              {/* AI-Powered Chat in Banking Customer Services offers
              round-the-clock personalized assistance, quick issue resolution,
              cost efficiency and data-driven insights, enhancing customer
              experience and operational effectiveness while enabling seamless
              scalability and compliance. */}
            </p>
          </div>
          {/* mobile and medium */}
          <div className="lg:hidden">
            <div className="grid grid-cols-1 md:grid-cols-2 mx-auto gap-2 md:gap-4 w-full px-6 md:px-8   ">
              <div className="bg-[#0080FF] text-white p-4 md:p-6   rounded-xl">
                <div className="flex  flex-wrap justify-between items-center mb-2 md:mb-4">
                  <h4 className="text-2xl font-light">24/7 Availability</h4>
                  <ClockIcon className="w-6 mb-5" />
                </div>
                <p className="text-base md:pb-0">
                  AI-powered customer support enables businesses to provide
                  round-the-clock assistance, addressing customer queries and
                  issues outside of regular working hours.
                </p>
              </div>

              <div className="bg-[#E76B7D] text-white p-4 md:p-6 rounded-xl">
                <div className="flex  flex-wrap justify-between items-center mb-2 md:mb-4">
                  <h4 className="text-2xl font-light">Scalability</h4>
                  <AdjustmentsHorizontalIcon className="w-6" />
                </div>
                <p className="text-base md:pb-0">
                  AI allows businesses to handle a large volume of customer
                  inquiries simultaneously, ensuring efficient support as the
                  customer base grows.
                </p>
              </div>

              <div className="bg-[#D24E7D] text-white p-4 md:p-6 rounded-xl">
                <div className="flex  flex-wrap justify-between items-center mb-2 md:mb-4">
                  <h4 className="text-2xl font-light">Consistency</h4>
                  <BoltIcon className="w-6" />
                </div>
                <p className="text-base md:pb-0">
                  AI ensures consistent responses across all interactions,
                  eliminating the risk of human error and maintaining brand
                  messaging accuracy.
                </p>
              </div>

              <div className="bg-[#8557E2] text-white  p-4 md:p-6 rounded-xl">
                <div className="flex  flex-wrap justify-between items-center mb-2 md:mb-4">
                  <h4 className="text-2xl font-light">Personalization</h4>
                  <HashtagIcon className="w-6" />
                </div>
                <p className="text-base md:pb-0">
                  Through data analysis, AI can personalise interactions,
                  tailoring responses to individual customer profiles and
                  improving the overall customer experience.
                </p>
              </div>

              <div className="bg-[#499B61] text-white  p-4 md:p-6 rounded-xl">
                <div className="flex  flex-wrap justify-between items-center mb-2 md:mb-4">
                  <h4 className="text-2xl font-light">Multilingual Support</h4>
                  <ChatBubbleLeftRightIcon className="w-6" />
                </div>
                <p className="text-base md:pb-0">
                  AI-powered systems can offer support in multiple languages,
                  breaking down language barriers and expanding the reach of
                  customer service.
                </p>
              </div>

              <div className="bg-[#F3AB41] text-white  p-4 md:p-6 rounded-xl">
                <div className="flex  flex-wrap justify-between items-center mb-2 md:mb-4">
                  <h4 className="text-2xl font-light">Cost Efficiency</h4>
                  <BanknotesIcon className="w-6" />
                </div>
                <p className="text-base md:pb-0">
                  Implementing AI for customer support can lead to reduced
                  operational costs as fewer human agents are required for
                  handling routine inquiries.
                </p>
              </div>

              <div className="bg-[#EF9B70] text-white md:col-span-2  p-4 md:p-6 rounded-xl">
                <div className="flex  flex-wrap justify-between items-center mb-2 md:mb-4">
                  <h4 className="text-2xl font-light">Adaptability</h4>
                  <ClipboardDocumentIcon className="w-6" />
                </div>
                <p className="text-base md:pb-0">
                  AI systems can adapt to changing customer needs and market
                  trends, providing adaptable and up-to-date support.
                </p>
              </div>
            </div>
          </div>
          {/* large and extra large */}
          <div className="hidden lg:flex px-10 xl:px-0   ">
            <div className="grid grid-cols-4 mx-auto my-8 w-full   ">
              <div className="col-span-1">
                <div
                  className="bg-[#0080FF] text-white h-full rounded-xl mr-2 xl:mr-4 p-6
                                flex justify-center items-center   " >
                  <div className="flex flex-col flex-wrap  items-start ">
                    <ClockIcon className="w-6 mb-5" />
                    <h4 className="text-2xl font-light mb-4">
                      24/7 Availability
                    </h4>
                    <p className="pb-4">
                      AI-powered customer support enables businesses to provide
                      round-the-clock assistance, addressing customer queries
                      and issues outside of regular working hours.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-span-3">
                <div className="w-full flex  ">
                  <div className="bg-[#E76B7D] text-white p-6 rounded-xl mx-2 xl:mx-4 xl:w-3/4">
                    <div className="flex  flex-wrap justify-between items-center mb-4">
                      <h4 className="text-2xl font-light"> Scalability</h4>
                      <AdjustmentsHorizontalIcon className="w-6" />
                    </div>
                    <p>
                      AI allows businesses to handle a large volume of customer
                      inquiries simultaneously, ensuring efficient support as
                      the customer base grows.
                    </p>
                  </div>
                  <div className="bg-[#D24E7D] text-white p-6 rounded-xl mx-2 xl:mx-4">
                    <div className="flex  flex-wrap justify-between items-center mb-4">
                      <h4 className="text-2xl font-light">Consistency</h4>
                      <BoltIcon className="w-6" />
                    </div>
                    <p>
                      AI ensures consistent responses across all interactions,
                      eliminating the risk of human error and maintaining brand
                      messaging accuracy.
                    </p>
                  </div>
                  <div className="bg-[#8557E2] text-white p-6 ml-2 xl:ml-4 rounded-xl">
                    <div className="flex  flex-wrap justify-between items-center mb-4">
                      <h4 className="text-2xl font-light">Personalization</h4>
                      <HashtagIcon className="w-6" />
                    </div>
                    <p>
                      Through data analysis, AI can personalise interactions,
                      tailor responses to individual customer profiles and
                      improve the overall customer experience.
                    </p>
                  </div>
                </div>

                <div className="w-full flex">
                  <div className="bg-[#499B61] text-white p-6 mx-2 mt-4 xl:mx-4 xl:mt-4  rounded-xl">
                    <div className="flex  flex-wrap justify-between items-center mb-4">
                      <h4 className="text-2xl font-light">
                        Multilevel Support
                      </h4>
                      <ChatBubbleLeftRightIcon className="w-6" />
                    </div>
                    <p>
                      AI-powered systems can offer support in multiple
                      languages, breaking down language barriers and expanding
                      the reach of customer service.
                    </p>
                  </div>
                  <div className="bg-[#F3AB41] text-white p-6 mx-2 mt-4 xl:mx-4 xl:mt-4 rounded-xl">
                    <div className="flex  flex-wrap justify-between items-center mb-4">
                      <h4 className="text-2xl font-light">Cost Efficiency</h4>
                      <BanknotesIcon className="w-6" />
                    </div>
                    <p>
                      Implementing AI for customer support can lead to reduced
                      operational costs as fewer human agents are required for
                      handling routine inquiries.
                    </p>
                  </div>
                  <div className="bg-[#EF9B70] text-white p-6 ml-2 mt-4 xl:ml-4 xl:mt-4  xl:w-3/4 rounded-xl">
                    <div className="flex  flex-wrap justify-between items-center mb-4">
                      <h4 className="text-2xl font-light">Adaptability</h4>
                      <ClipboardDocumentIcon className="w-6" />
                    </div>
                    <p>
                      AI systems can adapt to changing customer needs and market
                      trends, providing adaptable and up-to-date support.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section-4 */}
      <section className="bg-white">
        <div className="max-w-7xl mx-auto my-24 md:pb-18">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8">
            <h1 className="text-[2rem] font-light text-black text-left  pb-2 lg:pb-4 xl:text-4xl ">
              Enhancing Customer Support and Engagement
            </h1>
            <div className="flex flex-nowrap flex-cols-3 mb-6 ">
              <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
              <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
              <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
            </div>
            <p className=" mx-auto text-lg text-left pb-8">
              Experience a harmonious blend of innovation and aesthetics with
              our UI/UX design services. Our team of skilled designers
              transforms ideas into visually captivating experiences, ensuring
              seamless navigation and stunning visuals.
            </p>
          </div>


          {/* Efficiency and Automation: 
Automate repetitive tasks and processes, allowing your team to focus on strategic initiatives.
Real-Time Insights:
 Access valuable real-time insights into customer sentiment and preferences, empowering informed decision-making.
Seamless Integration: 
Easily integrate Neptune into your existing systems, enhancing your workflow without disruptions.
Customization and Scalability: 
Tailor Neptune to match your unique business requirements and scale as your enterprise grows. */}

          {/* mobile and medium */}
          <div className="md:hidden">
            <div className="grid grid-cols-1   gap-2 md:gap-4 mx-auto my-8 w-full px-2 md:px-6">
              <div className="col-span-1 bg-white p-2  mx-4 rounded-xl shadow-lg">
                <div className="flex flex-col ">
                  <div className="flex items-center justify-between">
                    <h4 className="text-lg font-bold mb-2 "> Efficiency and Automation</h4>
                    <CommandLineIcon className="w-8 text-[#8557E2]" />
                  </div>
                  <p className="pb-2 text-sm">
                    Automate repetitive tasks and processes, allowing your team to focus on
                    strategic initiatives.
                  </p>
                </div>
              </div>
              <div className="col-span-1 bg-white p-2  mx-4 rounded-xl shadow-lg">
                <div className="flex flex-col ">
                  <div className="flex items-center justify-between">
                    <h4 className="text-lg font-bold mb-2 ">
                      Real-Time Insights
                    </h4>
                    <CodeBracketIcon className="w-8 text-[#499B61]" />
                  </div>
                  <p className="pb-2 text-sm">
                    Access valuable real-time insights into customer sentiment and preferences,
                    empowering informed decision-making.
                  </p>
                </div>
              </div>
              <div className="col-span-1 bg-white p-2  mx-4 rounded-xl shadow-lg">
                <div className="flex flex-col ">
                  <div className="flex items-center justify-between">
                    <h4 className="text-lg font-bold mb-2 ">Seamless Integration</h4>
                    <ClipboardDocumentListIcon className="w-8 text-[#D04577]" />
                  </div>
                  <p className="pb-2 text-sm">
                    Easily integrate Neptune into your existing systems, enhancing your
                    workflow without disruptions.
                  </p>
                </div>
              </div>
              <div className="col-span-1 bg-white p-2  mx-4 rounded-xl shadow-lg">
                <div className="flex flex-col ">
                  <div className="flex items-center justify-between">
                    <h4 className="text-lg font-bold mb-2 ">
                      Customization and Scalability
                    </h4>
                    <FaceSmileIcon className="w-8 text-[#EF9B70]" />
                  </div>
                  <p className="pb-2 text-sm">
                    Tailor Neptune to match your unique business requirements and scale as
                    your enterprise grows.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* large and extra large */}
          <div className="hidden md:flex mx-4 lg:mx-0">
            <div className="grid grid-cols-1 md:grid-cols-2  gap-6 mx-auto my-8 w-full px-4 lg:px-6 xl:px-0">
              <div className="col-span-1 bg-white p-2 xl:p-6 lg:mx-4 xl:mx-0 rounded-xl shadow-lg">
                <div className="flex flex-row  justify-start items-center">
                  <CommandLineIcon className="text-8xl w-48 text-[#8557E2]" />
                  <div className="px-8  text-black">
                    <h4 className="text-2xl font-light lg:text-2xl mb-1 lg:mb-2 ">
                      Efficiency and Automation
                    </h4>
                    <p className="pb-4 text-sm lg:text-base">
                      Automate repetitive tasks and processes, allowing your team to focus
                      on strategic initiatives.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-1 bg-white p-2 xl:p-6 lg:mx-4 xl:mx-0 rounded-xl shadow-lg">
                <div className="flex flex-row  justif items-center">
                  <CodeBracketIcon className="text-8xl w-48  text-[#499B61]" />
                  <div className="px-8  text-black">
                    <h4 className="text-2xl font-light mb-1 lg:mb-2 ">
                      Real-Time Insights
                    </h4>
                    <p className="pb-4 text-sm lg:text-base">
                      Access valuable real-time insights into customer sentiment and preferences,
                      empowering informed decision-making.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-1 bg-white p-2 xl:p-6 lg:mx-4 xl:mx-0 rounded-xl shadow-lg">
                <div className="flex flex-row  justify-start items-center">
                  <ClipboardDocumentListIcon className="text-8xl w-48  text-[#D04577]" />
                  <div className="px-8  text-black">
                    <h4 className="text-2xl font-light mb-1 lg:mb-2 ">
                      Seamless Integration
                    </h4>
                    <p className="pb-4 text-sm lg:text-base">
                      Easily integrate Neptune into your existing systems, enhancing your
                      workflow without disruptions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-1 bg-white p-2 xl:p-6 lg:mx-4 xl:mx-0 rounded-xl shadow-lg ">
                <div className="flex flex-row  justify-start items-center">
                  <FaceSmileIcon className="text-8xl w-48  text-[#EF9B70]" />
                  <div className="px-8  text-black">
                    <h4 className="text-2xl font-light mb-1 lg:mb-2 ">
                      Customization and Scalability
                    </h4>
                    <p className="pb-4 text-sm lg:text-base">
                      Tailor Neptune to match your unique business requirements and scale as
                      your enterprise grows.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="max-w-7xl mx-auto my-6 md:pb-18">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8 ">
            <h1 className="text-[2rem] font-light text-black text-center mb-1">
              Flexible Plans to Fit Your Needs
            </h1>
            <p className="text-lg text-black text-center mb-6 md:mb-16">
              Select the perfect plan for your business goals and start your
              Neptune and Astroid experience.Find the plan that suits your
              business size and ambitions, ensuring you get the most value out
              of Neptune and Astroid.
            </p>
          </div>
          <div className="mb-6 md:mb-16">
          <PriceTable />
          </div>
          {/* <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 md:mx-8 xl:mx-0 lg:mx-10 py-10 mx-6">
            <div className="bg-white shadow-md hover:shadow-xl border-2 transition duration-300 ease-in-out transform hover:scale-105">
              <div className="pt-6 pb-2 md:my-1 mx-6">
                <h6 className="text-xl font-medium flex items-center justify-center">
                  Standard
                </h6>
                <h1 className="text-4xl font-medium flex items-center justify-center py-2">
                  $5
                </h1>
                <p className="text-sm font-normal flex items-center justify-center py-1">
                  {" "}
                  /user/month billed monthly
                </p>
              </div>
              <div className="flex items-center justify-center text-[#e75828]">
                <NavLink to="/contact" className="flex my-1 text-[#e75828]">
                  <button className="bg-[#e75828] text-sm hover:bg-[#492196] hover:border-[#492196] border-2 border-[#e75828] text-white hover:text-whitefont-medium py-1 px-4 flex items-center rounded-full">
                    <span className="flex w-[140px] items-center justify-center">
                      Contact Us
                    </span>
                  </button>
                </NavLink>
              </div>
              <div className="my-4 py-2 md:px-4 mx-6">
                <p className="text-base text-center text-black font-normal">
                  Automate and optimize your sales cycle
                </p>
              </div>
            </div>
            <div className="bg-white shadow-md hover:shadow-xl border-2 transition duration-300 ease-in-out transform hover:scale-105">
              <div className="pt-6 pb-2 md:my-1 mx-6">
                <h6 className="text-xl font-medium flex items-center justify-center">
                  Professional
                </h6>
                <h1 className="text-4xl font-medium flex items-center justify-center py-2">
                  $8
                </h1>
                <p className="text-sm font-normal flex items-center justify-center py-1">
                  {" "}
                  /user/month billed monthly
                </p>
              </div>
              <div className="flex items-center justify-center text-[#e75828]">
                <NavLink to="/contact" className="flex my-1 text-[#e75828]">
                  <button className="bg-[#e75828] text-sm hover:bg-[#492196] hover:border-[#492196] border-2 border-[#e75828] text-white hover:text-whitefont-medium py-1 px-4 flex items-center rounded-full">
                    <span className="flex w-[140px] items-center justify-center">
                      Contact Us
                    </span>
                  </button>
                </NavLink>
              </div>
              <div className="my-4 py-2 md:px-4 mx-6">
                <p className="text-base text-center text-black font-normal">
                  Improve customer acquisition and accelerate growth
                </p>
              </div>
            </div>
            <div className="bg-white shadow-md hover:shadow-xl border-2 transition duration-300 ease-in-out transform hover:scale-105">
              <div className="pt-6 pb-2 md:my-1 mx-6">
                <h6 className="text-xl font-medium flex items-center justify-center">
                  Enterprise
                </h6>
                <h1 className="text-4xl font-medium flex items-center justify-center py-2">
                  $13
                </h1>
                <p className="text-sm font-normal flex items-center justify-center py-1">
                  {" "}
                  /user/month billed monthly
                </p>
              </div>
              <div className="flex items-center justify-center text-[#e75828]">
                <NavLink to="/contact" className="flex my-1 text-[#e75828]">
                  <button className="bg-[#e75828] text-sm hover:bg-[#492196] hover:border-[#492196] border-2 border-[#e75828] text-white hover:text-whitefont-medium py-1 px-4 flex items-center rounded-full">
                    <span className="flex w-[140px] items-center justify-center">
                      Contact Us
                    </span>
                  </button>
                </NavLink>
              </div>
              <div className="my-4 py-2 md:px-4 mx-6">
                <p className="text-base text-center text-black font-normal">
                  Manage global CX operations with the complete CRM
                </p>
              </div>
            </div>
            <div className="bg-white shadow-md hover:shadow-xl border-2 transition duration-300 ease-in-out transform hover:scale-105">
              <div className="pt-6 pb-2 md:my-1 mx-6">
                <h6 className="text-xl font-medium flex items-center justify-center">
                  Ultimate
                </h6>
                <h1 className="text-4xl font-medium flex items-center justify-center py-2">
                  $25
                </h1>
                <p className="text-sm font-normal flex items-center justify-center py-1">
                  {" "}
                  /user/month billed monthly
                </p>
              </div>
              <div className="flex items-center justify-center text-[#e75828]">
                <NavLink to="/contact" className="flex my-1 text-[#e75828]">
                  <button className="bg-[#e75828] text-sm hover:bg-[#492196] hover:border-[#492196] border-2 border-[#e75828] text-white hover:text-whitefont-medium py-1 px-4 flex items-center rounded-full">
                    <span className="flex w-[140px] items-center justify-center">
                      Contact Us
                    </span>
                  </button>
                </NavLink>
              </div>
              <div className="my-4 py-2 md:px-4 mx-6">
                <p className="text-base text-center text-black font-normal">
                  Scale exponentially with dedicated BI capabilities
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      {/* section-5 */}
      <section className="bg-gray-100 ">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 py-10 mx-6 lg:mx-10 md:mx-8 xl:mx-0">
            <div className="justify-center items-center pt-5 md:col-span-2">
              <div className="pt-5 pb-3 pb-md-5 md:px-0 text-center md:text-left">
                <p className="text-[2rem] font-normal text-black mb-3">
                  Get Started with Neptune Today!
                </p>
                <h2 className="text-xl font-normal text-black pb-3">
                  Unlock the potential of your business with Neptune, the AI conversational chatbot. Contact us now to embark on your journey to exceptional customer engagement.
                </h2>
                <NavLink
                  to="/contact"
                  className="bg-[#0d6efd] hover:bg-[#0d6dfdb7] mt-6 mb-2 text-white py-3 text-base px-6 inline-block transition duration-300 ease-in-out rounded-full"
                >
                  Discover Neptune
                </NavLink>
              </div>
            </div>
            <div className="md:flex justify-end items-center md:col-span-1">
              <img src={CTA} alt="CTA" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductNeptune;
