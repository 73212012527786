import React from 'react'
import caseData from '../data/caseData.json'
import Svg from '../../svg';
import SubData from '../data/sub-page-data.json'
import ServiceCardPrimary from './Components/serviceCardPrimary';
import ServiceCardSecondary from './Components/serviceCardSecondary';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';


const AnimationStudio = () => {

    const BlogData = SubData.filter(content => content.path === 'animation-studio')[0];

    return (

        <>
            <div className="relative overflow-hidden bg-[#492196]  pt-[80px] md:pt-[50px] lg:pt-[100px]">
            <Helmet>
        {/* Meta Title: On-Premise Infrastructure Migration Case Study | TechSmew Technologies */}
        <title>On-Premise Infrastructure Migration Case Study | TechSmew Technologies</title>
        
        {/* Meta Description: Read our case study on migrating on-premise infrastructure for an animation company. Learn about our successful transformation. */}
        <meta name="description" content="Read our case study on migrating on-premise infrastructure for an animation company. Learn about our successful transformation." />
        
        {/* Meta Keywords (for reference): Infrastructure migration, case study, animation company */}
        {/* Note: Meta keywords are not used by major search engines for SEO. */}
        <meta name="keywords" content="Infrastructure migration, case study, animation company" />
      </Helmet>
                <div className=" flex flex-wrap items-center max-w-7xl mx-auto">
                    {/* Hero */}
                    <div className="hero-content mx-auto text-center w-[85%] md:w-[92%] xl:w-[75%]">
                        <h1 className="mb-4 md:my-6 text-xl md:text-2xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug">
                            {BlogData.title}
                        </h1>
                        <p className=" md:px-4 pb-4 mx-auto mb-10 font-normal text-sm text-[#e4e4e4] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed">
                            {BlogData.discription}
                        </p>
                    </div>
                    <Svg />
                    <div className=" flex items-center justify-center absolute bottom-0 z-20  bg-gradient-to-r from-indigo-500 to-orange-500 px-6 py-2  rounded-t-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8 ">
                        <nav className="flex " aria-label="Breadcrumb">
                            <ol className="inline-flex items-center space-x-1 md:space-x-3">
                                <li>
                                    <div className="flex items-start">
                                        <a
                                            className="text-sm mx-1 md:mx-2 font-medium text-gray-100 hover:text-[#f89539] "
                                            href="/"
                                        >
                                            <Link 
                                            to={`/`}>
                                            Home
                                            </Link>
                                        </a>
                                    </div>
                                </li>
                                <li aria-current="page" className="text-white">
                                    /
                                    <span className="mx-1 md:mx-2 text-sm font-medium text-gray-100 hover:text-[#f89539] ">
                                        <Link
                                         to={"/case-studies"}>
                                        Case Studies
                                        </Link>
                                    </span>
                                </li>
                                <li aria-current="page">
                                    <div className="flex items-center  text-white">
                                        /
                                        <span className="mx-1 md:mx-2 text-sm font-medium text-gray-100 hover:text-[#f89539] dark:text-gray-400 dark:hover:text-white">
                                            {BlogData.breadCrumb}
                                        </span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>


            <div className="mx-auto py-4 md:py-6 max-w-7xl ">
                <div className="grid grid-cols-4 lg:grid-cols-12 md:grid-cols-9 gap-10 mx-6 lg:mx-10 md:mx-8 xl:mx-0">

                    <div className='col-span-4 lg:col-span-8  md:col-span-6 '>
                        {BlogData.details.map((detail, i) => (
                            <div key={i}>

                                {
                                    detail.heading && 
                                <h2 className="text-[2rem] leading-none  text-black text-left font-light mb-2">
                                    {detail.heading}
                                </h2>
                                }
                                {detail.content &&
                                    <p className="text-black  text-left  mb-6">
                                        {detail.content}
                                    </p>
                                }
                                {detail.subHeading &&
                                    detail.subHeading.map((subDetail, j) => (
                                        <div key={j}>
                                            <h2 className="text-[2rem] leading-none  font-light mb-1">
                                                <span>{subDetail.division}</span>
                                                {subDetail.heading}
                                            </h2>
                                            <p className="text-black  text-left  mb-6">
                                                {subDetail.content}
                                            </p>
                                        </div>
                                    ))}
                            </div>
                        ))}
                    </div>


                    <div className='col-span-4 lg:col-span-4 md:col-span-3'>
                        <div className=' grid grid-rows-1 md:grid-rows-2 gap-6 mx-auto'>

                            <div>
                                <div className="md:ml-auto w-[100%]">
                                    <div className=''>
                                        <h1 className="text-[2rem] leading-none  font-light md:mb-2">Instance Insights</h1>
                                        <div className="flex flex-nowrap flex-cols-3 mb-4 ">
                                            <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
                                            <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
                                            <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
                                        </div>
                                    </div>
                                </div>
                                <div className=' flex flex-col gap-8'>

                                    <ServiceCardPrimary
                                        image={caseData[1].url}
                                        title={caseData[1].title}
                                        details={caseData[1].detail}
                                        path={caseData[1].path}
                                    />
                                    <ServiceCardSecondary
                                        image={caseData[2].subUri}
                                        title={caseData[2].title}
                                        detail={caseData[2].detail}
                                        path={caseData[2].path}
                                        border="border-[#492196]"
                                    />
                                    <ServiceCardSecondary
                                        image={caseData[3].subUri}
                                        title={caseData[3].title}
                                        detail={caseData[3].detail}
                                        path={caseData[3].path}
                                        border="border-[#FF7C4E]"
                                    />
                                    <ServiceCardSecondary
                                       image={caseData[4].subUri}
                                        title={caseData[4].title}
                                        detail={caseData[4].detail}
                                        path={caseData[4].path}
                                        border="border-[#AB8E8E]"
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>




            </div>
        </>
    );
};

export default AnimationStudio
