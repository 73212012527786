import {
  ChartBarSquareIcon,
  CheckBadgeIcon,
  CheckIcon,
  ClipboardDocumentCheckIcon,
  LockClosedIcon,
  ShieldCheckIcon,
  SparklesIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { NavLink } from "react-router-dom";
import CTA from "../../assets/images/cta.webp";
import WorkFlow from "../../assets/images/work_flow.webp";
import HelpDeskSupport from "../../assets/images/it-infrastructure/help-desk-support/help-desk-support.jpg";
import TroubleshootingandResolution from "../../assets/images/it-infrastructure/help-desk-support/troubleshooting-and-resolution.jpg";
import UserTraining from "../../assets/images/it-infrastructure/help-desk-support/user-training.jpg";
import IncidentTracking from "../../assets/images/it-infrastructure/help-desk-support/incident-tracking.jpg";
import { Helmet } from 'react-helmet';
import Svg from "../svg";


function Helpdesksupport() {
  const ServiceCard = ({ icon, title, details }) => {
    return (
      <div className="mb-3 md:mb-3 xl:mb-5 bg-white shadow-md hover:shadow-xl md:px-0 xl:px-0 border-2  transition duration-300 ease-in-out transform hover:scale-105">
        <div className="md:flex justify-start mb-4 items-start top-1/2">
          <img src={icon} alt="Service Icon" className="w-full" />
        </div>
        <div className="ml-2 mr-2">
          <h1 className="ml-2 mr-2 mb-2 text-2xl font-light text-black text-left">
            {title}
          </h1>
          <p className="ml-2 mr-2 mb-6  text-black">{details}</p>
        </div>
      </div>
    );
  };

  return (
    <main className="itInfrastructure_help_desk_support">
      <Helmet>
        {/* Meta Title: Help Desk Support Services | TechSmew Technologies */}
        <title>Help Desk Support Services | TechSmew Technologies</title>
        
        {/* Meta Description: Discover our help desk support services at TechSmew Technologies. Provide efficient IT support to your employees and customers. */}
        <meta name="description" content="Discover our help desk support services at TechSmew Technologies. Provide efficient IT support to your employees and customers." />
        
        {/* Meta Keywords (for reference): Help desk support, IT support services, customer support */}
        {/* Note: Meta keywords are not used by major search engines for SEO. */}
        <meta name="keywords" content="Help desk support, IT support services, customer support" />
      </Helmet>
      <div className="relative overflow-hidden bg-[#492196]  pt-[120px] md:pt-[130px] lg:pt-[160px]">
        <div className="max-w-7xl mx-auto">
          <div className=" flex flex-wrap items-center">
            <div className="w-full px-4">
              <div className="hero-content mx-auto text-center">
                <h1 className="mb-8 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug">
                  Uninterrupted IT operations through timely help desk support
                </h1>
                <p className=" px-4 mx-auto mb-10 font-normal text-base text-[#e4e4e4] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed">
                  Empower your digital ecosystem by leveraging our expert
                  assistance and prompt guidance to resolve issues
                  effectively.Proactively monitor IT assets to swiftly identify
                  potential problems, identify the root cause and uproot the issue to
                  ensure minimal downtime, maximal productivity and nil impact
                  on business.
                </p>
              </div>
            </div>

        <Svg/>
            <div className="flex items-center justify-center absolute bottom-0 z-20  bg-gradient-to-r from-indigo-500 to-orange-500 px-6 pt-1 md:py-2  rounded-t-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8">
              <nav className="flex " aria-label="Breadcrumb">
                <ol className="inline-flex items-centerw space-x-1 md:space-x-3">
                  <li>
                    <div className="flex items-center">
                      <NavLink
                        className="text-[9px] md:text-sm font-medium text-gray-100 hover:text-[#f89539] "
                        to="/"
                      >
                        Home
                      </NavLink>
                    </div>
                  </li>
                  <li aria-current="page" className="h-full">
                    <div className="flex items-center text-white ">
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-medium md:ml-2">
                        Solutions
                      </span>
                    </div>
                  </li>
                  <li aria-current="page">
                    <NavLink
                      to="/solutions/it-infrastructure"
                      className="flex items-center text-white"
                    >
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-medium md:ml-2">
                        IT-Infrastructure
                      </span>
                    </NavLink>
                  </li>
                  <li aria-current="page">
                    <div className="flex items-center  text-white">
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-medium md:ml-2">
                        HDS
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-white">
        <div className="max-w-7xl mx-auto my-6 md:pb-18">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8">
            <h1 className=" text-black mb-1 text-[2rem] font-light">
              Expert Help Desk Support for Seamless Solutions
            </h1>
            <div className="flex flex-nowrap flex-cols-3 mb-3 ">
              <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
              <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
              <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
            </div>
            <p className="text-base font-normal text-black">
              Experience seamless solutions with our expert help desk support
              services. Our dedicated team is here to swiftly address your
              concerns, ensuring uninterrupted productivity. Trust us for
              reliable assistance tailored to your unique needs.
            </p>
          </div>
          <div className="py-6 md:py-8 mx-6 grid grid-cols-1 xl:grid-cols-4 md:grid-cols-2 gap-6 xl:mx-0 lg:mx-10 md:mx-8">
            <ServiceCard
              icon={HelpDeskSupport}
              title="24/7 Help Desk Support"
              details="Round-the-clock assistance to quickly assess the situation and deliver comprehensive support to promptly resolve it."
            />
            <ServiceCard
              icon={TroubleshootingandResolution}
              title="Troubleshooting and Resolution"
              details="Remote and on-site assistance from our skilled technicians is needed to diagnose and deliver technical solutions with fast response times."
            />
            <ServiceCard
              icon={UserTraining}
              title="User Training"
              details="Enhancing your employee's technical skills by offering training programs and self-help resources to aid in basic troubleshooting."
            />
            <ServiceCard
              icon={IncidentTracking}
              title="Incident Tracking"
              details="Complete incident management from record creation, tracking resolution progress to delivering insightful reports to stakeholders."
            />
          </div>
        </div>
      </section>
      <section className="bg-gray-100 py-10">
        <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-1 gap-0 lg:gap-4 ">
          <div className="md:flex justify-start md:pr-4 xl:pl-0 items-center rounded-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8">
            <div className="col-span-1">
              <img src={WorkFlow} alt="work flow" className="w-full" />
              <div className="pt-4">
                <h1 className="text-[2rem] font-light mb-1">Our Workflow</h1>
              </div>
              <div className="flex flex-nowrap flex-cols-3">
                <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
                <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
                <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
              </div>
              <div className="py-3">
                <h1 className=" text-black text-base font-normal">
                  Streamlined ticket submission & filtering, precise issue
                  diagnosis & solution planning, seamless remote/on-site
                  support, collaborative escalation, skill-boosting training,
                  proactive post-support for ultimate satisfaction.
                </h1>
              </div>
            </div>
          </div>
          <div className="col-span-2 mx-12 xl:mx-0 lg:mx-10 md:mx-16 pt-8 xl:ml-10">
            <ol className="relative text-black border-l border-gray-300 dark:border-gray-700 ">
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <CheckIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Dedicated platform for your team to submit tickets, which gets
                  filtered based on severity and urgency to ensure issues are
                  promptly faced and resolved.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <ShieldCheckIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                Thoroughly diagnose the said issues, pinpoint the root cause and assess multiple courses of action to identify the best plan for an optimal solution.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <LockClosedIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Remote and on-site assistance to promptly resolve the issue
                  for smooth operation of the technology infrastructure.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <ChartBarSquareIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Efficient escalation support by collaborating with in-house
                  teams to provide detailed support and guidance.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <SparklesIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Empowering your team with training sessions and how-to guides
                  to develop basic troubleshooting skills.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <CheckIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Proactively offer post - support to ensure your team is
                  satisfied with the resolution by conducting surveys and
                  receiving feedbacks.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="max-w-7xl mx-auto my-6 md:pb-18">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8">
            <h1 className="text-[2rem] text-black text-left font-light mb-1">
              Our Unique Value Proposition
            </h1>
            <div className="flex flex-nowrap flex-cols-3 mb-3 ">
              <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
              <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
              <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
            </div>
            <p className=" text-black text-base font-normal">
              Navigating technical challenges is a breeze with our dedicated
              Help Desk Support. Unlock unrivalled assistance and elevate your
              productivity as we streamline solutions tailored to your needs.
              Experience support redefined, where your success is our priority.
            </p>
          </div>
          <div className="py-6 md:py-10 grid grid-cols-1 md:grid-cols-3 gap-6 mx-6 lg:mx-10 md:mx-8 xl:mx-0">
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#492196] rounded-lg w-12 h-12">
                  <CheckBadgeIcon className="h-8 w-8 text-white stroke-[1]" />
                </div>
              </div>
              <h3 className="text-2xl font-light text-black mb-2 text-center">
                Rapid Resolution
              </h3>
              <p className="text-black font-normal text-base  text-center">
                Minimal disruption, maximal productivity and prompt issue
                resolution through quick responses and technical expertise.
              </p>
            </div>
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#492196] rounded-lg w-12 h-12">
                  <ClipboardDocumentCheckIcon className="h-8 w-8 text-white stroke-[1]" />
                </div>
              </div>
              <h3 className="text-2xl font-light text-black mb-2 text-center">
                In-depth Understanding
              </h3>
              <p className="text-black font-normal text-base  text-center">
                Deep knowledge of your business goals and objectives that helps
                in delivering solutions that resonates with your requirements.
              </p>
            </div>
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#492196] rounded-lg w-12 h-12">
                  <UserIcon className="h-8 w-8 text-white stroke-[1]" />
                </div>
              </div>
              <h3 className="text-2xl font-light text-black mb-2 text-center">
                Exceptional experience
              </h3>
              <p className="text-black font-normal text-base  text-center">
                Complete transparency and communication to maximise client
                satisfaction and deliver excellent customer support.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-100">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 py-10 mx-6 lg:mx-10 md:mx-8 xl:mx-0">
            <div className="justify-center items-center pt-5 md:col-span-2">
              <div className="pt-5 pb-3 pb-md-5 md:px-0 text-center md:text-left">
                <p className="text-[1.5rem] font-normal text-black mb-3">
                  Ready to get started?
                </p>
                <h2 className="text-[2rem] font-normal text-black pb-3">
                Support Beyond Expectations Help Desk Excellence
                {" "}
                Resolve issues quickly with our responsive help desk.
                </h2>
                <NavLink
                  to="/contact"
                  className="bg-[#0d6efd] hover:bg-[#0d6dfdb7] mt-6 mb-2 text-white py-3 text-base px-6 inline-block transition duration-300 ease-in-out rounded-full"
                >
                  Get Support
                </NavLink>
              </div>
            </div>
            <div className="md:flex justify-end items-center md:col-span-1">
              <img src={CTA} alt="CTA" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Helpdesksupport;
