import {
  ChartBarSquareIcon,
  CheckBadgeIcon,
  CheckIcon,
  ClipboardDocumentCheckIcon,
  LockClosedIcon,
  ShieldCheckIcon,
  SparklesIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { NavLink } from "react-router-dom";
import CTA from "../../assets/images/cta.webp";
import WorkFlow from "../../assets/images/work_flow.webp";
import EmailCampaignStrategy from "../../assets/images/digital-marketing/email-marketing-campaigns/email-campaign-strategy.webp";
import EmailListManagement from "../../assets/images/digital-marketing/email-marketing-campaigns/email-list-management.webp";
import EngagingEmailContentCreation from "../../assets/images/digital-marketing/email-marketing-campaigns/engaging-email-content-creation.webp";
import PerformanceTrackingandAnalytics from "../../assets/images/digital-marketing/email-marketing-campaigns/performance-tracking-and-analytics.webp";
import { Helmet } from 'react-helmet';
import Svg from "../svg";


function Emailmarketingcampaigns() {
  const ServiceCard = ({ icon, title, details }) => {
    return (
      <div className="mb-3 md:mb-3 xl:mb-5 bg-white shadow-md hover:shadow-xl md:px-0 xl:px-0 border-2  transition duration-300 ease-in-out transform hover:scale-105">
        <div className="md:flex justify-start mb-4 items-start top-1/2">
          <img src={icon} alt="Service Icon" className="w-full" />
        </div>
        <div className="ml-2 mr-2">
          <h1 className="ml-2 mr-2 mb-2 text-2xl font-light text-black text-left">
            {title}
          </h1>
          <p className="ml-2 mr-2 mb-6  text-black">{details}</p>
        </div>
      </div>
    );
  };

  return (
    <main className="digitalmarketing_email_marketing_campaigns">
      <Helmet>
        {/* Meta Title: Email Marketing Campaigns | TechSmew Technologies */}
        <title>Email Marketing Campaigns | TechSmew Technologies</title>
        
        {/* Meta Description: Discover our email marketing campaigns at TechSmew Technologies. Engage your audience through effective email marketing. */}
        <meta name="description" content="Discover our email marketing campaigns at TechSmew Technologies. Engage your audience through effective email marketing." />
        
        {/* Meta Keywords (for reference): Email marketing campaigns, email marketing services */}
        {/* Note: Meta keywords are not used by major search engines for SEO. */}
        <meta name="keywords" content="Email marketing campaigns, email marketing services" />
      </Helmet>
      <div className="relative overflow-hidden bg-[#492196]  pt-[120px] md:pt-[130px] lg:pt-[160px]">
        <div className="max-w-7xl mx-auto">
          <div className=" flex flex-wrap items-center">
            <div className="w-full px-4">
              <div className="hero-content mx-auto text-center">
                <h1 className="mb-8 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug">
                Enhance Engagement and Supercharge Conversions with Effective Email Marketing
                </h1>
                <p className=" px-4 mx-auto mb-10 font-normal text-base text-[#e4e4e4] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed">
                Utilize the power of email marketing to connect with your audience, 
                nurture relationships and drive meaningful results for your business. 
                Collaborate with us to craft targeted email campaigns, create 
                compelling content and optimize your email marketing strategy 
                for maximum engagement and conversions.
                </p>
              </div>
            </div>

          <Svg/>

            <div className="flex items-center justify-center absolute bottom-0 z-20  bg-gradient-to-r from-indigo-500 to-orange-500 px-6 pt-1 md:py-2  rounded-t-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8">
              <nav className="flex " aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                  <li>
                    <div className="flex items-center">
                      <NavLink
                        className="text-[9px] md:text-sm font-medium text-gray-100 hover:text-[#f89539] "
                        to="/"
                      >
                        Home
                      </NavLink>
                    </div>
                  </li>
                  <li aria-current="page" className="h-full">
                    <div className="flex items-center text-white ">
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-medium md:ml-2">
                        Solutions
                      </span>
                    </div>
                  </li>
                  <li aria-current="page">
                    <NavLink
                      to="/solutions/digital-marketing"
                      className="flex items-center text-white"
                    >
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-medium md:ml-2">
                        Digital Marketing
                      </span>
                    </NavLink>
                  </li>
                  <li aria-current="page">
                    <div className="flex items-center  text-white">
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-medium md:ml-2">
                        EMC
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-white">
        <div className="max-w-7xl mx-auto my-6 md:pb-18">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8">
            <h1 className="text-2xl text-black text-left font-medium mb-1">
              Personalized Email Marketing Strategies
            </h1>
            <div className="flex flex-nowrap flex-cols-3 mb-3 ">
              <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
              <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
              <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
            </div>
            <p className="text-lg font-normal text-black">
              Connect with your audience on a personal level and drive real
              results through our strategic email marketing campaigns. At
              TechSmew Technologies we understand that effective communication
              is the key to building lasting relationships with your customers.
            </p>
          </div>
          <div className="py-6 md:py-8 mx-6 grid grid-cols-1 xl:grid-cols-4 md:grid-cols-2 gap-6 xl:mx-0 lg:mx-10 md:mx-8">
            <ServiceCard
              icon={EmailCampaignStrategy}
              title="Email Campaign Strategy"
              details="Develop a customised email marketing strategy that aligns with your business goals, target audience and customer lifecycle."
            />
            <ServiceCard
              icon={EmailListManagement}
              title="Email List Management"
              details="Segment your email list based on demographics, preferences and behaviours to deliver personalised and relevant content to each subscriber."
            />
            <ServiceCard
              icon={EngagingEmailContentCreation}
              title="Engaging Email Content Creation"
              details="Craft persuasive and engaging email content that captures attention, drives action and builds customer loyalty."
            />
            <ServiceCard
              icon={PerformanceTrackingandAnalytics}
              title="Performance Tracking and Analytics"
              details="Monitor and analyse key email metrics, such as open rates, click-through rates and conversions, to optimise campaign performance and drive continuous improvement."
            />
          </div>
        </div>
      </section>
      <section className="bg-gray-100 py-10">
        <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-1 gap-0 lg:gap-4 ">
          <div className="md:flex justify-start md:pr-4 xl:pl-0 items-center rounded-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8">
            <div className="col-span-1">
              <img src={WorkFlow} alt="work flow" className="w-full" />
              <div className="pt-4">
                <h1 className="text-[2rem] font-light mb-1">Our Workflow</h1>
              </div>
              <div className="flex flex-nowrap flex-cols-3">
                <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
                <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
                <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
              </div>
              <div className="py-3">
                <h1 className=" text-black">
                  Gain clarity on your business goals, audience and customer
                  path to shape a potent email marketing plan. Develop engaging
                  email material tailored to your audience, spotlighting your
                  offerings and stimulating interaction and sales.
                </h1>
              </div>
            </div>
          </div>
          <div className="col-span-2 mx-12 xl:mx-0 lg:mx-10 md:mx-16 pt-8 xl:ml-10">
            <ol className="relative text-black border-l border-gray-300 dark:border-gray-700 ">
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <CheckIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className="text-base pl-2 text-black font-normal">
                  Understand your business objectives, target audience and
                  customer journey to develop an effective email marketing
                  strategy.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <ShieldCheckIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className="text-base pl-2 text-black font-normal">
                  Create compelling email content that speaks to your audience,
                  showcases your products or services and encourages engagement
                  and conversions.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <LockClosedIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className="text-base pl-2 text-black font-normal">
                  Design visually appealing email templates that align with your
                  brand identity and create a cohesive brand experience.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <ChartBarSquareIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className="text-base pl-2 text-black font-normal">
                  Implement and launch your email campaigns, ensuring proper
                  segmentation, personalization and timing for optimal results.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <SparklesIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className="text-base pl-2 text-black font-normal">
                  Track campaign performance, analyse key metrics and make
                  data-driven decisions to improve future campaigns and maximise
                  ROI.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="max-w-7xl mx-auto my-6 md:pb-18">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8">
            <h1 className="text-2xl text-black text-left mb-1 font-medium ">
              Our Unique Value Proposition
            </h1>
            <div className="flex flex-nowrap flex-cols-3 mb-3 ">
              <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
              <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
              <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
            </div>
            <p className="text-lg font-normal text-black">
              Elevate your brand's reach and impact with our Email Marketing
              Campaigns. Engage your audience on a personal level, driving
              conversions and fostering lasting connections. Harness the power
              of tailored communication to achieve unparalleled marketing
              results.
            </p>
          </div>
          <div className="py-6 md:py-10 grid grid-cols-1 md:grid-cols-3 gap-6 mx-6 lg:mx-10 md:mx-8 xl:mx-0">
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#492196] rounded-lg w-12 h-12">
                  <CheckBadgeIcon className="h-8 w-8 text-white stroke-[1]" />
                </div>
              </div>
              <h3 className="text-xl font-medium text-black mb-2 text-center">
                Personalised Approach
              </h3>
              <p className="text-black font-normal text-base  text-center">
                We tailor your email marketing campaigns to resonate with your
                audience, delivering relevant content that drives engagement and
                conversions.
              </p>
            </div>
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#492196] rounded-lg w-12 h-12">
                  <ClipboardDocumentCheckIcon className="h-8 w-8 text-white stroke-[1]" />
                </div>
              </div>
              <h3 className="text-xl font-medium text-black mb-2 text-center">
                Automation and Efficiency
              </h3>
              <p className="text-black font-normal text-base  text-center">
                Our expertise in email automation helps you streamline your
                campaigns, save time and deliver timely and personalised
                messages to your subscribers.
              </p>
            </div>
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#492196] rounded-lg w-12 h-12">
                  <UserIcon className="h-8 w-8 text-white stroke-[1]" />
                </div>
              </div>
              <h3 className="text-xl font-medium text-black mb-2 text-center">
                Performance Tracking and Optimization
              </h3>
              <p className="text-black font-normal text-base  text-center">
                We continuously monitor campaign performance, analyse data and
                optimise your email strategy to ensure maximum effectiveness.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-100">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 py-10 mx-6 lg:mx-10 md:mx-8 xl:mx-0">
            <div className="justify-center items-center pt-5 md:col-span-2">
              <div className="pt-5 pb-3 pb-md-5 md:px-0 text-center md:text-left">
                <p className="text-[1.5rem] font-normal text-black mb-3">
                  Ready to get started?
                </p>
                <h2 className="text-[2rem] font-normal text-black pb-3">
                Powerful Email Marketing
                {" "}
                Engage and nurture your audience with personalized email campaigns.
                </h2>
                <NavLink
                  to="/contact"
                  className="bg-[#0d6efd] hover:bg-[#0d6dfdb7] mt-6 mb-2 text-white py-3 text-base px-6 inline-block transition duration-300 ease-in-out rounded-full"
                >
                 Send Emails
                </NavLink>
              </div>
            </div>
            <div className="md:flex justify-end items-center md:col-span-1">
              <img src={CTA} alt="CTA" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Emailmarketingcampaigns;
