import {
  ChartBarSquareIcon,
  CheckBadgeIcon,
  CheckIcon,
  ClipboardDocumentCheckIcon,
  LockClosedIcon,
  ShieldCheckIcon,
  SparklesIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { NavLink } from "react-router-dom";
import CTA from "../../assets/images/cta.webp";
import WorkFlow from "../../assets/images/work_flow.webp";
import NetworkAssessment from "../../assets/images/it-infrastructure/network-design-and-implementation/network-assessment.jpg";
import NetworkArchitecture from "../../assets/images/it-infrastructure/network-design-and-implementation/network-architecture.jpg";
import NetworkImplementation from "../../assets/images/it-infrastructure/network-design-and-implementation/network-implementation.jpg";
import Optimization from "../../assets/images/it-infrastructure/network-design-and-implementation/optimization.jpg";
import { Helmet } from 'react-helmet';
import Svg from "../svg";


function Networkdesignandimplementation() {
  const ServiceCard = ({ icon, title, details }) => {
    return (
      <div className="mb-3 md:mb-3 xl:mb-5 bg-white shadow-md hover:shadow-xl md:px-0 xl:px-0 border-2  transition duration-300 ease-in-out transform hover:scale-105">
        <div className="md:flex justify-start mb-4 items-start top-1/2">
          <img src={icon} alt="Service Icon" className="w-full" />
        </div>
        <div className="ml-2 mr-2">
          <h1 className="ml-2 mr-2 mb-2 text-2xl font-light text-black text-left">
            {title}
          </h1>
          <p className="ml-2 mr-2 mb-6  text-black">{details}</p>
        </div>
      </div>
    );
  };

  return (
    <main className="itInfrastructure_network_design_and_implementation">
       <Helmet>
        {/* Meta Title: Network Design and Implementation | TechSmew Technologies */}
        <title>Network Design and Implementation | TechSmew Technologies</title>
        
        {/* Meta Description: TechSmew Technology offers network design and implementation services. Build a secure and efficient network infrastructure. */}
        <meta name="description" content="TechSmew Technology offers network design and implementation services. Build a secure and efficient network infrastructure." />
        
        {/* Meta Keywords (for reference): Network design, network implementation, network security */}
        {/* Note: Meta keywords are not used by major search engines for SEO. */}
        <meta name="keywords" content="Network design, network implementation, network security" />
      </Helmet>
      <div className="relative overflow-hidden bg-[#492196]  pt-[120px] md:pt-[130px] lg:pt-[160px]">
        <div className="max-w-7xl mx-auto">
          <div className=" flex flex-wrap items-center">
            <div className="w-full px-4">
              <div className="hero-content mx-auto text-center">
                <h1 className="mb-8 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug">
                  One stop solution for reliable connectivity and scalability!
                </h1>
                <p className=" px-4 mx-auto mb-10 font-normal text-base text-[#e4e4e4] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed">
                  Driving your business forward through robust networks built by
                  our exceptional team of professionals with decades of
                  experience. Our deep understanding of your requirements
                  supplemented by our cutting- edge technologies and expert
                  technicians helps us to deploy solutions that are scalable,
                  reliable and provide seamless communication and connectivity.
                </p>
              </div>
            </div>

        <Svg/>

            <div className="flex items-center justify-center absolute bottom-0 z-20  bg-gradient-to-r from-indigo-500 to-orange-500 px-6 pt-1 md:py-2  rounded-t-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8">
              <nav className="flex " aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                  <li>
                    <div className="flex items-center">
                      <NavLink
                        className="text-[9px] md:text-sm font-medium text-gray-100 hover:text-[#f89539] "
                        to="/"
                      >
                        Home
                      </NavLink>
                    </div>
                  </li>
                  <li aria-current="page" className="h-full">
                    <div className="flex items-center text-white ">
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-medium md:ml-2">
                        Solutions
                      </span>
                    </div>
                  </li>
                  <li aria-current="page">
                    <NavLink
                      to="/solutions/it-infrastructure"
                      className="flex items-center text-white"
                    >
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-medium md:ml-2">
                        IT-Infrastructure
                      </span>
                    </NavLink>
                  </li>
                  <li aria-current="page">
                    <div className="flex items-center  text-white">
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-medium md:ml-2">
                        ND&I
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-white">
        <div className="max-w-7xl mx-auto my-6 md:pb-18">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8">
            <h1 className="text-[2rem] text-black text-left font-light mb-1">
              Empower Your Business with Expert Network Design & IT Solutions
            </h1>
            <div className="flex flex-nowrap flex-cols-3 mb-3 ">
              <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
              <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
              <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
            </div>
            <p className="text-base font-normal text-black">
              Transform your digital landscape with our comprehensive IT
              solutions, offering unparalleled Network Design and Implementation
              expertise to drive your business forward.Unlock the potential of
              your technology ecosystem with dedicated IT Support services,
              providing round-the-clock assistance for all your network needs.
            </p>
          </div>
          <div className="py-6 md:py-8 mx-6 grid grid-cols-1 xl:grid-cols-4 md:grid-cols-2 gap-6 xl:mx-0 lg:mx-10 md:mx-8">
            <ServiceCard
              icon={NetworkAssessment}
              title="Network Assessment"
              details="Designing future-proof solutions by understanding your current standpoint and building on top of it while removing vulnerabilities."
            />
            <ServiceCard
              icon={NetworkArchitecture}
              title="Network Architecture"
              details="From designing configurations to selecting protocols, our services will take care of the whole process."
            />
            <ServiceCard
              icon={NetworkImplementation}
              title="Network Implementation"
              details="We take great care in installing and integrating every critical component to ensure optimal performance."
            />
            <ServiceCard
              icon={Optimization}
              title="Optimization"
              details="From fine-tuning configurations to implementing security measures, our thorough optimization leaves no stone unturned."
            />
          </div>
        </div>
      </section>
      <section className="bg-gray-100 py-10">
        <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-1 gap-0 lg:gap-4 ">
          <div className="md:flex justify-start md:pr-4 xl:pl-0 items-center rounded-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8">
            <div className="col-span-1">
              <img src={WorkFlow} alt="work flow" className="w-full" />
              <div className="pt-4">
                <h1 className="text-[2rem] font-light mb-1">Our Workflow</h1>
              </div>
              <div className="flex flex-nowrap flex-cols-3">
                <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
                <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
                <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
              </div>
              <div className="py-3">
                <h1 className=" text-black text-base font-normal">
                  Comprehensive analysis informs enhancements needed. Plan
                  formulated, considering security and innovation. Equipment
                  sourced, installation supported, performance optimised,
                  documented and sustained.
                </h1>
              </div>
            </div>
          </div>
          <div className="col-span-2 mx-12 xl:mx-0 lg:mx-10 md:mx-16 pt-8 xl:ml-10">
            <ol className="relative text-black border-l border-gray-300 dark:border-gray-700 ">
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <CheckIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Extensive analysis of your current network infrastructure and
                  gathering business requirements to outline the areas that need
                  to be improved.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <ShieldCheckIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Consider a multitude of factors, including security protocols and future advancements, to draft a plan to present to key
                  stakeholders.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <LockClosedIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Leveraging our network of vendors and keeping in mind budget
                  and other specifications, we source equipment that best fit
                  your interests.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <ChartBarSquareIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Continual support throughout the installation process is required to
                  ensure proper connectivity and minimal downtime.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <SparklesIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Meticulously test and optimise to reach desired performance
                  levels and meet business requirements.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <CheckIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Detailed documentation of the network implementation process for future reference is accompanied by training sessions for IT
                  employees.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <ShieldCheckIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Consistent monitoring and post-implementation support to
                  ensure smooth operation.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="max-w-7xl mx-auto my-6 md:pb-18">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8">
            <h1 className="text-[2rem] text-black text-left mb-1 font-light">
              Our Unique Value Proposition
            </h1>
            <div className="flex flex-nowrap flex-cols-3 mb-3 ">
              <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
              <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
              <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
            </div>
            <p className="text-base font-normal text-black">
              We redefine IT support through our unwavering commitment to
              providing unparalleled solutions. With a relentless focus on
              innovation, we empower businesses to thrive in the digital
              landscape. Experience a new era of IT partnership that prioritises
              your success and transforms challenges into opportunities.
            </p>
          </div>
          <div className="py-6 md:py-10 grid grid-cols-1 md:grid-cols-3 gap-6 mx-6 lg:mx-10 md:mx-8 xl:mx-0">
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#492196] rounded-lg w-12 h-12">
                  <CheckBadgeIcon className="h-8 w-8 text-white stroke-[1]" />
                </div>
              </div>
              <h3 className="text-2xl font-light text-black mb-2 text-center">
                Reliable Assistance
              </h3>
              <p className="text-black font-normal text-base  text-center">
                Our around-the-clock assistance from our expert team extends
                beyond initial implementation and works till the end.
              </p>
            </div>
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#492196] rounded-lg w-12 h-12">
                  <ClipboardDocumentCheckIcon className="h-8 w-8 text-white stroke-[1]" />
                </div>
              </div>
              <h3 className="text-2xl font-light text-black mb-2 text-center">
                Scalable Solutions
              </h3>
              <p className="text-black font-normal text-base text-center">
                Our services are designed with flexibility and adaptability in
                mind, thus providing timeless solutions.
              </p>
            </div>
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#492196] rounded-lg w-12 h-12">
                  <UserIcon className="h-8 w-8 text-white stroke-[1]" />
                </div>
              </div>
              <h3 className="text-2xl font-light text-black mb-2 text-center">
                Expertise
              </h3>
              <p className="text-black font-normal text-base  text-center">
                Our skilled technicians have a knack for technology and
                constantly update themselves on changing trends, thus ensuring premium services.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-100">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 py-10 mx-6 lg:mx-10 md:mx-8 xl:mx-0">
            <div className="justify-center items-center pt-5 md:col-span-2">
              <div className="pt-5 pb-3 pb-md-5 md:px-0 text-center md:text-left">
                <p className="text-[1.5rem] font-normal text-black mb-3">
                  Ready to get started?
                </p>
                <h2 className="text-[2rem] font-normal text-black pb-3">
                Expert Network Solutions
                {" "}
                Design and implement networks that drive seamless communication.
                </h2>
                <NavLink
                  to="/contact"
                  className="bg-[#0d6efd] hover:bg-[#0d6dfdb7] mt-6 mb-2 text-white py-3 text-base px-6 inline-block transition duration-300 ease-in-out rounded-full"
                >
                  Network Now
                </NavLink>
              </div>
            </div>
            <div className="md:flex justify-end items-center md:col-span-1">
              <img src={CTA} alt="CTA" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Networkdesignandimplementation;
