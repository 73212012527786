import React from 'react';
import {
    CheckIcon
} from "@heroicons/react/24/outline";

const PriceTable = () => {
    return (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mx-8 lg:mx-8">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase dark:text-gray-400">
                    <tr>
                        <th scope="col" className="border-b-2 px-6 py-3 bg-gray-50 dark:bg-gray-800">
                            Features
                        </th>
                        <th scope="col" className="border-b-2 px-6 py-3">
                            Basic Plan
                        </th>
                        <th scope="col" className="border-b-2 px-6 py-3 bg-gray-50 dark:bg-gray-800">
                            Standard Plan
                        </th>
                        <th scope="col" className="border-b-2 px-6 py-3">
                            Premium Plan
                        </th>
                        <th scope="col" className="border-b-2 px-6 py-3 bg-gray-50 dark:bg-gray-800">
                            Pro Plan
                        </th>
                        <th scope="col" className="border-b-2 px-6 py-3">
                            Enterprise Plan
                        </th>

                    </tr>
                </thead>
                <tbody>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                        <th scope="row" className="px-6 md:py-4 py-2  font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            Advanced NLP
                        </th>
                        <td className="px-6 md:py-4 py-2  "><CheckIcon className='w-8 text-gray-400 ' /> </td>
                        <td className="px-6 md:py-4 py-2  bg-gray-50 dark:bg-gray-800"><CheckIcon className='w-8 text-gray-400 ' /> </td>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /> </td>
                        <td className="px-6 md:py-4 py-2  bg-gray-50 dark:bg-gray-800"><CheckIcon className='w-8 text-gray-400 ' /> </td>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /> </td>
                    </tr>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                        <th scope="row" className="px-6 md:py-4 py-2  font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            AI-Driven Interactions
                        </th>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2  bg-gray-50 dark:bg-gray-800"><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2  bg-gray-50 dark:bg-gray-800"><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /></td>
                    </tr>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                        <th scope="row" className="px-6 md:py-4 py-2  font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            ML-Powered Recommendations
                        </th>
                        <td className="px-6 md:py-4 py-2 "></td>
                        <td className="px-6 md:py-4 py-2  bg-gray-50 dark:bg-gray-800"></td>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2  bg-gray-50 dark:bg-gray-800"><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /></td>
                    </tr>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                        <th scope="row" className="px-6 md:py-4 py-2  font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            Automation Excellence
                        </th>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2  bg-gray-50 dark:bg-gray-800"><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2  bg-gray-50 dark:bg-gray-800"><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /></td>
                    </tr>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                        <th scope="row" className="px-6 md:py-4 py-2  font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            Multichannel Support
                        </th>
                        <td className="px-6 md:py-4 py-2 "></td>
                        <td className="px-6 md:py-4 py-2  bg-gray-50 dark:bg-gray-800"><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2  bg-gray-50 dark:bg-gray-800"><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /></td>
                    </tr>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                        <th scope="row" className="px-6 md:py-4 py-2  font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            Real-Time Analytics
                        </th>
                        <td className="px-6 md:py-4 py-2 "></td>
                        <td className="px-6 md:py-4 py-2  bg-gray-50 dark:bg-gray-800"></td>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2  bg-gray-50 dark:bg-gray-800"><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /></td>
                    </tr>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                        <th scope="row" className="px-6 md:py-4 py-2  font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            Seamless Integration
                        </th>
                        <td className="px-6 md:py-4 py-2 "></td>
                        <td className="px-6 md:py-4 py-2  bg-gray-50 dark:bg-gray-800"></td>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2  bg-gray-50 dark:bg-gray-800"><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /></td>
                    </tr>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                        <th scope="row" className="px-6 md:py-4 py-2  font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            Customization and Branding
                        </th>
                        <td className="px-6 md:py-4 py-2 "></td>
                        <td className="px-6 md:py-4 py-2  bg-gray-50 dark:bg-gray-800"><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2  bg-gray-50 dark:bg-gray-800"><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /></td>
                    </tr>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                        <th scope="row" className="px-6 md:py-4 py-2  font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            24/7 Availability
                        </th>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2  bg-gray-50 dark:bg-gray-800"><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2  bg-gray-50 dark:bg-gray-800"><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /></td>
                    </tr>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                        <th scope="row" className="px-6 md:py-4 py-2  font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            Data Security
                        </th>
                        <td className="px-6 md:py-4 py-2 "></td>
                        <td className="px-6 md:py-4 py-2  bg-gray-50 dark:bg-gray-800"><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2  bg-gray-50 dark:bg-gray-800"><CheckIcon className='w-8 text-gray-400 ' /></td>
                        <td className="px-6 md:py-4 py-2 "><CheckIcon className='w-8 text-gray-400 ' /></td>
                    </tr>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                        <th scope="row" className="px-6 md:py-4 py-2  font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            Price/user/month
                        </th>
                        <td className="text-sm font-bold px-6 md:py-4 py-2  ">$2</td>
                        <td className="text-sm font-bold px-6 md:py-4 py-2  bg-gray-50 dark:bg-gray-800">$5</td>
                        <td className="text-sm font-bold px-6 md:py-4 py-2 ">$6</td>
                        <td className="text-sm font-bold px-6 md:py-4 py-2  bg-gray-50 dark:bg-gray-800">$14</td>
                        <td className="text-sm font-bold px-6 md:py-4 py-2 ">Contact Us</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default PriceTable;          
