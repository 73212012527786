import {
  ChartBarSquareIcon,
  CheckBadgeIcon,
  CheckIcon,
  ClipboardDocumentCheckIcon,
  LockClosedIcon,
  ShieldCheckIcon,
  SparklesIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { NavLink } from "react-router-dom";
import CTA from "../../assets/images/cta.webp";
import WorkFlow from "../../assets/images/work_flow.webp";
import ProcessAutomationandDigitization from "../../assets/images/it-consulting/digital-operations/process-automation-and-digitization.jpg";
import SupplyChainManagement from "../../assets/images/it-consulting/digital-operations/supply-chain-management.jpg";
import AnalyticsandTracking from "../../assets/images/it-consulting/digital-operations/analytics-and-tracking.jpg";
import CustomerRelationshipManagement from "../../assets/images/it-consulting/digital-operations/customer-relationship-management.jpg";
import { Helmet } from 'react-helmet';
import Svg from "../svg";


function Digitaloperations() {
  const ServiceCard = ({ icon, title, details }) => {
    return (
      <div className="mb-3 md:mb-3 xl:mb-5 bg-white shadow-md hover:shadow-xl md:px-0 xl:px-0 border-2  transition duration-300 ease-in-out transform hover:scale-105">
        <div className="md:flex justify-start mb-4 items-start top-1/2">
          <img src={icon} alt="Service Icon" className="w-full" />
        </div>
        <div className="ml-2 mr-2">
          <h1 className="ml-2 mr-2 mb-2 text-2xl font-light text-black text-left">
            {title}
          </h1>
          <p className="ml-2 mr-2 mb-6  text-black">{details}</p>
        </div>
      </div>
    );
  };

  return (
    <main className="itconsulting_digital_operations">
      <Helmet>
        {/* Meta Title: Digital Operations Services | TechSmew Technologies */}
        <title>Digital Operations Services | TechSmew Technologies</title>
        
        {/* Meta Description: TechSmew Technology provides digital operations services. Optimize your digital processes for efficiency and productivity. */}
        <meta name="description" content="TechSmew Technology provides digital operations services. Optimize your digital processes for efficiency and productivity." />
        
        {/* Meta Keywords (for reference): Digital operations, operational efficiency, digital process optimization */}
        {/* Note: Meta keywords are not used by major search engines for SEO. */}
        <meta name="keywords" content="Digital operations, operational efficiency, digital process optimization" />
      </Helmet>
      <div className="relative overflow-hidden bg-[#492196]  pt-[120px] md:pt-[130px] lg:pt-[160px]">
        <div className="max-w-7xl mx-auto">
          <div className=" flex flex-wrap items-center">
            <div className="w-full px-4">
              <div className="hero-content mx-auto text-center">
                <h1 className="mb-8 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug">
                Enhancing Operational Efficiency through Digital Solutions
                </h1>
                <p className=" px-4 mx-auto mb-10 font-normal text-base text-[#e4e4e4] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed">
                  Automating repetitive, manual and laborious tasks with
                  cutting-edge technology to maximise productivity and deliver
                  premier solutions. Empower your employees with the power of
                  digital operations, so your team can focus on innovation,
                  creativity and while digitization takes care of your
                  monotonous tasks.
                </p>
              </div>
            </div>

     <Svg/>

            <div className="flex items-center justify-center absolute bottom-0 z-20  bg-gradient-to-r from-indigo-500 to-orange-500 px-6 pt-1 md:py-2  rounded-t-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8">
              <nav className="flex " aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                  <li>
                    <div className="flex items-center">
                      <NavLink
                        className="text-[9px] md:text-sm font-medium text-gray-100 hover:text-[#f89539] "
                        to="/"
                      >
                        Home
                      </NavLink>
                    </div>
                  </li>
                  <li aria-current="page" className="h-full">
                    <div className="flex items-center text-white ">
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-medium md:ml-2">
                        Solutions
                      </span>
                    </div>
                  </li>
                  <li aria-current="page">
                    <NavLink
                      to="/solutions/it-consulting"
                      className="flex items-center text-white"
                    >
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-medium md:ml-2">
                        IT Consulting
                      </span>
                    </NavLink>
                  </li>
                  <li aria-current="page">
                    <div className="flex items-center  text-white">
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-medium md:ml-2">
                        DO
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-white">
        <div className="max-w-7xl mx-auto my-6 md:pb-18">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8">
            <h1 className="text-[2rem] text-black text-left font-light mb-1">
              Elevate Your Business with Expert Digital Operations and IT
              Consulting
            </h1>
            <div className="flex flex-nowrap flex-cols-3 mb-3 ">
              <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
              <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
              <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
            </div>
            <p className="text-base font-normal text-black">
              Transform your business with our expert Digital Operations and IT
              Consulting services. We specialise in optimising your digital
              processes and providing strategic IT guidance to elevate your
              operations to new heights. Unlock efficiency and innovation with
              our tailored solutions today.
            </p>
          </div>
          <div className="py-6 md:py-8 mx-6 grid grid-cols-1 xl:grid-cols-4 md:grid-cols-2 gap-6 xl:mx-0 lg:mx-10 md:mx-8">
            <ServiceCard
              icon={ProcessAutomationandDigitization}
              title="Process Automation and Digitization"
              details="Streamline workflows, reduce errors and enhance productivity by digitising and automating key operational tasks."
            />
            <ServiceCard
              icon={SupplyChainManagement}
              title="Supply Chain Management"
              details="Optimise supply chain operations through real-time visibility, inventory management and forecasting for future demands."
            />
            <ServiceCard
              icon={AnalyticsandTracking}
              title="Analytics and Tracking"
              details="Implement intelligent chatbots and virtual assistants to provide instant customer support and improve user experiences."
            />
            <ServiceCard
              icon={CustomerRelationshipManagement}
              title="Customer Relationship Management (CRM)"
              details=" Leverage CRM platforms to manage customer interactions, track sales and enhance customer satisfaction."
            />
          </div>
        </div>
      </section>
      <section className="bg-gray-100 py-10">
        <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-1 gap-0 lg:gap-4 ">
          <div className="md:flex justify-start md:pr-4 xl:pl-0 items-center rounded-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8">
            <div className="col-span-1">
              <img src={WorkFlow} alt="work flow" className="w-full" />
              <div className="pt-4">
                <h1 className="text-[2rem] font-light mb-1">Our Workflow</h1>
              </div>
              <div className="flex flex-nowrap flex-cols-3">
                <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
                <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
                <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
              </div>
              <div className="py-3">
                <h1 className=" text-black">
                  Analysing company goals, past interactions and challenges to
                  pinpoint digital enhancement opportunities.
                </h1>
              </div>
            </div>
          </div>
          <div className="col-span-2 mx-12 xl:mx-0 lg:mx-10 md:mx-16 pt-8 xl:ml-10">
            <ol className="relative text-black border-l border-gray-300 dark:border-gray-700 ">
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <CheckIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Assess company objectives, pain points and historical data
                  touchpoints to identify areas of improvement and digital
                  optimization.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <ShieldCheckIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Set Key Performance Indicators and benchmarks in alignment
                  with business objectives to work towards.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <LockClosedIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Leverage latest technologies to design digital solutions that
                  could be integrated into existing systems for a seamless
                  workflow.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <ChartBarSquareIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Monitor operational performance to identify scope of
                  improvement and to create optimised digital workflows.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <SparklesIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Support your in-house team with basic operating knowledge and aid in the change management process.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="max-w-7xl mx-auto my-6 md:pb-18">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8">
            <h1 className="text-[2rem] text-black text-left mb-1 font-light">
              Our Unique Value Proposition
            </h1>
            <div className="flex flex-nowrap flex-cols-3 mb-3 ">
              <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
              <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
              <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
            </div>
            <p className="text-base font-normal text-black">
              Elevate your business with our expert digital operations and IT
              consulting services. Streamline processes, optimise resources and
              unlock growth potential through our tailored solutions.
            </p>
          </div>
          <div className="py-6 md:py-10 grid grid-cols-1 md:grid-cols-3 gap-6 mx-6 lg:mx-10 md:mx-8 xl:mx-0">
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#492196] rounded-lg w-12 h-12">
                  <CheckBadgeIcon className="h-8 w-8 text-white stroke-[1]" />
                </div>
              </div>
              <h3 className="text-2xl font-light text-black mb-2 text-center">
                Domain Expertise
              </h3>
              <p className="text-black font-normal text-base  text-center">
                Our experts possess the necessary skill sets required to drive
                business growth and success.
              </p>
            </div>
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#492196] rounded-lg w-12 h-12">
                  <ClipboardDocumentCheckIcon className="h-8 w-8 text-white stroke-[1]" />
                </div>
              </div>
              <h3 className="text-2xl font-light text-black mb-2 text-center">
                End-to-end Services
              </h3>
              <p className="text-black font-normal text-base  text-center">
                A comprehensive suite of services that encompasses everything
                from deployment to retirement.
              </p>
            </div>
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#492196] rounded-lg w-12 h-12">
                  <UserIcon className="h-8 w-8 text-white stroke-[1]" />
                </div>
              </div>
              <h3 className="text-2xl font-light text-black mb-2 text-center">
                Proven Track Record
              </h3>
              <p className="text-black font-normal text-base  text-center">
                We have worked with clients of various niches and variety of
                calibre thus accumulating years of experience.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-100">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 py-10 mx-6 lg:mx-10 md:mx-8 xl:mx-0">
            <div className="justify-center items-center pt-5 md:col-span-2">
              <div className="pt-5 pb-3 pb-md-5 md:px-0 text-center md:text-left">
                <p className="text-[1.5rem] font-normal text-black mb-3">
                  Ready to get started?
                </p>
                <h2 className="text-[2rem] font-normal text-black pb-3">
                Optimizing Digital Processes for Success
                {" "}
                Efficiency and effectiveness in the digital age are just a step away.
                </h2>
                <NavLink
                  to="/contact"
                  className="bg-[#0d6efd] hover:bg-[#0d6dfdb7] mt-6 mb-2 text-white py-3 text-base px-6 inline-block transition duration-300 ease-in-out rounded-full"
                >
                 Optimize Operations
                </NavLink>
              </div>
            </div>
            <div className="md:flex justify-end items-center md:col-span-1">
              <img src={CTA} alt="CTA" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Digitaloperations;
