import React, { useState } from "react";
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";


const schema = yup.object().shape({
    fname: yup.string().required("First name is required"),
    lname: yup.string().required("Last name is required"),
    email: yup
        .string()
        .email("Invalid email address")
        .required("Email address is required"),
    phone: yup
        .string()
        .matches(/^\d{10}$/, 'Phone number must be a valid 10-digit number')
        .required('Phone number is required'),
    residence: yup.string().required("Please add your residence"),
    education: yup.string().required("Please add your education"),
    experiance: yup.string().required("Please add your experiance"),
    currentctc: yup.string().required('Current CTC  is required'),
    expectedctc: yup.string().required('Expected CTC  is required'),
    
});

export default function Modal() {

    const [showModal, setShowModal] = useState(false);

    
    const handleCloseModal = () => {
        setShowModal(false);
    };



    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ mode: "all", resolver: yupResolver(schema) });


   const [isSubmitting,setIsSubmitting]  = useState(false);
    const [isFileError,setIsFileError]  = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        
        if (file && allowedTypes.includes(file.type)) {
            setIsFileError(false);
            setSelectedFile(file);
        } else {
            setIsFileError(true);
            setSelectedFile(null);
            toast.error("Please upload a valid PDF or document file");
        }
    };




    const onSubmit = async (data, e) => {
        if(selectedFile){
        e.preventDefault();
        setIsSubmitting(true)
        const resumeFormData = new FormData();
        resumeFormData.append("candidate_cv", selectedFile);
        resumeFormData.append("candidate_first_name", data.fname + " " + data.lname);
        resumeFormData.append("candidate_last_name", data.lname);
        resumeFormData.append("candidate_phone", data.phone);
        resumeFormData.append("candidate_email", data.email);
        resumeFormData.append("candidate_address1", data.residence);
        resumeFormData.append("candidate_total_experience", data.experiance);
        resumeFormData.append("candidate_relevant_experience", data.education);
        resumeFormData.append("candidate_ctc", data.currentctc);
        resumeFormData.append("candidate_expected_ctc", data.expectedctc);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_HOST}candidates`,
                {
                    method: "POST",
                    body: resumeFormData,
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_token}`,
                    },
                }
            );
            if (response.ok) {
                const json = await response.json();
                if (json.status === 200) {
                    reset();
                    toast.success("Thank You! We will get back to you soon.");
                    setShowModal(false);
                } else {
                    toast.error("Facing an error! Please try again!");
                }
            } else {
                toast.error("Network error occurred. Please try again later.");
            }
        } catch (error) {
            console.error("An error occurred:", error);
            toast.error("An error occurred. Please try again later.");
        }
        setIsSubmitting(false)
        return true
    }
    setIsFileError(true)
    toast.error("Please upload your resume");
    return false
    };

    return (
        <>
            <button
                className=" bg-[#492196] text-white rounded-full text-sm xl:text-base py-1 px-4 lg:py-2 lg:px-6
               hover:bg-[#E75828] hover:text-white active:bg-[#E75828] active:text-white "
                type="button"
                onClick={() => setShowModal(true)}
            >
                Apply Now
            </button>


            {showModal && (
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-gray-300 my-10">
                    {/* Rest of your modal code */}
                    <div className="relative p-6 flex-auto">
                        <section className={`  max-w-7xl mx-auto`}>
                            <div className='mx-auto  max-w-5xl'>
                                <div className=" mx-auto border-2 bg-white border-gray-200 p-6 md:p-8 md:my-12">
                                    <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
                                        <div className="grid  grid-cols-2 gap-2 md:gap-4">
                                        <h3 className="text-2xl font-medium col-span-2">Elevate Your Career</h3>

                                            <div className="relative z-0 w-full mb-6 group col-span-2 md:col-span-1">
                                                <input
                                                    type="text"
                                                    name="fname"
                                                    id="fname"
                                                    {...register("fname", { required: true })}
                                                    className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 ${errors.fname
                                                        ? "border-red-400 focus:border-red-600"
                                                        : "dark:border-gray-600 dark:focus:border-blue-500 focus:border-blue-600"
                                                        } appearance-none dark:text-white focus:outline-none focus:ring-0  peer`}
                                                    placeholder=" "
                                                />
                                                <label
                                                    htmlFor="fname"
                                                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 
                                                               -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                                               peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                >
                                                    First name
                                                </label>

                                                {errors.fname && (
                                                    <div className="text-sm text-red-500">
                                                        First name is required
                                                    </div>
                                                )}
                                            </div>
                                            <div className="relative z-0 w-full mb-6 group  col-span-2 md:col-span-1">
                                                <input
                                                    type="text"
                                                    name="lname"
                                                    id="lname"
                                                    className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 ${errors.lname
                                                        ? "border-red-400 focus:border-red-600"
                                                        : "dark:border-gray-600 dark:focus:border-blue-500 focus:border-blue-600"
                                                        } appearance-none dark:text-white focus:outline-none focus:ring-0  peer`}
                                                    placeholder=" "
                                                    {...register("lname", { required: true })}
                                                />
                                                <label
                                                    htmlFor="lname"
                                                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
                                                              -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                                              peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                >
                                                    Last name
                                                </label>
                                                {errors.lname && (
                                                    <div className="text-sm text-red-500">
                                                        Last name is required
                                                    </div>
                                                )}
                                            </div>

                                            <Controller
                                                name="email"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                    <div className="relative z-0 w-full mb-6 group  col-span-2 md:col-span-1">
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 ${errors.email
                                                                ? "border-red-400 focus:border-red-600"
                                                                : "dark:border-gray-600 dark:focus:border-blue-500 focus:border-blue-600"
                                                                } appearance-none dark:text-white focus:outline-none focus:ring-0  peer`}
                                                            placeholder=" "
                                                            {...register("email", { required: true })}
                                                        />
                                                        <label
                                                            htmlFor="email"
                                                            className="peer-focus:font-medium absolute text-sm
                                                                      text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 
                                                                       top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500
                                                                       peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                        >
                                                            Email address
                                                        </label>
                                                        {errors.email && (
                                                            <div className="text-sm text-red-500">
                                                                {errors.email.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            />

                                            <div className="relative mt-0  col-span-2 md:col-span-1">
                                                <div className="relative z-0 w-full mb-6 group">
                                                    <input
                                                        type="number"
                                                        name="phone"
                                                        id="phone"
                                                        pattern="[0-9]*"
                                                        className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 ${errors.phone
                                                            ? "border-red-400 focus:border-red-600"
                                                            : "dark:border-gray-600 dark:focus:border-blue-500 focus:border-blue-600"
                                                            } appearance-none dark:text-white focus:outline-none focus:ring-0  peer`}
                                                        placeholder=" "
                                                        {...register("phone", { required: true })}
                                                    />
                                                    <label
                                                        htmlFor="phone"
                                                        className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300
                                                                   transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500
                                                                   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                    >
                                                        Phone
                                                    </label>
                                                    {errors.phone && (
                                                        <div className="text-sm text-red-500">
                                                            {errors.phone.message}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="relative z-0 w-full my-3 group col-span-2">
                                                <textarea
                                                    type="text"
                                                    name="residence"
                                                    id="residence"
                                                    className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 ${errors.residence
                                                        ? "border-red-400 focus:border-red-600"
                                                        : "dark:border-gray-600 dark:focus:border-blue-500 focus:border-blue-600"
                                                        } appearance-none dark:text-white focus:outline-none focus:ring-0  peer`}
                                                    placeholder=" "
                                                    {...register("residence", { required: true })}
                                                ></textarea>
                                                <label
                                                    htmlFor="residence"
                                                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300
                                                              transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500
                                                              peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                >
                                                    Residence
                                                </label>
                                                {errors.residence && (
                                                    <div className="text-sm text-red-500">
                                                        {errors.residence.message}
                                                    </div>
                                                )}
                                            </div>


                                            <div className="relative z-0 w-full my-3 group col-span-2">
                                                <textarea
                                                    type="text"
                                                    name="education"
                                                    id="education"
                                                    className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 ${errors.education
                                                        ? "border-red-400 focus:border-red-600"
                                                        : "dark:border-gray-600 dark:focus:border-blue-500 focus:border-blue-600"
                                                        } appearance-none dark:text-white focus:outline-none focus:ring-0  peer`}
                                                    placeholder=" "
                                                    {...register("education", { required: true })}
                                                ></textarea>
                                                <label
                                                    htmlFor="education"
                                                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300
                                                              transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500
                                                              peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                >
                                                    Education
                                                </label>
                                                {errors.education && (
                                                    <div className="text-sm text-red-500">
                                                        {errors.education.message}
                                                    </div>
                                                )}
                                            </div>


                                            <div className="relative z-0 w-full my-3 group col-span-2">
                                                <textarea
                                                    type="text"
                                                    name="experiance"
                                                    id="experiance"
                                                    className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 ${errors.experiance
                                                        ? "border-red-400 focus:border-red-600"
                                                        : "dark:border-gray-600 dark:focus:border-blue-500 focus:border-blue-600"
                                                        } appearance-none dark:text-white focus:outline-none focus:ring-0  peer`}
                                                    placeholder=" "
                                                    {...register("experiance", { required: true })}
                                                ></textarea>
                                                <label
                                                    htmlFor="experiance"
                                                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300
                                                              transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500
                                                              peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                >
                                                    Experiance
                                                </label>
                                                {errors.experiance && (
                                                    <div className="text-sm text-red-500">
                                                        {errors.experiance.message}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="relative mt-0 md:col-span-1 col-span-2">
                                                <div className="relative z-0 w-full mb-6 group">
                                                    <input
                                                        type="number"
                                                        name="currentctc"
                                                        id="currentctc"
                                                        className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 ${errors.currentctc
                                                            ? "border-red-400 focus:border-red-600"
                                                            : "dark:border-gray-600 dark:focus:border-blue-500 focus:border-blue-600"
                                                            } appearance-none dark:text-white focus:outline-none focus:ring-0  peer`}
                                                        placeholder=" "
                                                        {...register("currentctc", { required: true })}
                                                    />
                                                    <label
                                                        htmlFor="currentctc"
                                                        className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300
                                                                   transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500
                                                                   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                    >
                                                        Current CTC
                                                    </label>
                                                    {errors.currentctc && (
                                                        <div className="text-sm text-red-500">
                                                            {errors.currentctc.message}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="relative mt-0 md:col-span-1 col-span-2">
                                                <div className="relative z-0 w-full mb-6 group">
                                                    <input
                                                        type="number"
                                                        name="expectedctc"
                                                        id="expectedctc"
                                                        className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 ${errors.expectedctc
                                                            ? "border-red-400 focus:border-red-600"
                                                            : "dark:border-gray-600 dark:focus:border-blue-500 focus:border-blue-600"
                                                            } appearance-none dark:text-white focus:outline-none focus:ring-0  peer`}
                                                        placeholder=" "
                                                        {...register("expectedctc", { required: true })}
                                                    />
                                                    <label
                                                        htmlFor="expectedctc"
                                                        className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300
                                                                   transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500
                                                                   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                    >
                                                        Expected CTC
                                                    </label>
                                                    {errors.expectedctc && (
                                                        <div className="text-sm text-red-500">
                                                            {errors.expectedctc.message}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className='mt-2'>
                                                <label
                                                    htmlFor="file-upload"
                                                    className={`bg-white border border-gray-400 hover:border-2 hover:border-[#492196] text-[#492196] py-1 px-2 mt-4 md:py-2 md:px-4 rounded cursor-pointer`} >
                                                  {!selectedFile ? 'Choose File':'Upload'}
                                                </label>
                                                <input
                                                    name="file"
                                                    placeholder="file"
                                                    id="file-upload"
                                                    type="file"
                                                    className="hidden"
                                                    onChange={handleFileChange}
                                                    
                                                />
                                                {isFileError && (
                                                    <div className="text-sm text-red-500 mt-2 ">
                                                        Please upload your resume
                                                    </div>
                                                )}
                                            </div>

                                            <div className='col-span-2 md:col-span-1 mt-4 md:mt-0'>
                                                <div className='md:ml-auto w-fit'>

                                                    <button type="button"
                                                        onClick={handleCloseModal}
                                                        className="bg-[#f97316] mr-2 md:mr-6 text-sm md:text-base w-16 md:w-24 text-white px-2 py-1 md:px-4 md:py-2  rounded-full">
                                                        cancel
                                                    </button>
                                                    <button
                                                   disabled={isSubmitting}
                                                        type="submit"
                                                        className="bg-[#492196] text-sm md:text-base w-32 md:w-32 text-white px-2 py-1 md:px-4 md:py-2  rounded-full">
                                                        {(isSubmitting)? "Submitting..." : "Submit"}
                                                    </button>


                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )}
            {showModal && (
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            )}
        </>
    );
}


