import React from "react";
import { NavLink } from "react-router-dom";
// import MainBannerImage from "../../public/assets/images/home/main-banner.webp";
// import MainBannerImage from "/assets/images/home/main-banner.webp";
import Strategy from "../assets/images/home/strategy.webp";
import Process from "../assets/images/home/process.webp";
import Technology from "../assets/images/home/technology.webp";
import People from "../assets/images/home/people.webp";
import Culture from "../assets/images/home/culture.webp";
import Growth from "../assets/images/home/growth.webp";
import Diversity from "../assets/images/home/diversity.webp";
import Community from "../assets/images/home/community.webp";
import Banner from '../assets/images/home/home-banner.webp';
import ContinuousMonitoring from "../assets/images/it-infrastructure/backup-and-recovery-services/continuous-monitoring.jpg";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  ClockIcon,
  CheckBadgeIcon,
  RocketLaunchIcon,
  ShieldCheckIcon
} from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet";


function Home() {
  function styledConsoleText(text, fontSize) {
    const blueStyle = 'color: blue;'; // Blue text color
    const fontSizeStyle = `font-size: ${fontSize}px;`; // Font size in pixels
    const border = 'border:double';


    // Combine the styling with the text
    const styledText = `%c${text}`;

    // Log the styled text to the console
    console.log(styledText, `${blueStyle}${fontSizeStyle} ${border}`);
  }
  // Call the custom function with your desired text and font size
  styledConsoleText(" Apply Job at hr@techsmew.com ", 49); // Adjust the font size as needed


  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const containerStyle = {
    overflow: 'hidden',
  };

  const servicesData = [
    {
      link: "/solutions/design/user-research",
      iconColor: "#0d6efd",
      icon: "consulting.webp",
      title: "Consulting & Design",
      description:
        "Our accelerated solutions observe and connect business with customers using next-generation technologies and elegant user interfaces.",
    },
    {
      link: "/solutions/design/user-research",
      iconColor: "#0d6efd",
      icon: "technologies.webp",
      title: "Modern Technologies",
      description:
        "We transform existing raw data into cutting-edge business solutions by applying modern AI/ML algorithms and new technologies.",
    },
    {
      link: "/solutions/design/user-research",
      iconColor: "#0d6efd",
      icon: "cloud.webp",
      title: "Cloud Transformation",
      description:
        "Our dedicated cloud experts build scalable and secure cloud infrastructure and data science applications in the cloud. We ensure reliability at every point.",
    },
    {
      link: "/solutions/design/user-research",
      iconColor: "#0d6efd",
      icon: "managed-services.webp",
      title: " Managed Services",
      description:
        "We design infrastructure that enables the dev & support team to collaborate to maximise the business value from existing and new technologies.",
    },
    {
      link: "/solutions/design/user-research",
      iconColor: "#0d6efd",
      icon: "marketing.webp",
      title: "  Marketing  Conversion strategy",
      description:
        "Detailed digital marketing strategy and action plan to help your business to flourish. Designing high-converting marketing funnels and filters that maximize your ROI.",
    },
    {
      link: "/solutions/design/user-research",
      iconColor: "#0d6efd",
      icon: "social.webp",
      title: "Social media",
      description:
        "Social Media channels management that boosts your customer engagement. Facebook ads, Instagram ads, Google ads, PPC, Paid Social & Search campaigns.",
    },
    {
      link: "/solutions/design/user-research",
      iconColor: "#0d6efd",
      icon: "apps.webp",
      title: "UI, UX Web Apps",
      description:
        "We design the interface to interact with your customer with your business more efficiently. We serve all industries by the sector of E-commerce, content full, CMS etc., ",
    },
    {
      link: "/solutions/design/user-research",
      iconColor: "#0d6efd",
      icon: "research.webp",
      title: "Research and Development",
      description:
        "Our data science team process and deliver business intelligence solutions by empowering customers’ insights and data.  ",
    },
  ];

  const ServiceCard = ({ icon, title, details }) => {
    return (
      <div className="mb-3 md:mb-3 xl:mb-5 bg-white shadow-md hover:shadow-xl md:px-0 xl:px-0 border-2  transition duration-300 ease-in-out transform hover:scale-105">
        <div className="md:flex justify-start mb-4 items-start top-1/2">
          <img src={icon} alt="Service Icon" className="w-full" />
        </div>
        <div className="ml-2 mr-2">
          <h1 className="ml-2 mr-2 mb-2 text-2xl font-light text-black text-left">
            {title}
          </h1>
          <p className="ml-2 mr-2 mb-6  text-black">{details}</p>
        </div>
      </div>
    );
  };

  return (
    <main className="home_main">
      <Helmet>
        {/* Meta Title: Innovative Solutions Provider | TechSmew Technologies */}
        <title>TechSmew Technologies</title>

        {/* Meta Description: TechSmew Technologies delivers innovative solutions in design, IT consulting, cloud services and IT infrastructure. Partner with us for transformative technology solutions. */}
        <meta
          name="description"
          content="TechSmew Technologies delivers innovative solutions in design, IT consulting, cloud services and IT infrastructure. Partner with us for transformative technology solutions."
        />

        {/* Meta Keywords: Innovative solutions, design, IT consulting, cloud services, IT infrastructure, technology solutions */}
        <meta
          name="keywords"
          content="Innovative solutions, design, IT consulting, cloud services, IT infrastructure, technology solutions"
        />
      </Helmet>
      <section className="relative overflow-hidden bg-[#492196]">
        <div className="mx-auto mt-20 md:mt-0 mb-4 md:mb-0">
          <div className="grid grid-cols-1 items-center  md:grid-cols-2 gap-8 ">
            <div style={containerStyle} className="xl:ml-[6rem] mt-30 md:mt-40 lg:mt-60 xl:mt-40 md:my-28 mx-6 xl:mx-0 lg:mx-10  md:mx-8 xl:mr-16 my-auto">
              <Slider {...settings} >
                <div >
                  <h1 className="text-[28px] xl:text-[55px] font-bold text-left text-white mx-auto mb-6 xl:leading-[60px]">
                  Modernize and Stay Ahead of Trends
                  </h1>
                  <p className="text-lg text-left text-[#e3e3e3] mx-auto">
                  Our specialization in migrating outdated technology to cutting-edge solutions ensures your applications stay relevant in today's fast-paced digital landscape. We seamlessly bridge the gap between legacy systems and innovation, allowing your business to evolve with technology trends. Don't be left behind; let us future-proof your applications.
                  </p>
                </div>
                <div >
                  <h1 className="text-[28px] xl:text-[55px] font-bold text-left text-white mx-auto mb-6 xl:leading-[60px]">
                  Unlock Scalability with Microservices Mastery
                  </h1>
                  <p className="text-lg text-left text-[#e3e3e3] mx-auto">
                  Our deep expertise in microservices architecture empowers you to revolutionize your applications. Seamlessly transition from monolithic systems to agile, scalable microservices, unleashing the full potential of your digital capabilities. Embrace the future with confidence, as we guide you through this transformative journey.
                  </p>
                </div>
                <div >
                  <h1 className="text-[28px] xl:text-[55px] font-bold text-left text-white mx-auto mb-6 xl:leading-[60px]">
                  Empower Your Business Through Digital Transformation
                  </h1>
                  <p className="text-lg text-left text-[#e3e3e3] mx-auto">
                  Leverage our expertise to drive innovation and growth in the ever-evolving digital landscape. Discover new opportunities and stay ahead. With our solutions, you'll redefine your industry presence and lead the way in the digital future.
                  </p>
                </div>
                <div >
                  <h1 className="text-[28px] xl:text-[55px] font-bold text-left text-white mx-auto mb-6 xl:leading-[60px]">
                  Craft Engaging User Experiences for Success
                  </h1>
                  <p className="text-lg text-left text-[#e3e3e3] mx-auto">
                  From UI/UX to graphic design, our solutions ensure your brand thrives by placing customers at the forefront. Elevate your brand's image and create lasting connections. Our designs aren't just beautiful; they're strategic.
                  </p>
                </div>
                <div >
                  <h1 className="text-[28px] xl:text-[55px] font-bold text-left text-white mx-auto mb-6 xl:leading-[60px]">
                  Informed Decision-Making with Data Insights
                  </h1>
                  <p className="text-lg text-left text-[#e3e3e3] mx-auto">
                  Harness the power of data analytics for valuable insights and growth. Make informed choices that drive your business forward. With our data-driven approach, you'll outpace the competition and make decisions that truly matter.
                  </p>
                </div>
                <div >
                  <h1 className="text-[28px] xl:text-[55px] font-bold text-left text-white mx-auto mb-6 xl:leading-[60px]">
                  Securing Digital Assets for Peace of Mind
                  </h1>
                  <p className="text-lg text-left text-[#e3e3e3] mx-auto">
                  We provide top-notch IT security solutions, from cloud protection to disaster recovery. Ensure the safety and continuity of your digital operations. Rest easy knowing that your digital assets are fortified against threats, keeping your business resilient.
                  </p>
                </div>
                <div >
                  <h1 className="text-[28px] xl:text-[55px] font-bold text-left text-white mx-auto mb-6 xl:leading-[60px]">
                  Enhance Online Presence with Strategic Marketing
                  </h1>
                  <p className="text-lg text-left text-[#e3e3e3] mx-auto">
                  Effectively connect with your target audience through SEO, influencers and more. Boost your brand's visibility and engagement. With our strategic marketing, you'll not only be seen but also remembered in the digital landscape.
                  </p>
                </div>
                <div >
                  <h1 className="text-[28px] xl:text-[55px] font-bold text-left text-white mx-auto mb-6 xl:leading-[60px]">
                  Boost Productivity with AI-Powered Automation
                  </h1>
                  <p className="text-lg text-left text-[#e3e3e3] mx-auto">
                  Optimize your operations, increase productivity and maintain competitiveness in today's fast-paced digital landscape. Our AI-powered solutions don't just automate tasks; they supercharge your efficiency and drive results.
                  </p>
                </div>
                <div >
                  <h1 className="text-[28px] xl:text-[55px] font-bold text-left text-white mx-auto mb-6 xl:leading-[60px]">
                  Your Trusted Partner for Comprehensive IT Solutions
                  </h1>
                  <p className="text-lg text-left text-[#e3e3e3] mx-auto">
                  Delegate IT needs from procurement to network design to us, allowing you to concentrate on your core business. We've got IT covered. Partner with us to access a full suite of IT solutions, tailored to your specific needs.
                  </p>
                </div>
                <div >
                  <h1 className="text-[28px] xl:text-[55px] font-bold text-left text-white mx-auto mb-6 xl:leading-[60px]">
                  Confidently Embrace Scalable Cloud Solutions
                  </h1>
                  <p className="text-lg text-left text-[#e3e3e3] mx-auto">
                  Discover scalable and efficient cloud solutions with our expertise in AWS, Google Cloud and Azure. Take your business to new heights by leveraging the power of the cloud. With us, your digital journey is secure, efficient and scalable.
                  </p>
                </div>
             
              </Slider>
            </div>
            {/* <div className="xl:ml-[19rem] mt-30 md:my-28 mx-6 xl:mx-0 lg:mx-10 md:mx-8">
              <h1 className="text-[28px] xl:text-[55px] font-bold text-left text-white mx-auto mb-6 xl:leading-[60px]">
                Transform your business to digital
              </h1>
              <p className="text-lg text-left text-[#e3e3e3] mx-auto">
                Manage your IT infrastructure with our dynamic, scalable,
                trusted and responsive solution. Experience remarkable business
                retention. A customer journey you’ll love.
              </p>
            </div> */}

            <div className="justify-end w-full h-full hidden md:flex">
              <img src={Banner} alt="home main" className="w-full !justify-end h-full rounded-xl object-cover" />
              {/* <img src={MainBannerImage} alt="home main" className="w-full" /> */}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white text-black">
        <div className="max-w-7xl mx-auto my-10 md:pb-18 ">
          <div className="text-center mb-3 py-4 mx-6 xl:mx-0 lg:mx-10 md:mx-8">
            <h2 className="text-[2rem] font-light">
              Let's bring easier and faster solutions with our perspectives
            </h2>
            <p className="py-2">
              We focus on delivering value to clients through integrated,
              reliable, responsive and cost-effective modern solutions. We hold
              an intellectual experience and expertise in providing solutions
              for complex IT solution architecting and implementation to
              projects.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mx-6 xl:mx-0 lg:mx-10 md:mx-8">
            <div className="border rounded-0 overflow-hidden shadow-sm h-[200px] relative flex flex-row lg:mb-4">
              <div className="p-4 flex flex-col position-static min-h-[184px] border border-b-[#ff7c4e] border-b-[4px] w-full">
                <h4 className="mb-2 text-2xl font-light">Strategy</h4>
                <p className="card-text mb-auto">
                  Identifying the opportunities and resources available, We
                  create solutions by our experts.
                </p>
              </div>
              <div className="md:flex-shrink-0 hidden lg:block">
                <img
                  src={Strategy}
                  alt="strategy"
                  className="!w-[230px] xl:!h-[200px] lg:!h-[221px] object-cover "
                />
              </div>
            </div>
            <div className="border rounded-0 overflow-hidden shadow-sm h-[200px] relative flex flex-row lg:mb-4">
              <div className="p-4 flex flex-col position-static min-h-[184px] border border-b-[#fcce3f] border-b-[4px] w-full">
                <h4 className="mb-2 text-2xl font-light ">Process</h4>
                <p className="card-text mb-auto">
                  We will define the best way to reach goals, prioritise
                  activities and focus their efforts to achieve the goal.
                </p>
              </div>
              <div className="md:flex-shrink-0 hidden lg:block ">
                <img
                  src={Process}
                  alt="process"
                  className="!w-[230px] xl:!h-[200px] lg:!h-[221px] object-cover "
                />
              </div>
            </div>
            <div className="border rounded-0 overflow-hidden shadow-sm h-[200px] relative flex flex-row lg:mb-4">
              <div className="p-4 flex flex-col position-static min-h-[184px] border border-b-[#492196] border-b-[4px] w-full">
                <h4 className="mb-2 text-2xl font-light">Technology</h4>
                <p className="card-text mb-auto">
                  We integrate a range of next-generation technology
                  development, automation tools and delivery practices.
                </p>
              </div>
              <div className="md:flex-shrink-0 hidden lg:block ">
                <img
                  src={Technology}
                  alt="technology"
                  className="!w-[230px] xl:!h-[200px] lg:!h-[221px] object-cover "
                />
              </div>
            </div>
            <div className="border rounded-0 overflow-hidden shadow-sm h-[200px] relative flex flex-row lg:mb-4">
              <div className="p-4 flex flex-col position-static min-h-[184px] border border-b-[#ab8e8e] border-b-[4px] w-full">
                <h4 className="mb-2 text-2xl font-light">People</h4>
                <p className="card-text mb-auto">
                  We proactively identify and pursue opportunities to develop
                  new initiatives, knowledge and skills.
                </p>
              </div>
              <div className="md:flex-shrink-0 hidden lg:block">
                <img
                  src={People}
                  alt="people"
                  className="!w-[230px] xl:!h-[200px] lg:!h-[221px] object-cover "
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-[#f2f4fa] text-black">
        <div className="max-w-7xl mx-auto my-10 md:pb-18 flex flex-col items-center">
          <div className="text-center mb-3 py-6 mx-6 xl:mx-0 lg:mx-10 md:mx-8">
            <h2 className="text-[2rem] font-light">
              Experience the wonder of UX/UI{" "}
            </h2>
            <p className="text-lg py-2">
              We perceive the design's resilience. Integrating your business
              with our revolutionary solutions is Fast and Easy. But times are
              changing faster, so transforming the customer experience is the
              right choice to aid the growth of core operating capabilities.
            </p>
          </div>
          <div className="over w-[274px] md:w-[92%] md:h-[300px] h-[285px] border-[4px] border-b-0 border-[#492196] rounded-tl-[30px] rounded-tr-[30px] mx-6 xl:mx-0 lg:mx-10 md:mx-8">
            <div className="flex h-[295px] overflow-hidden  bg-blue-50 rounded-tl-[30px] rounded-tr-[36px]">
              {/* Sidebar */}
              <div className="hidden md:block bg-white  w-1/4  text-dark rounded-tl-[30px]  ">
                <div className="text-md font-normal mt-4 px-6 py-1 bg-[#492196] text-white w-fit rounded-full ml-8">Hello Alexa!</div>
                <ul className="mt-6 ml-2 text-sm">
                  <li className="px-4 mb-1 text-gray-800  p-2  hover:bg-blue-50 hover:rounded-l-full ">
                    Dashboard
                  </li>
                  <li className="px-4 mb-1 text-black  p-2  bg-blue-50 rounded-l-full">
                    Sales
                  </li>
                  <li className="px-4 mb-1 text-black  p-2  hover:bg-blue-50 hover:rounded-l-full">
                    Products
                  </li>
                  <li className="px-4 mb-1 text-black  p-2  hover:bg-blue-50 hover:rounded-l-full">
                    Customers
                  </li>
                </ul>
              </div>

              {/* Main Content */}
              <div className="flex-1 rounded-tr-[30px]">
                {/* Top Bar */}
                <div className="bg-white shadow-sm p-4 rounded-tr-[26px] border-l border-teal-50">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center space-x-4">
                      <button className="text-gray-800">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                      </button>
                      <input
                        type="text"
                        placeholder="Search"
                        className="bg-gray-100 px-3 py-2 rounded-md focus:outline-none focus:ring focus:ring-blue-300 w-64"
                      />
                    </div>
                    <div>
                      <img src={ContinuousMonitoring} alt="Profile" className="w-10 h-10 rounded-full" />
                    </div>
                  </div>
                </div>

                {/* Main Content Area */}
                <div className="p-4  ">
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
                    {/* Sales Cards */}
                    <div className="bg-white p-4 rounded-lg shadow-sm">
                      <div className="text-xs text-gray-500 font-semibold  mb-2">Active Customers</div>
                      <div className="text-gray-800 text-2xl mb-2 font-bold">90</div>
                      <div className="flex justify-between">
                        <div className="flex items-center mt-2">
                          <span className="bg-green-500 text-white rounded-full px-2 py-1 text-[9px]">
                            +200
                          </span>
                          <span className="ml-2 text-xs">Avg. Customers</span>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow-sm">
                      <div className="text-xs text-gray-500 font-semibold  mb-2">New Customers</div>
                      <div className="text-gray-800 text-2xl mb-2 font-bold">200</div>
                      <div className="flex justify-between">
                        <div className="flex items-center mt-2">
                          <span className="bg-green-500 text-white rounded-full px-2 py-1 text-[9px]">
                            +30
                          </span>
                          <span className="ml-2 text-xs">Avg. Customers</span>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow-sm">
                      <div className="text-xs  text-gray-500 font-semibold  mb-2">Inquiries</div>
                      <div className="text-gray-800 text-2xl mb-2 font-bold">250</div>
                      <div className="flex justify-between">
                        <div className="flex items-center mt-2">
                          <span className="bg-red-300 text-white rounded-full px-2 py-1 text-[9px]">
                            +50
                          </span>
                          <span className="ml-2 text-xs">Avg. Inquiries</span>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow-sm">
                      <div className="text-xs text-gray-500 font-semibold  mb-2">Orders</div>
                      <div className="text-gray-800 text-2xl mb-2 font-bold">1200</div>
                      <div className="flex justify-between">
                        <div className="flex items-center mt-2">
                          <span className="bg-orange-500 text-white rounded-full px-2 py-1 text-[9px]">
                            +20
                          </span>
                          <span className="ml-2 text-xs">Avg. Orders</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white text-sm p-4 rounded-lg shadow-md mt-4">
                    <div className="text-sm font-semibold mb-4">Sales Data</div>
                    <table className="min-w-full bg-blue-200 rounded-lg text-white">
                      <thead>
                        <tr>
                          <th className="text-left px-4 py-2">Product</th>
                          <th className="text-left px-4 py-2">Quantity Sold</th>
                          <th className="text-left px-4 py-2">Revenue</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Product A</td>
                          <td>25</td>
                          <td>$1000</td>
                        </tr>
                        <tr>
                          <td>Product B</td>
                          <td>30</td>
                          <td>$1200</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white text-black">
        <div className="max-w-7xl mx-auto my-10 md:pb-18 ">
          <div className="grid grid-cols-1 lg:grid-cols-2 py-5 gap-6">
            <div className="text-left mb-3 py-4 mx-6 xl:mx-0 lg:mx-10 md:mx-8">
              <h1 className="text-[2rem] font-light  mb-3">
                Meet The Experts in Rapid IT Evolution
              </h1>
              <p className="text-justify py-2">
                One of the best IT consulting and application development
                companies in India, we focus on delivering value to our clients
                through integrated, reliable, responsive and cost-effective
                solutions. Our team holds intellectual experience and expertise
                in providing top-notch IT solutions even in the most complex
                situations. With our dynamic, scalable, trusted and responsive
                solutions, we ensure growth beyond odds with remarkable business
                retention.
              </p>
              <ul className="pl-0 pt-2">
                <li className="border-l-4 border-gray-600 pl-4 py-2 mb-3 text-base">
                  Supporting clients to expand their business.
                </li>
                <li className="border-l-4 border-[#e2f72d] pl-4 py-2 mb-3 text-base">
                  Building a long-term relationship with our client base.
                </li>
                <li className="border-l-4 border-[#ca6262] pl-4 py-2 mb-3 text-base">
                  Keeping clients updated with the trends.
                </li>
              </ul>
            </div>
            <div className="w-full justify-end">
              <img
                src="assets/images/section-new.webp"
                className="mx-auto "
                alt="Bootstrap Themes"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-[#492196] mt-4 px-4">
        <div className="max-w-7xl mx-auto my-10 md:pb-18 pb-10">
          <div className="py-10 mx-3 lg:mx-6 md:mx-4 xl:mx-0 gap-6">
            <div className="justify-center items-center pt-5">
              <h2 className="text-[2rem] font-light text-white text-start">
                Accelerate Your Enterprise Journey With Ease
              </h2>
              <p className="text-justify py-2 text-white">
                As a leading IT services and solutions company in Chennai, we
                are focused on helping businesses grow through digital
                transformation services. We bring fast and reliable solutions to
                each client’s business requirements through deep research and
                analysis of their market, solution and customers.
              </p>
            </div>

          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 pt-10 mx-3 lg:mx-6 md:mx-4 xl:mx-0">
            {servicesData.map((service, index) => (
              <div key={index} className="py-4 my-2 mt-5 my-sm-3 ">
                <a
                  href={service.link}
                  className=" card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2 transition duration-300 ease-in-out"
                >
                  <div className=" pt-3 bg-slate-50 rounded-lg relative">
                    <div
                      className={`inline-block bg-[${service.iconColor}] rounded-3 absolute z-10 top-[-55px] left-8 p-5 rounded-lg items-center justify-center`}
                    >
                      <img
                        src={`assets/icons/${service.icon}`}
                        className=" w-[40px]"
                        alt="Icon"
                      />
                    </div>
                    <div className="p-5 mt-6 lg:h-[150px] md:h-[230px]">
                      <h2 className="h4 flex flex-row text-dark text-2xl font-light pb-2 items-center">
                        {service.title} &nbsp;
                        <div className="flex items-center">
                          <img
                            src="assets/icons/arrow.svg"
                            className="d-block m-1 pt-[7px]"
                            width="20"
                            alt="Icon"
                          />
                        </div>
                      </h2>
                      <p className="fs-sm text-body mb-0">
                        {service.description}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="bg-white text-black">
        <div className="max-w-7xl mx-auto my-10 md:pb-18 ">
          <div className="grid grid-cols-1 lg:grid-cols-2 py-5 gap-6">
            <div className="text-left mb-3 py-8 mx-6 xl:mx-0 lg:mx-10 md:mx-8">
              <h1 className="text-[2rem] font-light leading-snug mb-3">
                Neptune - Future of Customer Support is Conversational
                Automation
              </h1>
              <p className="text-justify py-2">
                Neptune, a conversational AI platform that uses advanced AI,
                NLP and ML to automatically resolve customer issues with
                natural language. Human-like bot conversations aids enterprises
                for deeper insights on user behavior & experience in order to
                better serve their customers and clients.
                <br />
                <br />
                From build to deploy to improve - all you need is our
                easy-to-use Contextual AI Assistant that improves your
                customer’s interaction and relationship.
              </p>
            </div>
            <div className="w-full justify-end items-center">
              <img
                src="assets/images/who-we-are.webp"
                className="mx-auto w-700 h-500"
                alt="Icon"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-5 mx-auto max-w-7xl xl:px-0 pb-10 pt-10">
        <div className="bg-[#f3f6ff] rounded-xl overflow-hidden p-10 mx-6 xl:mx-0 lg:mx-10 md:mx-8">
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 xl:w-4/12 md:offset-5 md:pl-18 m-auto">
              <div className="pt-5 pb-3 pb-md-5 md:px-0 text-center md:text-left">
                <p className="text-[1.5rem] font-normal text-black mb-3">
                  Ready to get started?
                </p>
                <h2 className="md:text-[2rem] text-3xl font-normal text-black pb-3">
                  Are you looking for world-class IT support that helps your{" "}
                  <span className="text-[#0d6efd]">business</span> grow?{" "}
                </h2>
                <NavLink
                  to="/contact"
                  className="bg-[#0d6efd] hover:bg-[#0d6dfdb7] mt-6 mb-2 text-white py-3 text-base px-6 inline-block transition duration-300 ease-in-out rounded-full"
                >
                  Work with us
                </NavLink>
              </div>
            </div>
            <div className="w-full md:w-1/2 xl:w-7/12">
              <div className="relative flex items-center justify-center md:h-full">
                <svg
                  className="hidden md:block absolute top-1/2 left-20 transform -translate-y-1/2"
                  width="868"
                  height="868"
                  viewBox="0 0 868 868"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    opacity="0.15"
                    cx="434"
                    cy="434"
                    r="434"
                    fill="#6366F1"
                  ></circle>
                </svg>
                <img
                  src="/assets/images/main-mail.webp"
                  className="relative z-10 mb-2 my-lg-4"
                  width="382"
                  alt="Illustration"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-[#f2f4fa] ">
        <div className="max-w-7xl mx-auto my-10 md:pb-18 ">
          <div className="text-center mb-3 py-6 mx-3 lg:mx-6 md:mx-4">
            <h2 className=" text-[2rem] font-light ">
              Unlocking The Value In IT Solutions
            </h2>
            <p className="text-center text-lg my-2 py-2">
              Standard practices are our normal practice, we go beyond the
              standard.
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <div className="text-center border-r flex flex-col items-center">
              <div className="inline bg-light rounded-full shadow-lg p-2 mb-4 w-fit">
             
                <ClockIcon className="w-8 text-black" />
              </div>
              <h3 className="mb-2 text-center text-2xl font-light px-3 m-auto ">
                Build a functional prototype in 24 hrs
              </h3>
            </div>
            <div className="text-center border-r flex flex-col items-center">
             <div className="inline bg-light rounded-full shadow-lg p-2 mb-4 w-fit">
                {/* <img src="assets/icons/mvp.webp" width="32" alt="Icon" /> */}
                <CheckBadgeIcon className="w-8 text-black" />
              </div>
              <h3 className="mb-2 text-center text-2xl font-light px-3 m-auto">
                Create an MVP within two weeks
              </h3>
            </div>
            <div className="text-center border-r flex flex-col items-center">
             <div className="inline bg-light rounded-full shadow-lg p-2 mb-4 w-fit">
                {/* <img src="assets/icons/start.webp" width="32" alt="Icon" /> */}
                <RocketLaunchIcon className="w-8 text-black" />
              </div>
              <h3 className="mb-2 text-center text-2xl font-light px-3 m-auto">
                Kickstart software development
              </h3>
            </div>
            <div className="text-center flex flex-col items-center">
             <div className="inline bg-light rounded-full shadow-lg p-2 mb-4 w-fit">
                {/* <img src="assets/icons/chat.webp" width="32" alt="Icon" /> */}
                <ShieldCheckIcon className="w-8 text-black" />
              </div>
              <h3 className="mb-2 text-center text-2xl font-light px-3 m-auto">
                Get 24/7 tech &amp; business support
              </h3>
            </div>
          </div>
        </div>
        <div className="max-w-7xl mx-auto my-10 md:pb-16">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mx-6 xl:mx-0 lg:mx-10 md:mx-8">
            <div className="w-full">
              <img
                src="assets/images/why-us.webp"
                alt="why"
                className="mx-auto d-block object-cover w-700 h-500"
              />
            </div>
            <div className="w-full  pt-0">
              <p className="text-justify text-lg mb-5">
                We enable and delegate our people to equip with the latest
                trends and technologies to adopt every system. We bring
                workspace as a fun, creative and learning platform under
                complex technologies.
              </p>
              <ul className="w-[90%]">
                <li className="list-none border-l-[5px] border-[#ca6262] pl-6 py-2 mb-4 text-lg">
                  We do the research for every business to get valuable insights
                  and create the outline for the solution.
                </li>
                <li className="list-none border-l-[5px] border-[#979e5c] pl-6 py-2 mb-4 text-lg">
                  The team of developers and architects will bring the drafted
                  outline as a real-world application.
                </li>
                <li className="list-none border-l-[5px] border-[#888686] pl-6 py-2 mb-4 text-lg">
                  The quality of every application is ensured by our team of
                  testers, who are all well experienced and equipped with
                  required technologies.
                </li>
                <li className="list-none border-l-[5px] border-[#f89539] pl-6 py-2 mb-4 text-lg">
                  Our team of experts bring your applications to the cloud to
                  avail anytime, anywhere.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="max-w-7xl mx-auto my-6 md:pb-18">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8">
            <h1 className="text-[2rem] text-black font-light mb-1 text-center">
              Empowering Innovation, Enabling Transformation and Delivering
              Excellence
            </h1>
          </div>
          <div className="py-6 md:py-8 mx-6 grid grid-cols-1 xl:grid-cols-4 md:grid-cols-2 gap-6 xl:mx-0 lg:mx-10 md:mx-8">
            <div>
              <ServiceCard
                icon={Culture}
                title="Culture"
                details="We encourage and support everyone to have equal opportunities and time to talk, contribute, joy, pride and Empathy among them."
              />
            </div>
            <div>
              <ServiceCard
                icon={Growth}
                title="Growth"
                details="We grow further together by creating and propelling individual ability and by further developing portrayal at all levels."
              />
            </div>
            <div>
              <ServiceCard
                icon={Diversity}
                title="Diversity"
                details="We create, prioritise and implement the diversity, equity and inclusion (DEI) and pay much greater attention to all of these."
              />
            </div>
            <div>
              <ServiceCard
                icon={Community}
                title="Community"
                details="We, The family who supports not only technical challenges but also non profit supports by extending our hands together."
              />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Home;
