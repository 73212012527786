import React from 'react';

const TermsPage = () => {
  return (

    <section className=" overflow-hidden bg-[#492196]  pt-[80px] md:pt-[100px] ">
    <div className="max-w-7xl mx-auto">
      <div className=" flex flex-wrap items-center">
        <div className="w-full px-6 md:px-10 lg:px-0">
          <div className=" mx-auto text-left">
            <h1 className="mb-8 text-3xl font-bold  text-white sm:text-4xl ">
            Terms and Conditions
            </h1>
          </div>
        </div>
      </div>
    </div>
  
    <div className="bg-gray-100 p-8">
      <div className="max-w-7xl mx-auto">
       
      <section className="mb-6">
          <h2 className="text-xl font-semibold">Introduction</h2>
          <p>Welcome to TechSmew's Terms and Conditions page. By accessing and using this website, you agree to comply with and be bound by the following terms and conditions.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold">Acceptance of Terms</h2>
          <p>Your use of this website is subject to your acceptance of and compliance with these terms. If you disagree with any part of these terms, please do not use this website.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold">Privacy Policy</h2>
          <p>We value your privacy. Please review our Privacy Policy to understand how we collect, use and protect your personal information when you use our services.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold">User Conduct</h2>
          <p>When using this website, you agree to conduct yourself in a respectful and lawful manner. Do not engage in any activity that may harm the website or other users.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold">Intellectual Property</h2>
          <p>All content on this website, including text, graphics and logos, is the intellectual property of TechSmew and is protected by copyright laws.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold">Account Registration</h2>
          <p>To access certain features of this website, you may be required to register an account. You are responsible for maintaining the security of your account information.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold">Termination</h2>
          <p>We reserve the right to terminate your access to this website at our discretion, without prior notice, if you violate these terms or engage in harmful behavior.</p>
        </section>

      </div>
    </div>
    </section>
  );
};

export default TermsPage;
