import React from "react";
import { NavLink } from "react-router-dom";
function Footer() {

  var currentYear = new Date().getFullYear();
  return (
    <footer className="footer bg-black xl:!h-[500px] lg:!h[465px] xl:pb-0 lg:pb-8 pt-5 pb-4">
      <div className="max-w-7xl mx-auto mt-4">
        <div className="grid grid-cols-4 gap-3 xl:!gap-3 md:!gap-1 md:grid-cols-9">
          <div className="!col-span-4 pr-10 xl:!pl-0 lg:!pl-10 md:!pl-10 md:!pr-0 pl-6 md:!col-span-3 lg:!col-span-3">
            <div className="flex flex-wrap items-center justify-start">
              <NavLink to="/">
                <img
                  src="/assets/images/logo-white.svg"
                  alt="logo"
                  className=" w-[150px] lg:!w-[170px] md:!w-[136px] mt-4 mb-8"
                />
              </NavLink>
            </div>
            <p className="text-white opacity-70 mb-4 text-left items-center xl:!w-[400px] lg:!w-[300px] md:!w-[200px]">
              TechSmew Gathering ideas makes it simple for you to frame connections between them.
              We develop digital experiences that assist your organisation with working more intelligently,
              quicker and better. Join us to do the endeavours with One of Emerging IT solutions companies
              in India.
            </p>
          </div>
          <div className="text-white opacity-70 gap-3 mt-8 xl:!pl-10 lg:!pl-0 col-span-2 md:!col-span-1 lg:!col-span-2 md:!pl-6 pl-6">
            {" "}
            <ul className="flex-col pb-3">
              <li className="pb-0 xl:pb-2">
                <NavLink to="/" className=" text-white px-0 pt-1 pb-2">
                  Home
                </NavLink>
              </li>
              <li className="pb-0 xl:pb-2">
                <NavLink
                  to="/case-studies"
                  className=" text-white px-0 pt-1 pb-2"
                >
                  Case Studies
                </NavLink>
              </li>
              <li className="pb-0 xl:pb-2">
                <NavLink to="/about-us" className=" text-white px-0 pt-1 pb-2">
                  About Us
                </NavLink>
              </li>
              <li className="pb-0 xl:pb-2">
                <NavLink
                  to="/careers"
                  className=" text-white d-inline-block px-0 pt-1 pb-2"
                >
                  Careers
                </NavLink>
              </li>
              <li className="pb-0 xl:pb-2">
                <NavLink to="/contact" className=" text-white px-0 pt-1 pb-2">
                  Contact Us
                </NavLink>
              </li>

            </ul>
            <ul className="flex-col mb-2 mt-6">
              <li className="pb-0 xl:pb-2">
                <NavLink to="/terms-and-condition" className=" text-white px-0 pt-1 pb-2">
                  Terms &amp; Conditions
                </NavLink>
              </li>
              <li className="pb-0 xl:pb-2">
                <NavLink to="/policy-policy" className=" text-white px-0 pt-1 pb-2">
                  Privacy Policy
                </NavLink>
              </li>
              <li className="pb-0 xl:pb-2">
                <NavLink to="/cookies-notice" className=" text-white px-0 pt-1 pb-2">
                  Cookies Notice
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="text-white opacity-70 col-span-2 gap-3 mt-6 xl:!pl-10 lg:!pl-0 md:!pl-6">
            <ul className="flex-col mb-2">
              <li className="flex pb-0 items-center">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/techsmew"
                  className=" text-white px-0 pt-1 pb-0 flex items-center"
                >
                  <img
                    className="p-2"
                    src="/assets/images/icons/fb.svg"
                    width="35"
                    alt="techsmew"
                  />
                  Facebook
                </a>
              </li>
              <li className="pb-0">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/techsmew"
                  className=" text-white px-0 pt-1 pb-0 flex items-center"
                >
                  <img
                    className="p-2"
                    src="/assets/images/icons/in.svg"
                    width="35"
                    alt="techsmew"
                  />
                  LinkedIn
                </a>
              </li>
              <li className="pb-0">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/techsmew"
                  className=" text-white px-0 pt-1 pb-0 flex items-center"
                >
                  <img
                    className="p-2"
                    src="/assets/images/icons/twitter.svg"
                    width="35"
                    alt="techsmew"
                  />
                  Twitter
                </a>
              </li>
              <li className="pb-0">
                <a

                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/offcialtechsmew/"
                  className=" text-white px-0 pt-1 pb-0 flex items-center"
                >
                  <img
                    className="p-2"
                    src="/assets/images/icons/instagram.svg"
                    width="35"
                    alt="techsmew"
                  />
                  Instagram
                </a>
              </li>
            </ul>
          </div>

          <div className="text-white opacity-70 col-span-2 mt-8 gap-3 xl:!pl-10 lg:!pl-0 pr-10 pl-6">
            {" "}
            <h6 className="mb-2 text-white">Contact Us</h6>
            <a
              href="mailto:support@techsmew.com"
              className="text-white text-decoration-none fw-medium"
            >
              support@techsmew.com
            </a>
          </div>
        </div>
        <p className=" text-white text-start text-md-start pb-2 mt-8 mb-0 xl:!pl-0 lg:!pl-10 md:!pl-10 pl-6">
          <span className="text-light opacity-50">
            ©{currentYear} All rights reserved. TechSmew Technologies{" "}
          </span>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
