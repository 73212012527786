import React, { useEffect, useState } from 'react';
import Svg from './svg';
import { NavLink } from 'react-router-dom';
import Modal from './applicationModal';
import Culture from "../../assets/images/about/culture.webp";
import Growth from "../../assets/images/about/growth.webp";
import Diversity from "../../assets/images/about/diversity.webp";
import Community from "../../assets/images/about/community.webp";
import { useParams } from 'react-router-dom';
import he from 'he';
import {
  ClockIcon,
  MapPinIcon,
  CalendarIcon,
  UserIcon,
  BanknotesIcon,
  CodeBracketIcon,
} from '@heroicons/react/24/outline';
import { ToastContainer } from 'react-toastify';

const JobDetails = () => {
  const { id } = useParams();
  const [jobData, setJobData] = useState({});
  const [jobDescription, setJobDescription] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_HOST}jobs/${id}`;
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        const data = responseData.data.job;
        setJobData(data);
        setJobDescription(data.job_description)
        setIsLoading(false);
      } catch (error) {
        console.error(error.message);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id]);


  const ServiceCard = ({ icon, title, details }) => {
    return (
      <div className="mb-3 md:mb-3 xl:mb-5 bg-white shadow-md hover:shadow-xl md:px-0 xl:px-0 border-2 transition duration-300 ease-in-out transform hover:scale-105">
        <div className="md:flex justify-start mb-4 items-start top-1/2">
          <img src={icon} alt="Service Icon" className="w-full" />
        </div>
        <div className="ml-2 mr-2">
          <h1 className="ml-2 mr-2 mb-2 text-xl font-medium text-black text-left">
            {title}
          </h1>
          <p className="ml-2 mr-2 mb-6 text-base font-normal text-black">
            {details}
          </p>
        </div>
      </div>
    );
  };

  return (
    <>

      <>
        <section className="relative overflow-hidden bg-[#492196]  pt-[40px] md:pt-[50px] lg:pt-[100px]">
          <div className=" flex flex-wrap items-center max-w-7xl mx-auto">
            {/* Hero */}
            <div className="hero-content mx-auto text-center w-[85%] md:w-[92%] xl:w-[70%]">
              <h1 className="mb-4 md:mb-8 text-xl  font-bold leading-snug
                         text-white sm:text-4xl sm:leading-snug md:text-[45px] 
                          md:leading-snug py-10 md:py-12">
                Shape Your Future Explore Exciting Career Opportunities!
              </h1>
            </div>
            <Svg />
            <div className=" flex items-center justify-center absolute bottom-0 z-20  bg-gradient-to-r from-indigo-500 to-orange-500 px-6 py-2  rounded-t-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8 ">
              <nav className="flex " aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                  <li>
                    <div className="flex items-start">
                      <div className="text-sm mx-1 md:mx-2 font-medium text-gray-100 hover:text-[#f89539] ">
                        <NavLink to={`/`}>
                          Home
                        </NavLink>
                      </div>
                    </div>
                  </li>
                  <li aria-current="page" className="text-white">
                    /
                    <span className="mx-1 md:mx-2 text-sm font-medium text-gray-100 hover:text-[#f89539] ">
                      <NavLink to={`/careers`}>
                        Career
                      </NavLink>
                    </span>
                  </li>
                  <li aria-current="page" className="text-white">
                    /
                    <span className="mx-1 md:mx-2 text-sm font-medium text-gray-100 hover:text-[#f89539] ">
                      Job
                    </span>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </section>

        <section className='w-full bg-[#f3f6ff] mx-auto '>
          <div className="max-w-7xl md:py-8 py-4 mx-auto flex justify-around items-center">
            <div>
              <p className='text-center font-bold text-xl md:text-4xl py-4'>60+</p>
              <p className='text-center text-sm md:text-2xl'>Peoples</p>
            </div>
            <div>
              <p className='text-center font-bold text-xl md:text-4xl py-4'>15+</p>
              <p className='text-center text-sm md:text-2xl'>Products</p>
            </div>
            <div>
              <p className='text-center font-bold text-xl md:text-4xl py-4'>50+</p>
              <p className='text-center text-sm md:text-2xl'>Technology
                <br /> Projects</p>
            </div>
          </div>
        </section>

        {isLoading ? (
          <div className='flex items-center justify-center mx-auto h-screen'>Loading...</div>
        ) : (
          <>{
            jobData &&
            jobData.length !== 0 &&
            <>
              <section className='bg-white mx-auto w-full'>
                <div className="max-w-7xl mx-auto pt-8">
                  <div className="grid md:grid-cols-3 gap-4 p-6 items-center  border border-gray-200 ">
                    <div className='col-span-6  md:col-span-3'>
                      <h2 className='text-xl md:text-2xl mb-2'>
                        {jobData.job_title}
                      </h2>
                    </div>

                    <div className='col-span-6 md:col-span-1 flex flex-col gap-4 text-base'>
                      <div className='flex items-center'>
                        <ClockIcon className='w-6 mr-2  inline-block' />
                        <p>{jobData.job_type}</p>
                      </div>
                      <div className='flex items-center'>
                        <UserIcon className='w-6 mr-2  inline-block' />
                        <p>{jobData.job_exp}</p>
                      </div>
                      <div className='flex items-center'>
                        <BanknotesIcon className='w-6 mr-2  inline-block' />
                        <p>
                          Disclosed
                          {/* {jobData.job_budget}  */}
                        </p>
                      </div>
                    </div>

                    <div className='col-span-6 md:col-span-1 flex flex-col gap-4 text-base'>

                      <div className='flex items-center'>
                        <CodeBracketIcon className='w-6 mr-2  inline-block' />
                        <p>{jobData.job_department}</p>
                      </div>
                      <div className='flex items-center'>
                        <MapPinIcon className='w-6 mr-2  inline-block' />
                        <p>{jobData.job_location}</p>
                      </div>
                      <div className='flex items-center'>
                        {/* <HomeModernIcon className='w-6 mr-2  inline-block' />
                      <p>Software Developing</p> */}
                        <CalendarIcon className='w-6 mr-2 inline-block' />
                        <p className=''> {jobData.updated_at && jobData.updated_at.substr(0, 10)}</p>
                      </div>
                    </div>

                    <div className='col-span-6 md:col-span-1 mt-4 md:mt-0 mr-auto md:ml-auto flex flex-col items-center'>

                      <div className='flex flex-col gap-2'>
                        <Modal />
                        <button className=" bg-[#492196] text-white rounded-full text-sm xl:text-base py-1 px-4 lg:py-2 lg:px-6
                                 hover:bg-[#E75828] hover:text-white active:bg-[#E75828] active:text-white ">
                          <NavLink to="/careers">
                            Explore More
                          </NavLink>
                        </button>
                      
                      </div>

                    </div>
                    <div className='col-span-3 flex justify-end'>
                    </div>
                  </div>
                </div>
              </section>
              <section className='bg-white mx-auto w-full'>
                <div className="max-w-7xl mx-auto my-4">
                  <div className="p-6 border border-gray-200 job_description">

                   {jobDescription && <div dangerouslySetInnerHTML={{ __html: he.decode(jobDescription) }} />}

                    <p className='text-sm my-2 md:text-base'>These criteria are customized to suit the specific requirements of the position and feel free to ask any queries.</p>
                  </div>
                </div>
              </section>
            </>
          }

          </>
        )}

        <section className="bg-white">
          <div className="max-w-7xl mx-auto my-6 md:pb-18">
            <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8">
              <h1 className="text-[2rem] text-black font-light mb-1">
                How We Connect
              </h1>
              <div className="flex flex-nowrap flex-cols-3 mb-3 ">
                <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
                <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
                <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
              </div>
              <p className="text-base text-black">
                At TechSmew, we believe in the power of connection. Our culture is
                built on fostering equal opportunities for all and providing a
                platform where every voice is heard. We encourage open dialogue,
                contributions, shared moments of joy and a sense of pride and
                empathy that binds us together.
              </p>
            </div>
            <div className="py-6 md:py-8 mx-6 grid grid-cols-1 xl:grid-cols-4 md:grid-cols-2 gap-6 xl:mx-0 lg:mx-10 md:mx-8">
              <div>
                <ServiceCard
                  icon={Culture}
                  title="Culture"
                  details="We encourage and support everyone to have equal opportunities and time to talk, contribute, joy, pride and Empathy among them."
                />
              </div>
              <div>
                <ServiceCard
                  icon={Growth}
                  title="Growth"
                  details="We grow further together by creating and propelling individual ability and by further developing portrayal at all levels."
                />
              </div>
              <div>
                <ServiceCard
                  icon={Diversity}
                  title="Diversity"
                  details="We create, prioritise and implement the diversity, equity and inclusion (DEI) and pay much greater attention to all of these."
                />
              </div>
              <div>
                <ServiceCard
                  icon={Community}
                  title="Community"
                  details="We, The family who supports not only technical challenges but also non profit supports by extending our hands together."
                />
              </div>
            </div>
          </div>
        </section>
      </>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={true}
      />
    </>
  )
}

export default JobDetails