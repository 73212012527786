import React from 'react';

const PrivacyPolicyPage = () => {
  return (

    <section className=" overflow-hidden bg-[#492196]  pt-[80px] md:pt-[100px] ">
    <div className="max-w-7xl mx-auto">
      <div className=" flex flex-wrap items-center">
        <div className="w-full px-6 md:px-10 lg:px-0">
          <div className=" mx-auto text-left">
            <h1 className="mb-8 text-3xl font-bold  text-white sm:text-4xl ">
              Privacy Notice
            </h1>
          </div>
        </div>
      </div>
    </div>
  
    <div className="bg-gray-100 p-8">
      <div className="max-w-7xl mx-auto">
       
      <section className="mb-6">
  <h2 className="text-xl font-semibold">1. Introduction</h2>
  <p>Welcome to TechSmew's Privacy Policy. This Privacy Policy describes how we collect, use and protect your personal information when you use our website and services.</p>
</section>

<section className="mb-6">
  <h2 className="text-xl font-semibold">2. Information We Collect</h2>
  <p>We may collect various types of information, including your name, email address, contact details and other information you provide when using our services or interacting with our website.</p>
</section>

<section className="mb-6">
  <h2 className="text-xl font-semibold">3. How We Use Your Information</h2>
  <p>We use the information we collect to provide, maintain and improve our services, to personalize content and to communicate with you. Your information may also be used for legal and security purposes.</p>
</section>

<section className="mb-6">
  <h2 className="text-xl font-semibold">4. Information Sharing</h2>
  <p>We do not sell or rent your personal information to third parties. However, we may share certain information with trusted partners and service providers who assist us in delivering our services.</p>
</section>

<section className="mb-6">
  <h2 className="text-xl font-semibold">5. Cookies and Similar Technologies</h2>
  <p>We use cookies and similar technologies to enhance your browsing experience. You can manage your cookie preferences through your browser settings.</p>
</section>

<section className="mb-6">
  <h2 className="text-xl font-semibold">6. Data Security</h2>
  <p>We take security measures to protect your data. However, no method of data transmission over the internet is completely secure and we cannot guarantee the absolute security of your information.</p>
</section>

<section className="mb-6">
  <h2 className="text-xl font-semibold">7. Your Choices</h2>
  <p>You have the right to access, correct, or delete your personal information. You can also opt out of receiving communications from us.</p>
</section>

<section className="mb-6">
  <h2 className="text-xl font-semibold">8. Changes to This Policy</h2>
  <p>We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any changes through our website or other means.</p>
</section>

<section className="mb-6">
  <h2 className="text-xl font-semibold">9. Contact Us</h2>
  <p>If you have any questions or concerns about our Privacy Policy, please contact us at privacy@techsmew.com.</p>
</section>

       
      </div>
    </div>
    </section>
  );
};

export default PrivacyPolicyPage;
