import React from "react";
import {
  BanknotesIcon,
  ClipboardDocumentListIcon,
  FaceSmileIcon,
  UserGroupIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ShieldExclamationIcon,
  Square2StackIcon,
  RocketLaunchIcon,
  CircleStackIcon,
} from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import CTA from "../../assets/images/cta.webp";
import astroidmain from "../../assets/images/products/astroid/astroid-main.webp";
import AutomatingHospitalOperationswithAsteroidCRM from "../../assets/images/products/astroid/automating-hospital-operations-with-asteroid-crm.webp";
import { Helmet } from "react-helmet";
import PriceTable from "./priceTable";

const ProductNeptune = () => {
  return (
    <>
      {/* section-1 */}
      <section className="relative overflow-hidden bg-[#492196] pt-[80px] md:pt-[50px] lg:pt-[100px] ">
        <Helmet>
          {/* Meta Title: Asteroid CRM for Healthcare | TechSmew Technologies */}
          <title>Asteroid CRM for Healthcare | TechSmew Technologies</title>

          {/* Meta Description: Asteroid CRM has revolutionized hospital operations, tailored for healthcare. This specialized system streamlines processes, elevates patient care and boosts efficiency. By leveraging Asteroid, the hospital optimizes workflows, fostering patient satisfaction. Its transformative impact lies in its tailored features, enhancing operational efficiency and patient contentment. */}
          <meta
            name="description"
            content="Asteroid CRM has revolutionized hospital operations, tailored for healthcare. This specialized system streamlines processes, elevates patient care and boosts efficiency. By leveraging Asteroid, the hospital optimizes workflows, fostering patient satisfaction. Its transformative impact lies in its tailored features, enhancing operational efficiency and patient contentment."
          />

          {/* Meta Keywords (for reference): Asteroid CRM, healthcare CRM, hospital operations, patient care, operational efficiency, patient satisfaction */}
          {/* Note: Meta keywords are not used by major search engines for SEO. */}
          <meta
            name="keywords"
            content="Asteroid CRM, healthcare CRM, hospital operations, patient care, operational efficiency, patient satisfaction"
          />
        </Helmet>
        <div className=" max-w-7xl mx-auto px-6 md:px-10 xl:px-0  ">
          <div className="grid grid-cols-5 gap-4 xl:mb-10">
            <div className="col-span-5 lg:col-span-3 my-auto xl:max-w-xl ">
              <div className="text-white">
              <div className="mx-auto text-left">
              <h1 className="mb-8 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug md:mt-12">
              Elevate your business with Astroid, the ultimate CRM solution.
              </h1>
              <p className=" mb-16 lg:mb-24  font-normal text-base text-[#e4e4e4]   sm:leading-relaxed md:text-base md:leading-relaxed">
              Streamline customer support, maintain accurate customer records, 
              and supercharge your enterprise's productivity with Astroid, our 
              comprehensive CRM solution. Designed for businesses in banking, 
              retail and beyond, Asteroid seamlessly blends cutting-edge 
              technology with intuitive design to transform your operations.
              </p>
            </div>
              </div>
            </div>
            <div className="col-span-2 ml-auto hidden lg:block my-auto">
              <img
                src={astroidmain}
                className="my-auto  md:mt-6 lg:mt-0  md:px-2 lg:px-10 xl:px-0  "
                alt=""
              />
            </div>
            <div className="flex items-center justify-center absolute bottom-0 z-20  bg-gradient-to-r from-indigo-500 to-orange-500 
            px-6 py-2  rounded-t-lg mx-6 md:mx-2 lg:mx-10 xl:mx-0  ">
              <nav className="flex " aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                  <li>
                    <div className="flex items-start">
                      <a
                        className="text-sm mx-1 md:mx-2 font-medium text-gray-100 hover:text-[#f89539] "
                        href="/"
                      >
                        <NavLink to={`/`}>Home</NavLink>
                      </a>
                    </div>
                  </li>
                  <li aria-current="page" className="text-white">
                    /
                    <span className="mx-1 md:mx-2 text-sm font-medium text-gray-100 hover:text-[#f89539] ">
                      <NavLink to={`/products`}>Products</NavLink>
                    </span>
                  </li>
                  <li aria-current="page" className="text-white">
                    /
                    <span className="mx-1 md:mx-2 text-sm font-medium text-gray-100 hover:text-[#f89539] ">
                    Asteroid
                    </span>
                  </li>
                </ol>
              </nav>
            </div>
          </div>

        </div>
       
      </section>

      {/* section-2 */}
      <section className="bg-[#f3f6ff]">
        <div className="relative overflow-hidden max-w-7xl mx-auto md:px-8 lg:px-10 xl:px-0">
          <div className="mx-auto pb-12 pt-20">
            <div className="grid grid-cols-1 lg:grid-cols-2  md:gap-6 px-6 md:px-0">
              <div className="col-span-1">
                <h3 className="text-[2rem] font-light text-black text-left  pb-2 lg:pb-4 ">
                Revolutionise Your Business with Asteroid CRM
                </h3>
                <div className="md:ml-auto w-[100%] ">
                  <div className="flex flex-nowrap flex-cols-3 pb-4 ">
                    <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
                    <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
                    <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
                  </div>
                </div>
                <img
                  src={AutomatingHospitalOperationswithAsteroidCRM}
                  className="w-full h-auto object-cover max-w-xl md:hidden"
                  alt="chat-bot"
                />
                <p className=" mx-auto text-lg text-left pb-8">
                In today's fast-paced world, businesses in banking and retail need 
                more than just a CRM tool. They require a comprehensive solution 
                that empowers them to stay ahead. Asteroid CRM is designed to transform your enterprise
                </p>
                <div className="flex items-center justify-start text-[#e75828]">
                  <NavLink to="/contact" className="flex my-1 text-[#e75828]">
                    <button className="bg-[#e75828] border-2 border-[#e75828] hover:bg-orange-700 text-white hover:text-white py-2 px-6 flex items-center rounded-full">
                      <span className="flex  items-center justify-center">
                        Explore Now
                      </span>
                    </button>
                  </NavLink>
                </div>
              </div>
              <div className="col-span-1 hidden md:flex">
                <img
                  src={AutomatingHospitalOperationswithAsteroidCRM}
                  className="w-full h-auto object-cover"
                  alt="chat-bot"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section-3 */}
      <section className="bg-white">
        <div className="relative overflow-hidden max-w-7xl mx-auto   lg:px-0 md:mt-6 xl:mt-16">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8">
            <h1 className="text-[2rem] font-light text-black text-left  pb-2 lg:pb-4 ">
              Elevating Hospitals with Benefits of Asteroid CRM
            </h1>
            <div className="flex flex-nowrap flex-cols-3 pb-4 ">
              <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
              <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
              <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
            </div>
            <p className=" mx-auto text-lg text-left pb-4 md:pb-6">
              The hospital wanted to update and improve their operations,
              especially in patient management for the automation industry. They
              needed a strong CRM solution to gather patient information,
              automate tasks and improve communication among staff. The goal
              was to improve patient care, make operations smoother and provide
              great patient experiences.
            </p>
          </div>
          {/* mobile and medium */}
          <div className="lg:hidden">
            <div className="grid grid-cols-1 md:grid-cols-2 mx-auto gap-2 md:gap-4 w-full px-6 md:px-8   ">
              <div className="bg-[#0080FF] text-white p-4 md:p-6   rounded-xl">
                <div className="flex  flex-wrap justify-between items-center mb-2 md:mb-4">
                  <h4 className="text-lg font-medium">Enhanced Efficiency</h4>
                  <UserGroupIcon className="w-6 " />
                </div>
                <p className="text-sm  md:pb-0">
                  Automation of administrative tasks improves hospital
                  operations, Staff can focus on patient care instead of manual
                  paperwork.
                </p>
              </div>

              <div className="bg-[#E76B7D] text-white p-4 md:p-6 rounded-xl">
                <div className="flex  flex-wrap justify-between items-center mb-2 md:mb-4">
                  <h4 className="text-lg font-medium">Patient Experience</h4>
                  <ChatBubbleOvalLeftEllipsisIcon className="w-6" />
                </div>
                <p className="text-sm  md:pb-0">
                  Streamlined appointment scheduling, communication and medical
                  information access, Patients feel engaged and well-informed
                  about their healthcare journey.
                </p>
              </div>

              <div className="bg-[#D24E7D] text-white p-4 md:p-6 rounded-xl">
                <div className="flex  flex-wrap justify-between items-center mb-2 md:mb-4">
                  <h4 className="text-lg font-medium">Reduced Errors</h4>
                  <ShieldExclamationIcon className="w-6" />
                </div>
                <p className="text-sm  md:pb-0">
                  Automation decreases errors in tasks like appointments,
                  billing and data entry, Enhances accuracy in patient records
                  and financial transactions.
                </p>
              </div>

              <div className="bg-[#8557E2] text-white  p-4 md:p-6 rounded-xl">
                <div className="flex  flex-wrap justify-between items-center mb-2 md:mb-4">
                  <h4 className="text-lg font-medium">Optimized Resource</h4>
                  <Square2StackIcon className="w-6" />
                </div>
                <p className="text-sm  md:pb-0">
                  CRM analytics aid data-driven decisions for resources,
                  staffing and facility management, Better resource utilization
                  and cost savings result.
                </p>
              </div>

              <div className="bg-[#499B61] text-white  p-4 md:p-6 rounded-xl">
                <div className="flex  flex-wrap justify-between items-center mb-2 md:mb-4">
                  <h4 className="text-lg font-medium">Better Compliance</h4>
                  <RocketLaunchIcon className="w-6" />
                </div>
                <p className="text-sm  md:pb-0">
                  Tracking patient data and appointments ensures compliance with
                  regulations and follow-up care.
                </p>
              </div>

              <div className="bg-[#F3AB41] text-white  p-4 md:p-6 rounded-xl">
                <div className="flex  flex-wrap justify-between items-center mb-2 md:mb-4">
                  <h4 className="text-lg font-medium">Increased Revenue</h4>
                  <BanknotesIcon className="w-6" />
                </div>
                <p className="text-sm  md:pb-0">
                  Effective billing, insurance claim processing and reduced
                  no-shows improve revenue collection.
                </p>
              </div>

              <div className="bg-[#EF9B70] text-white md:col-span-2  p-4 md:p-6 rounded-xl">
                <div className="flex  flex-wrap justify-between items-center mb-2 md:mb-4">
                  <h4 className="text-lg font-medium">Staff Satisfaction</h4>
                  <FaceSmileIcon className="w-6" />
                </div>
                <p className="text-sm  md:pb-0">
                  Automating routine tasks lowers staff burden, leading to
                  higher job satisfaction, Staff can focus on more meaningful
                  aspects of their work.
                </p>
              </div>
            </div>
          </div>
          {/* large and extra large */}
          <div className="hidden lg:flex px-10 xl:px-0">
            <div className="grid grid-cols-4 mx-auto my-8 w-full   ">
              <div className="col-span-1">
                <div className="bg-[#0080FF] text-white h-full rounded-xl mr-2 xl:mr-4 p-6 flex justify-center items-center">
                  <div className="flex flex-col flex-wrap  items-start ">
                    <UserGroupIcon className="w-6 " />
                    <h4 className="text-2xl font-light mb-4">
                      Enhanced Efficiency
                    </h4>
                    <p className="pb-4">
                      Automation of administrative tasks improves hospital
                      operations, Staff can focus on patient care instead of
                      manual paperwork.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-span-3">
                <div className="w-full flex  ">
                  <div className="bg-[#E76B7D] text-white p-6 rounded-xl mx-2 xl:mx-4 xl:w-3/4">
                    <div className="flex  flex-wrap justify-between items-center mb-4">
                      <h4 className="text-2xl font-light">
                        Patient Experience
                      </h4>
                      <ChatBubbleOvalLeftEllipsisIcon className="w-8 " />
                    </div>
                    <p>
                      Streamlined appointment scheduling, communication and
                      medical information access, Patients feel engaged and
                      well-informed about their healthcare journey.
                    </p>
                  </div>
                  <div className="bg-[#D24E7D] text-white p-6 rounded-xl mx-2 xl:mx-4">
                    <div className="flex  flex-wrap justify-between items-center mb-4">
                      <h4 className="text-2xl font-light">Reduced Errors</h4>
                      <ShieldExclamationIcon className="w-8" />
                    </div>
                    <p>
                      Automation decreases errors in tasks like appointments,
                      billing and data entry, Enhances accuracy in patient
                      records and financial transactions.
                    </p>
                  </div>
                  <div className="bg-[#8557E2] text-white p-6 ml-2 xl:ml-4 rounded-xl">
                    <div className="flex  flex-wrap justify-between items-center mb-4">
                      <h4 className="text-2xl font-light">
                        Optimized Resource
                      </h4>
                      <Square2StackIcon className="w-8" />
                    </div>
                    <p>
                      CRM analytics aid data-driven decisions for resources,
                      staffing and facility management, Better resource
                      utilization and cost savings result.
                    </p>
                  </div>
                </div>

                <div className="w-full flex">
                  <div className="bg-[#499B61] text-white p-6 mx-2 mt-4 xl:mx-4 xl:mt-4  rounded-xl">
                    <div className="flex  flex-wrap justify-between items-center mb-4">
                      <h4 className="text-2xl font-light">Better Compliance</h4>
                      <RocketLaunchIcon className="w-8" />
                    </div>
                    <p>
                      Tracking patient data and appointments ensures compliance
                      with regulations and follow-up care.
                    </p>
                  </div>
                  <div className="bg-[#F3AB41] text-white p-6 mx-2 mt-4 xl:mx-4 xl:mt-4 rounded-xl">
                    <div className="flex  flex-wrap justify-between items-center mb-4">
                      <h4 className="text-2xl font-light">Increased Revenue</h4>
                      <BanknotesIcon className="w-8" />
                    </div>
                    <p>
                      Effective billing, insurance claim processing and reduced
                      no-shows improve revenue collection.
                    </p>
                  </div>
                  <div className="bg-[#EF9B70] text-white p-6 ml-2 mt-4 xl:ml-4 xl:mt-4  xl:w-3/4 rounded-xl">
                    <div className="flex  flex-wrap justify-between items-center mb-4">
                      <h4 className="text-2xl font-light">
                        Staff Satisfaction
                      </h4>
                      <FaceSmileIcon className="w-8" />
                    </div>
                    <p>
                      Automating routine tasks lowers staff burden, leading to
                      higher job satisfaction, Staff can focus on more
                      meaningful aspects of their work.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section-4 */}
      <section className="bg-white">
        <div className="max-w-7xl mx-auto my-24 md:pb-18">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8">
            <h1 className="text-[2rem] font-light text-black text-left  pb-2 lg:pb-4 ">
              Features of Asteroid for Hospital Automation
            </h1>
            <div className="flex flex-nowrap flex-cols-3 mb-6 ">
              <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
              <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
              <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
            </div>
            <p className=" mx-auto text-lg text-left pb-8">
              Asteroid's hospital automation features include a centralized
              patient database for real-time record management, eliminating
              paper-based systems. Automated appointment scheduling and
              reminders enhance patient engagement while optimizing resource
              utilization. Seamless communication tools keep patients informed
              about appointments, results and plans. Workflow automation
              handles administrative tasks, freeing up staff for more valuable
              work. Task management ensures efficient allocation across teams.
              Performance analytics enable administrators to monitor key
              indicators like wait times and resource usage. Asteroid's
              comprehensive features drive data-driven decision-making and
              process improvement.
            </p>
          </div>

          {/* mobile and medium */}
          <div className="md:hidden">
            <div className="grid grid-cols-1   gap-2 md:gap-4 mx-auto my-8 w-full px-2 md:px-6">
              <div className="col-span-1 bg-white p-2  mx-4 rounded-xl shadow-lg">
                <div className="flex flex-col ">
                  <div className="flex items-center justify-between">
                    <h4 className="text-lg font-bold mb-2 ">
                      Efficiency at its Best
                    </h4>
                    <CircleStackIcon className="w-8 text-[#8557E2]" />
                  </div>
                  <p className="pb-2 text-sm">
                  Maximise efficiency with streamlined support ticket handling, ensuring your customers receive prompt assistance and your team stays organised.

                  </p>
                </div>
              </div>
              <div className="col-span-1 bg-white p-2  mx-4 rounded-xl shadow-lg">
                <div className="flex flex-col ">
                  <div className="flex items-center justify-between">
                    <h4 className="text-lg font-bold mb-2 ">
                      Proactive Follow-ups
                    </h4>
                    <ChatBubbleOvalLeftEllipsisIcon className="w-8 text-[#499B61]" />
                  </div>
                  <p className="pb-2 text-sm">
                  Never miss an opportunity with automated follow-up reminders, ensuring your relationships with customers remain strong.

                  </p>
                </div>
              </div>
              <div className="col-span-1 bg-white p-2  mx-4 rounded-xl shadow-lg">
                <div className="flex flex-col ">
                  <div className="flex items-center justify-between">
                    <h4 className="text-lg font-bold mb-2 ">
                    Seamless Integration
                    </h4>
                    <ClipboardDocumentListIcon className="w-8 text-[#D04577]" />
                  </div>
                  <p className="pb-2 text-sm">
                  Easily integrate Asteroid CRM with your existing tools and systems for a seamless workflow.

                  </p>
                </div>
              </div>
              <div className="col-span-1 bg-white p-2  mx-4 rounded-xl shadow-lg">
                <div className="flex flex-col ">
                  <div className="flex items-center justify-between">
                    <h4 className="text-lg font-bold mb-2 ">
                     Customization Options
                    </h4>
                    <FaceSmileIcon className="w-8 text-[#EF9B70]" />
                  </div>
                  <p className="pb-2 text-sm">
                  Tailor Asteroid CRM to suit your specific needs, whether you're a large banking institution or a small retail business.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* large and extra large */}
          <div className="hidden md:flex mx-4 lg:mx-0">
            <div className="grid grid-cols-1 md:grid-cols-2  gap-6 mx-auto my-8 w-full px-4 lg:px-6 xl:px-0">
              <div className="col-span-1 bg-white p-2 xl:p-6 lg:mx-4 xl:mx-0 rounded-xl shadow-lg">
                <div className="flex flex-row  justify-start items-center">
                  <CircleStackIcon className="text-8xl w-48 text-[#8557E2]" />
                  <div className="px-8  text-black">
                    <h4 className="text-2xl font-light mb-1 lg:mb-2 ">
                      Efficiency at its Best
                    </h4>
                    <p className="pb-4 text-base ">
                    Maximise efficiency with streamlined support ticket handling, ensuring your customers receive prompt assistance and your team stays organised.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-1 bg-white p-2 xl:p-6 lg:mx-4 xl:mx-0 rounded-xl shadow-lg">
                <div className="flex flex-row  justif items-center">
                  <ChatBubbleOvalLeftEllipsisIcon className="text-8xl w-48  text-[#499B61]" />
                  <div className="px-8  text-black">
                    <h4 className="font-light text-2xl mb-1 lg:mb-2 ">
                      Proactive Follow-ups
                    </h4>
                    <p className="pb-4 text-base">
                    Never miss an opportunity with automated follow-up reminders, ensuring your relationships with customers remain strong.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-1 bg-white p-2 xl:p-6 lg:mx-4 xl:mx-0 rounded-xl shadow-lg">
                <div className="flex flex-row  justify-start items-center">
                  <ClipboardDocumentListIcon className="text-8xl w-48  text-[#D04577]" />
                  <div className="px-8  text-black">
                    <h4 className="font-light text-2xl mb-1 lg:mb-2 ">
                    Seamless Integration
                    </h4>
                    <p className="pb-4 text-base">
                    Easily integrate Asteroid CRM with your existing tools and systems for a seamless workflow.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-1 bg-white p-2 xl:p-6 lg:mx-4 xl:mx-0 rounded-xl shadow-lg ">
                <div className="flex flex-row  justify-start items-center">
                  <FaceSmileIcon className="text-8xl w-48  text-[#EF9B70]" />
                  <div className="px-8  text-black">
                    <h4 className="font-light text-2xl mb-1 lg:mb-2 ">
                     Customization Options
                    </h4>
                    <p className="pb-4 text-base">
                    Tailor Asteroid CRM to suit your specific needs, whether you're a large banking institution or a small retail business.

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



   
      <section className="bg-white">
        <div className="max-w-7xl mx-auto my-6 md:pb-18">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8 ">
            <h1 className="text-[2rem] font-light text-black text-center mb-1">
              Flexible Plans to Fit Your Needs
            </h1>
            <p className="text-lg text-black text-center mb-6 md:mb-16">
              Select the perfect plan for your business goals and start your
              Neptune and Astroid experience.Find the plan that suits your
              business size and ambitions, ensuring you get the most value out
              of Neptune and Astroid.
            </p>
          </div>
          <div className="mb-6 md:mb-16">
          <PriceTable />
          </div>
         
        </div>
      </section>
   
      {/* <section className="bg-white">
        <div className="max-w-7xl mx-auto my-6 md:pb-18">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8 ">
            <h1 className="text-[2rem] font-light text-black text-center mb-1">
              Flexible Plans to Fit Your Needs
            </h1>
          
            <p className="text-base font-normal text-black text-center">
              Select the Perfect Plan for Your Business Goals and Start Your
              Neptune and Astroid Experience.Find the plan that suits your
              business size and ambitions, ensuring you get the most value out
              of Neptune and Astroid.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 md:mx-8 xl:mx-0 lg:mx-10 py-10 mx-6">
            <div className="bg-white shadow-md hover:shadow-xl border-2 transition duration-300 ease-in-out transform hover:scale-105">
              <div className="pt-6 pb-2 md:my-1 mx-6">
                <h6 className="text-xl font-medium flex items-center justify-center">
                  Standard
                </h6>
                <h1 className="text-4xl font-medium flex items-center justify-center py-2">
                  $5
                </h1>
                <p className="text-sm font-normal flex items-center justify-center py-1">
                  {" "}
                  /user/month billed monthly
                </p>
              </div>
              <div className="flex items-center justify-center text-[#e75828]">
                <NavLink to="/contact" className="flex my-1 text-[#e75828]">
                  <button className="bg-[#e75828] text-sm hover:bg-[#492196] hover:border-[#492196] border-2 border-[#e75828] text-white hover:text-whitefont-medium py-1 px-4 flex items-center rounded-full">
                    <span className="flex w-[140px] items-center justify-center">
                      Contact Us
                    </span>
                  </button>
                </NavLink>
              </div>
              <div className="my-4 py-2 md:px-4 mx-6">
                <p className="text-base text-center text-black font-normal">
                  Automate and optimize your sales cycle
                </p>
              </div>
              
            </div>
            <div className="bg-white shadow-md hover:shadow-xl border-2 transition duration-300 ease-in-out transform hover:scale-105">
              <div className="pt-6 pb-2 md:my-1 mx-6">
                <h6 className="text-xl font-medium flex items-center justify-center">
                  Professional
                </h6>
                <h1 className="text-4xl font-medium flex items-center justify-center py-2">
                  $8
                </h1>
                <p className="text-sm font-normal flex items-center justify-center py-1">
                  {" "}
                  /user/month billed monthly
                </p>
              </div>
              <div className="flex items-center justify-center text-[#e75828]">
                <NavLink to="/contact" className="flex my-1 text-[#e75828]">
                  <button className="bg-[#e75828] text-sm hover:bg-[#492196] hover:border-[#492196] border-2 border-[#e75828] text-white hover:text-whitefont-medium py-1 px-4 flex items-center rounded-full">
                    <span className="flex w-[140px] items-center justify-center">
                      Contact Us
                    </span>
                  </button>
                </NavLink>
              </div>
              <div className="my-4 py-2 md:px-4 mx-6">
                <p className="text-base text-center text-black font-normal">
                  Improve customer acquisition and accelerate growth
                </p>
              </div>
             
            </div>
            <div className="bg-white shadow-md hover:shadow-xl border-2 transition duration-300 ease-in-out transform hover:scale-105">
              <div className="pt-6 pb-2 md:my-1 mx-6">
                <h6 className="text-xl font-medium flex items-center justify-center">
                  Enterprise
                </h6>
                <h1 className="text-4xl font-medium flex items-center justify-center py-2">
                  $13
                </h1>
                <p className="text-sm font-normal flex items-center justify-center py-1">
                  {" "}
                  /user/month billed monthly
                </p>
              </div>
              <div className="flex items-center justify-center text-[#e75828]">
                <NavLink to="/contact" className="flex my-1 text-[#e75828]">
                  <button className="bg-[#e75828] text-sm hover:bg-[#492196] hover:border-[#492196] border-2 border-[#e75828] text-white hover:text-whitefont-medium py-1 px-4 flex items-center rounded-full">
                    <span className="flex w-[140px] items-center justify-center">
                      Contact Us
                    </span>
                  </button>
                </NavLink>
              </div>
              <div className="my-4 py-2 md:px-4 mx-6">
                <p className="text-base text-center text-black font-normal">
                  Manage global CX operations with the complete CRM
                </p>
              </div>
             
            </div>
            <div className="bg-white shadow-md hover:shadow-xl border-2 transition duration-300 ease-in-out transform hover:scale-105">
              <div className="pt-6 pb-2 md:my-1 mx-6">
                <h6 className="text-xl font-medium flex items-center justify-center">
                  Ultimate
                </h6>
                <h1 className="text-4xl font-medium flex items-center justify-center py-2">
                  $25
                </h1>
                <p className="text-sm font-normal flex items-center justify-center py-1">
                  {" "}
                  /user/month billed monthly
                </p>
              </div>
              <div className="flex items-center justify-center text-[#e75828]">
                <NavLink to="/contact" className="flex my-1 text-[#e75828]">
                  <button className="bg-[#e75828] text-sm hover:bg-[#492196] hover:border-[#492196] border-2 border-[#e75828] text-white hover:text-whitefont-medium py-1 px-4 flex items-center rounded-full">
                    <span className="flex w-[140px] items-center justify-center">
                      Contact Us
                    </span>
                  </button>
                </NavLink>
              </div>
              <div className="my-4 py-2 md:px-4 mx-6">
                <p className="text-base text-center text-black font-normal">
                  Scale exponentially with dedicated BI capabilities
                </p>
              </div>
             
            </div>
          </div>
        </div>
      </section> */}

      {/* section-5 */}
      <section className="bg-gray-100 ">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 py-10 mx-6 lg:mx-10 md:mx-8 xl:mx-0">
            <div className="justify-center items-center pt-5 md:col-span-2">
              <div className="pt-5 pb-3 pb-md-5 md:px-0 text-center md:text-left">
                <p className="text-[2rem] font-normal text-black mb-3">
                Experience the Future of CRM
                </p>
                <h2 className="text-xl font-normal text-black pb-3">
                Discover the power of Asteroid CRM with these key features.
                </h2>
                <NavLink
                  to="/contact"
                  className="bg-[#0d6efd] hover:bg-[#0d6dfdb7] mt-6 mb-2 text-white py-3 text-base px-6 inline-block transition duration-300 ease-in-out rounded-full"
                >
                  Discover Neptune
                </NavLink>
              </div>
            </div>
            <div className="md:flex justify-end items-center md:col-span-1">
              <img src={CTA} alt="CTA" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductNeptune;
