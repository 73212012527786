import React, { useState } from "react";
import { Controller, useForm } from 'react-hook-form'
import { toast  } from "react-toastify";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";






const schema = yup.object().shape({
    fname: yup.string().required("First name is required"),
    lname: yup.string().required("Last name is required"),
    email: yup
        .string()
        .email("Invalid email address")
        .required("Email address is required"),
    phone: yup
        .string()
        .matches(/^\d{10}$/, 'Phone number must be a valid 10-digit number')
        .required('Phone number is required'),
    residence: yup.string().required("Please add your residence"),
    skills: yup.string().required("Please add your skills"), 
    preferred_department: yup.object().required("Please select your Preferred Department"),
});

const colourStyles = {
    control: (styles) => ({ ...styles, lineHeight: "28px", border: "0px", fontSize: "13px", borderBottom: "2px solid #d1d5db", borderRadius: "0px", boxShadow: "none" }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: '0 0px'
    }),
    singleValue: (provided, state) => {
        const margin = "2px 0 0 5px";
        return { ...provided, margin };
    },
};

export default function InternshipModal() {



    const [showModal, setShowModal] = useState(false);
    const [isSubmitting,setIsSubmitting]  = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ mode: "all", resolver: yupResolver(schema) });

    const [isFileError, setIsFileError] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

        if (file && allowedTypes.includes(file.type)) {
            setIsFileError(false);
            setSelectedFile(file);
        } else {
            setIsFileError(true);
            setSelectedFile(null);
            toast.error("Please upload a valid PDF or document file");
        }
    };
    
    const preferred_department = [
        { value: "Front End", label: "Front End" },
        { value: "Back End", label: "Back End" },
        { value: "Java Full Stack", label: "Java Full Stack" },
        { value: "MERN Stack", label: "MERN Stack" },
        { value: "MEAN Stack", label: "MEAN Stack" }
    ]

    const onSubmitInternship = async (data, e) => {
        if (selectedFile) {
            setIsSubmitting(true)
            e.preventDefault();
            const resumeFormData = new FormData();
            resumeFormData.append("internship_cv", selectedFile);
            resumeFormData.append("internship_first_name", data.fname );
            resumeFormData.append("internship_last_name", data.lname );
            resumeFormData.append("internship_phone", data.phone);
            resumeFormData.append("internship_email", data.email);
            resumeFormData.append("internship_address1", data.residence);
            resumeFormData.append("internship_preferred_location", data.skills);
            resumeFormData.append("internship_current_oganization", data["preferred_department"]["value"]);
           
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_HOST}internships`,
                    {
                        method: "POST",
                        body: resumeFormData,
                        headers: {
                            Authorization: `Bearer ${process.env.REACT_APP_token}`,
                        },
                    }
                );
                if (response.ok) {
                    const json = await response.json();
                    if (json.status === 200) {
                        reset();
                        toast.success("Thank You! We will get back to you soon.");
                        setShowModal(false);
                    } else {
                        toast.error("Facing an error! Please try again!");
                    }
                } else {
                    toast.error("Network error occurred. Please try again later.");
                }
            } catch (error) {
                console.error("An error occurred:", error);
                toast.error("An error occurred. Please try again later.");
            }
            setIsSubmitting(false)
            return true
        }

        setIsFileError(true)
        toast.error("Please upload your resume");
        return false
        };





    return (
        <>
            <button className="mt-4 lg:mt-0 md:mt-0 lg:ml-4 bg-orange-500 hover:bg-orange-700 text-white font-normal text-base py-2 px-6 rounded-full"
                type="button"
                onClick={() => setShowModal(true)} >
                Join with Us!
            </button>
            {showModal && (
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-gray-300 my-10">
                    {/* Rest of your modal code */}
                    <div className="relative p-6 flex-auto">
                        <section className={`  max-w-7xl mx-auto`}>
                            <div className='mx-auto  max-w-5xl'>
                                <div className=" mx-auto border-2 bg-white border-gray-200 p-6 md:p-8 md:my-12">
                                    <form onSubmit={handleSubmit(onSubmitInternship)} className="space-y-2">

                                        <div className="grid  grid-cols-2 gap-2 md:gap-4">
                                            <h3 className="text-2xl font-medium col-span-2">Elevate Your Career</h3>

                                            <div className="relative z-0 col-span-2 md:col-span-1 w-full mb-6 group">
                                                <input
                                                    type="text"
                                                    name="fname"
                                                    id="fname"
                                                    {...register("fname", { required: true })}
                                                    className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 ${errors.fname
                                                        ? "border-red-400 focus:border-red-600"
                                                        : "dark:border-gray-600 dark:focus:border-blue-500 focus:border-blue-600"
                                                        } appearance-none dark:text-white focus:outline-none focus:ring-0  peer`}
                                                    placeholder=" "
                                                />
                                                <label
                                                    htmlFor="fname"
                                                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 
                                                               -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                                               peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                >
                                                    First name
                                                </label>

                                                {errors.fname && (
                                                    <div className="text-sm text-red-500">
                                                        First name is required
                                                    </div>
                                                )}
                                            </div>
                                            <div className="relative z-0 w-full mb-6 group col-span-2 md:col-span-1">
                                                <input
                                                    type="text"
                                                    name="lname"
                                                    id="lname"
                                                    className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 ${errors.lname
                                                        ? "border-red-400 focus:border-red-600"
                                                        : "dark:border-gray-600 dark:focus:border-blue-500 focus:border-blue-600"
                                                        } appearance-none dark:text-white focus:outline-none focus:ring-0  peer`}
                                                    placeholder=" "
                                                    {...register("lname", { required: true })}
                                                />
                                                <label
                                                    htmlFor="lname"
                                                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
                                                              -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                                              peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                >
                                                    Last name
                                                </label>
                                                {errors.lname && (
                                                    <div className="text-sm text-red-500">
                                                        Last name is required
                                                    </div>
                                                )}
                                            </div>

                                            <Controller
                                                name="email"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                    <div className="relative z-0 w-full mb-6 group col-span-2 md:col-span-1">
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 ${errors.email
                                                                ? "border-red-400 focus:border-red-600"
                                                                : "dark:border-gray-600 dark:focus:border-blue-500 focus:border-blue-600"
                                                                } appearance-none dark:text-white focus:outline-none focus:ring-0  peer`}
                                                            placeholder=" "
                                                            {...register("email", { required: true })}
                                                        />
                                                        <label
                                                            htmlFor="email"
                                                            className="peer-focus:font-medium absolute text-sm
                                                                      text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 
                                                                       top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500
                                                                       peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                        >
                                                            Email address
                                                        </label>
                                                        {errors.email && (
                                                            <div className="text-sm text-red-500">
                                                                {errors.email.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            />

                                            <div className="relative mt-0 col-span-2 md:col-span-1">
                                                <div className="relative z-0 w-full mb-6 group">
                                                    <input
                                                        type="number"
                                                        name="phone"
                                                        id="phone"
                                                        pattern="[0-9]*"
                                                        className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 ${errors.phone
                                                            ? "border-red-400 focus:border-red-600"
                                                            : "dark:border-gray-600 dark:focus:border-blue-500 focus:border-blue-600"
                                                            } appearance-none dark:text-white focus:outline-none focus:ring-0  peer`}
                                                        placeholder=" "
                                                        {...register("phone", { required: true })}
                                                    />
                                                    <label
                                                        htmlFor="phone"
                                                        className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300
                                                                   transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500
                                                                   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                    >
                                                        Phone
                                                    </label>
                                                    {errors.phone && (
                                                        <div className="text-sm text-red-500">
                                                            {errors.phone.message}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="relative z-0 w-full my-3 group col-span-2">
                                                <textarea
                                                    type="text"
                                                    name="residence"
                                                    id="residence"
                                                    className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 ${errors.residence
                                                        ? "border-red-400 focus:border-red-600"
                                                        : "dark:border-gray-600 dark:focus:border-blue-500 focus:border-blue-600"
                                                        } appearance-none dark:text-white focus:outline-none focus:ring-0  peer`}
                                                    placeholder=" "
                                                    {...register("residence", { required: true })}
                                                ></textarea>
                                                <label
                                                    htmlFor="residence"
                                                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300
                                                              transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500
                                                              peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                >
                                                    Residence
                                                </label>
                                                {errors.residence && (
                                                    <div className="text-sm text-red-500">
                                                        {errors.residence.message}
                                                    </div>
                                                )}
                                            </div>


                                            <div className="relative z-0 w-full my-3 group col-span-2">
                                                <textarea
                                                    type="text"
                                                    name="skills"
                                                    id="skills"
                                                    className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 ${errors.skills
                                                        ? "border-red-400 focus:border-red-600"
                                                        : "dark:border-gray-600 dark:focus:border-blue-500 focus:border-blue-600"
                                                        } appearance-none dark:text-white focus:outline-none focus:ring-0  peer`}
                                                    placeholder=" "
                                                    {...register("skills", { required: true })}
                                                ></textarea>
                                                <label
                                                    htmlFor="skills"
                                                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300
                                                              transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500
                                                              peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                >
                                                    Skills
                                                </label>
                                                {errors.skills && (
                                                    <div className="text-sm text-red-500">
                                                        {errors.skills.message}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="relative col-span-2">
                                                <div className="form-floating form-floating-custom">
                                                    <Controller
                                                        name="preferred_department"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={preferred_department.preferred_department}
                                                        render={({ field }) => (
                                                            <Select
                                                                styles={colourStyles}
                                                                options={preferred_department}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                {...field}
                                                                placeholder="Preferred Department"
                                                            />
                                                        )}
                                                    />
                                                    {errors.preferred_department && (
                                                        <div className="text-sm text-red-500">
                                                            {errors.preferred_department.message}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className='mt-2'>
                                                <label
                                                    htmlFor="file-upload"
                                                    className={`bg-white border border-gray-400 hover:border-2 hover:border-[#492196] text-[#492196] py-1 px-2 mt-4 md:py-2 md:px-4 rounded cursor-pointer`} >
                                                  {!selectedFile ? 'Choose File':'Upload'}
                                                </label>
                                                <input
                                                    name="file"
                                                    placeholder="file"
                                                    id="file-upload"
                                                    type="file"
                                                    className="hidden"
                                                    onChange={handleFileChange}
                                                    
                                                />
                                                {isFileError && (
                                                    <div className="text-sm text-red-500 mt-2 ">
                                                        Please upload your resume
                                                    </div>
                                                )}
                                            </div>

                                            <div className='col-span-2 md:col-span-1 mt-4 md:mt-0'>
                                                <div className='md:ml-auto w-fit'>

                                                    <button type="button"
                                                        onClick={handleCloseModal}
                                                        className="bg-[#f97316] mr-2 md:mr-3 text-sm md:text-base w-16 md:w-24 text-white px-2 py-1 md:px-4 md:py-2  rounded-full">
                                                        cancel
                                                    </button>
                                                    <button
                                                    disabled={isSubmitting}
                                                        type="submit"
                                                        className="bg-[#492196] text-sm md:text-base w-32 md:w-32 text-white px-2 py-1 md:px-4 md:py-2  rounded-full">
                                                        {(isSubmitting)? "Submitting..." : "Submit"}
                                                    </button>


                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </div>
                  
                </div>
            )}
            {showModal && (
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            )}
          
        </>
    );
}


