import {
  ChartBarSquareIcon,
  CheckBadgeIcon,
  CheckIcon,
  ClipboardDocumentCheckIcon,
  LockClosedIcon,
  ShieldCheckIcon,
  SparklesIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { NavLink } from "react-router-dom";
import CTA from "../../assets/images/cta.webp";
import WorkFlow from "../../assets/images/work_flow.webp";
import UserInterviews from "../../assets/images/design/user-research/user-interviews.webp";
import Surveys from "../../assets/images/design/user-research/surveys.webp";
import UsabilityTesting from "../../assets/images/design/user-research/usability-testing.webp";
import PersonalDevelopment from "../../assets/images/design/user-research/personal-development.webp";
import { Helmet } from 'react-helmet';
import Svg from "../svg";

function Userresearch() {
  const ServiceCard = ({ icon, title, details }) => {
    return (
      <div className="mb-3 md:mb-3 xl:mb-5 bg-white shadow-md hover:shadow-xl md:px-0 xl:px-0 border-2  transition duration-300 ease-in-out transform hover:scale-105">
        <div className="md:flex justify-start mb-4 items-start top-1/2">
          <img src={icon} alt="Service Icon" className="w-full" />
        </div>
        <div className="ml-2 mr-2">
          <h1 className="ml-2 mr-2 mb-2 text-2xl font-light text-black text-left">
            {title}
          </h1>
          <p className="ml-2 mr-2 mb-6  text-black">{details}</p>
        </div>
      </div>
    );
  };

  return (
    <main className="design_user_research">
      <Helmet>
        <title>User Research Services | TechSmew Technologies</title>
        
        {/* Meta Description: TechSmew Technology conducts user research to enhance user experiences. Gain insights with our user research experts. */}
        <meta name="description" content="TechSmew Technology conducts user research to enhance user experiences. Gain insights with our user research experts." />
        
        {/* Meta Keywords (for reference): User research, user insights, usability testing */}
        {/* Note: Meta keywords are not used by major search engines for SEO. */}
        <meta name="keywords" content="User research, user insights, usability testing" />
      </Helmet>
      <div className="relative overflow-hidden bg-[#492196]  pt-[120px] md:pt-[130px] lg:pt-[160px]">
        <div className="max-w-7xl mx-auto">
          <div className=" flex flex-wrap items-center">
            <div className="w-full px-4">
              <div className="hero-content mx-auto text-center">
                <h1 className="mb-8 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug">
                Crafting Exceptional Designs Through In-Depth User Research
                </h1>
                <p className="mb-12 md:ml-10 px-4 mx-auto font-normal text-base text-[#e4e4e4] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed">
                Leverage our extensive user research to uncover valuable insights. Empower your design decisions with in-depth research of your target audience, making your creatives more impactful and powerful.
                </p>
              </div>
            </div>
          <Svg/>

            <div className="flex items-center justify-center absolute bottom-0 z-20  bg-gradient-to-r from-indigo-500 to-orange-500 px-2 md:px-6 pt-1 md:py-2 rounded-t-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8 mr-10">
              <nav className="flex " aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                  <li>
                    <div className="flex items-center">
                      <NavLink
                        className="text-[9px] md:text-sm font-medium text-gray-100 hover:text-[#f89539] "
                        href="/"
                      >
                        Home
                      </NavLink>
                    </div>
                  </li>
                  <li aria-current="page" className="h-full">
                    <div className="flex items-center text-white ">
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-medium md:ml-2">
                        Solutions
                      </span>
                    </div>
                  </li>
                  <li aria-current="page">
                    <NavLink
                      to="/solutions/design"
                      className="flex items-center text-white"
                    >
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-medium md:ml-2 ">
                        Design
                      </span>
                    </NavLink>
                  </li>
                  <li aria-current="page">
                    <div className="flex items-center  text-white">
                      /
                      <span className="ml-1 text-[9px] md:text-sm font-medium  md:ml-2 ">
                        UR
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-white">
        <div className="max-w-7xl mx-auto my-6 md:pb-18">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8">
            <h1 className="text-[2rem] text-black text-left font-light mb-1">
              Seamless User Research Services
            </h1>
            <div className="flex flex-nowrap flex-cols-3 mb-3 ">
              <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
              <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
              <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
            </div>
            <p className="text-base font-normal text-black">
              Unlock the power of user research and creative design with our
              professional website content services. Our experts blend the
              insights of user research with innovative design approaches,
              creating a digital experience that captivates and converts.
            </p>
          </div>
          <div className="py-6 md:py-8 mx-6 grid grid-cols-1 xl:grid-cols-4 md:grid-cols-2 gap-6 xl:mx-0 lg:mx-10 md:mx-8">
            <ServiceCard
              icon={UserInterviews}
              title="User Interviews"
              details="Conduct in-depth interviews of target audience to gather qualitative data, preferences and pain points."
            />
            <ServiceCard
              icon={Surveys}
              title="Surveys"
              details="Preparing questionnaires to gather quantitative data and analyse patterns and trends."
            />
            <ServiceCard
              icon={UsabilityTesting}
              title="Usability Testing"
              details="Receive real-time feedback from users to gather insights and improvise."
            />
            <ServiceCard
              icon={PersonalDevelopment}
              title="Personal Development"
              details="Utilise demographic data to develop a user persona that models your target audience."
            />
          </div>
        </div>
      </section>
      <section className="bg-gray-100 py-10">
        <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-1 gap-0 lg:gap-4 ">
          <div className="md:flex justify-start md:pr-4 xl:pl-0 items-center rounded-lg mx-6 xl:mx-0 lg:mx-10 md:mx-8">
            <div className="col-span-1">
              <img src={WorkFlow} alt="work flow" className="w-full" />
              <div className="pt-4">
                <h1 className="text-[2rem] font-light mb-1">Our Workflow</h1>
              </div>
              <div className="flex flex-nowrap flex-cols-3">
                <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
                <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
                <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
              </div>
              <div className="py-3">
                <h1 className=" text-black">
                  Define requirements and audience, plan research with
                  methodologies, conduct user-focused activities for data
                  collection, analyse insights to discern trends and provide
                  design-enhancing recommendations for impactful outcomes.
                </h1>
              </div>
            </div>
          </div>
          <div className="col-span-2 mx-12 xl:mx-0 lg:mx-10 md:mx-16 pt-8 xl:ml-10">
            <ol className="relative text-black border-l border-gray-300 dark:border-gray-700 ">
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <CheckIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Gather your requirements and target audience to determine
                  appropriate research methods and techniques.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <ShieldCheckIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Develop a research plan by outlining methodology and user
                  enrollment strategy to receive real-time feedback.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <LockClosedIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Conduct surveys, user interviews and usability testing to
                  accumulate both qualitative and quantitative data.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <ChartBarSquareIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Gain comprehensive insights into target audience preferences by
                  analysing data to identify trends, patterns and behaviours.
                </p>
              </li>
              <li className="mb-10 ml-6">
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#e75828] rounded-full -left-4 ring-4 ring-[#e75828]">
                  <div className="m-4">
                    <SparklesIcon className="h-6 w-6 text-white stroke-[1.2]" />
                  </div>
                </span>
                <p className=" pl-2 text-black ">
                  Prepare a detailed report on findings and compile design
                  suggestions to the design team for effective feedback
                  employment.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="max-w-7xl mx-auto my-6 md:pb-18">
          <div className="mx-6 xl:p-0 pt-6 xl:mx-0 lg:mx-10 md:mx-8">
            <h1 className="text-[2rem] text-black text-left mb-1 font-light">
              Our Unique Value Proposition
            </h1>
            <div className="flex flex-nowrap flex-cols-3 mb-3 ">
              <div className="py-1 w-10 border-b-2 border-[#e75828] "></div>
              <div className="py-1 w-10 border-b-2 border-[#6b7280] "></div>
              <div className="py-1 w-10 border-b-2 border-[#492196] "></div>
            </div>
            <p className="text-base font-normal text-black">
              Elevate your digital experience with our unparalleled, unique value proposition. Explore a world of innovation, where mobile app design transforms into an art of creative design, setting new standards for user engagement and visual delight.  
            </p>
          </div>
          <div className="py-6 md:py-10 grid grid-cols-1 md:grid-cols-3 gap-6 mx-6 lg:mx-10 md:mx-8 xl:mx-0">
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#492196] rounded-lg w-12 h-12">
                  <CheckBadgeIcon className="h-8 w-8 text-white stroke-[1]" />
                </div>
              </div>
              <h3 className="text-2xl font-light text-black mb-2 text-center">
                Holistic Approach
              </h3>
              <p className="text-black font-normal text-base  text-center">
                We employ all means of collecting data to gain comprehensive
                insight into user behavioural patterns.
              </p>
            </div>
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#492196] rounded-lg w-12 h-12">
                  <ClipboardDocumentCheckIcon className="h-8 w-8 text-white stroke-[1]" />
                </div>
              </div>
              <h3 className="text-2xl font-light text-black mb-2 text-center">
                Data-Driven design Decisions
              </h3>
              <p className="text-black font-normal text-base  text-center">
                Our design decisions are driven and backed by data leading to
                creation of designs resonating with the audience.
              </p>
            </div>
            <div className="bg-white  p-6 shadow-md border-y-[1px]  transition duration-300 ease-in-out transform hover:scale-95">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center mb-4 bg-[#492196] rounded-lg w-12 h-12">
                  <UserIcon className="h-8 w-8 text-white stroke-[1]" />
                </div>
              </div>
              <h3 className="text-2xl font-light text-black mb-2 text-center">
                User Research Expertise
              </h3>
              <p className="text-black font-normal text-base  text-center">
                Our team leverages leading tools and technology to gather
                precise information.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-100">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 py-10 mx-6 lg:mx-10 md:mx-8 xl:mx-0">
            <div className="justify-center items-center pt-5 md:col-span-2">
              <div className="pt-5 pb-3 pb-md-5 md:px-0 text-center md:text-left">
                <p className="text-[1.5rem] font-normal text-black mb-3">
                  Ready to get started?
                </p>
                <h2 className="text-[2rem] font-normal text-black pb-3">
                Understanding Users, Informing Design
                {" "}
                Gaining insights to shape products that truly resonate.
                </h2>
                <NavLink
                  to="/contact"
                  className="bg-[#0d6efd] hover:bg-[#0d6dfdb7] mt-6 mb-2 text-white py-3 text-base px-6 inline-block transition duration-300 ease-in-out rounded-full"
                >
                 Research Now
                </NavLink>
              </div>
            </div>
            <div className="md:flex justify-end items-center md:col-span-1">
              <img src={CTA} alt="CTA" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Userresearch;
