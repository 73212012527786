import React from 'react'
import { Link } from 'react-router-dom';

const ServiceCardPrimary = ({ image, title, details , path}) => {
    return (
        <div className=" relative bg-white shadow-md hover:shadow-xl md:px-0 xl:px-0 border-2 transition duration-100 ease-in-out transform hover:scale-105">
            <div className="md:flex justify-start mb-2 items-start top-1/2">
                <img src={image} alt="Case Studies Icon" className="w-full" />
            </div>
            <div className="ml-2 mr-2">
                <h5 className="mb-2 text-[2rem] font-light tracking-tight text-gray-900 dark:text-white leading-none">
                    {title}
                </h5>
                <p className=" mb-12 md:mb-8 lg:mb-16 text-gray-700  line-clamp-2">
                    {details}
                </p>
            </div>
            <Link
            to={`/case-studies/blog/${path}`}>
            <button className="absolute bottom-3 lg:right-3 lg:bottom-3 right-3 md:bottom-1 md:right-1 bg-[#492196] text-white
            hover:bg-[#E75828] hover:text-white active:bg-[#E75828] active:text-white  rounded-full py-1 px-2 lg:px-4  lg:py-2 " >
                Read More...
            </button>
        </Link>
        </div>
    );
};
export default ServiceCardPrimary;

// <button className=" bg-[#492196] text-white rounded-full text-sm xl:text-base py-1 px-4 lg:py-2 lg:px-6
// hover:bg-[#E75828] hover:text-white active:bg-[#E75828] active:text-white ">