import React, { useRef, useState, useEffect, useCallback } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { NavLink } from "react-router-dom";
import '../index.css'

function Navigation() {
  const dropdownRef = useRef(null);
  const dropdownSolutionRef = useRef(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleHamburger = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const ToggleProducts = () => {
    if (activeDropdown === "products") {
      setActiveDropdown(null);
    } else {
      setActiveDropdown("products");
    }
  };

  const toggleSolution = () => {
    if (activeDropdown === "solution") {
      setActiveDropdown(null);
    } else {
      setActiveDropdown("solution");
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const closeDropdown = useCallback((value) => {
    if (value !== undefined) {
      scrollToTop();
    }
    setActiveDropdown(null);
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        dropdownSolutionRef.current &&
        !dropdownSolutionRef.current.contains(event.target)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [closeDropdown]);

  return (
    <main className="about">
      <div className="z-30 w-full bg-white fixed top-0">
        <div className="max-w-7xl mx-auto">
          <div className="items-center">
            <nav className="navbar fixed-top">
              <div className="lg:mx-10 xl:mx-0 md:mx-9 mx-6 grid grid-cols-5">
                <div className="flex col-span-1">
                  <NavLink to="/">
                    <img
                      src="/assets/images/logo.svg"
                      alt="logo"
                      className="min-w-[150px] pt-4 pb-4"
                    />
                  </NavLink>
                </div>

                <div
                  className="col-end-10 col-span-2 !justify-items-end mt-3 w-[3.25rem] lg:hidden toggle_button"
                  onClick={toggleHamburger}
                >
                  <div className="py-[0.5rem] px-[0.75rem] space-y-2 bg-[#492196] rounded shadow justify-items-end">
                    <span className="block w-7 h-0.5 bg-white animate-pulse"></span>
                    <span className="block w-7 h-0.5 bg-white animate-pulse"></span>
                    <span className="block w-7 h-0.5 bg-white animate-pulse"></span>
                  </div>
                  <div className={`hamburger-icon ${isMenuOpen ? "open" : ""}`} />
                </div>

                <div className={` space-x-0 lg:flex col-span-5  lg:col-span-4 xl:justify-end lg:justify-end ${isMenuOpen ? "block" : "hidden"
                  }`} >
                  <ul className="space-x-0  pb-2 flex-nowrap lg:flex xl:space-x-10 lg:space-x-6 items-center mx-auto">
                    <li className="">
                      <NavLink
                        to="/about-us"
                        className="font-semibold text-black hover:text-[#ff7c4e] hover:font-blod"
                        onClick={(e) => closeDropdown(e.target)}
                      >
                        About
                      </NavLink>
                    </li>
                    <li className="group relative " ref={dropdownRef}>
                      <span
                        className="text-black font-semibold hover:text-[#ff7c4e] hover:font-blod cursor-pointer flex items-center"
                        onClick={ToggleProducts}
                      >
                        <NavLink
                          to="/products"
                          className="text-black font-semibold hover:text-[#ff7c4e] hover:font-blod"
                          onClick={(e) => closeDropdown(e.target)}
                        >
                          Products
                        </NavLink>
                        {activeDropdown === "products" ? (
                          <HiChevronUp className="ml-1 h-6 w-6 text-black pt-1 " />
                        ) : (
                          <HiChevronDown className="ml-1 h-6 w-6 text-black pt-1" />
                        )}
                      </span>
                      <ul className={`relative lg:absolute ${activeDropdown === "products" ? "block" : "hidden"
                        } bg-white !pointer-events-noneshadow-sm mt-0 py-0  md:mt-2 md:py-3 border-b-4 border-[#492196]  rounded-md `}
                        style={{
                          overflowY: "auto",
                          marginTop: "10px",
                        }}>
                        <li>
                          <NavLink
                            to="/products/neptune"
                            className="block px-6 py-2 text-black hover:bg-gray-100"
                            onClick={(e) => closeDropdown(e.target)}
                          >
                            Neptune
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/products/asteroid"
                            className="block px-6 py-2 text-black hover:bg-gray-100 "
                            onClick={(e) => closeDropdown(e.target)}
                          >
                            Asteroid
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li
                      className="group relative w-auto"
                      ref={dropdownSolutionRef}
                    >
                      <span
                        className="font-semibold text-black hover:text-[#ff7c4e] hover:font-blod  cursor-pointer flex items-center"
                        onClick={toggleSolution}
                      >
                        Solutions
                        {activeDropdown === "solution" ? (
                          <HiChevronUp className="ml-1 h-6 w-6 text-black pt-1" />
                        ) : (
                          <HiChevronDown className="ml-1 h-6 w-6 text-black pt-1" />
                        )}
                      </span>

                      <div
                        className={`relative lg:fixed ${activeDropdown === "solution" ? "block" : "hidden"
                          } bg-white mt-2 py-0 shadow-md border-b-4 border-[#492196] w-full left-0 `}
                        style={{
                          overflowY: "auto",
                          maxHeight: "90vh",
                          marginTop: "10px",
                        }}
                      >
                        <div className="grid grid-cols-1 gap-4 p-2 lg:p-6 lg:grid-cols-5 md:grid-cols-3">
                          <div>

                            <div className="font-semibold text-gray-700 pt-2">
                              <NavLink
                                to="solutions/design"
                                onClick={(e) => closeDropdown(e.target)}
                              >
                                Design
                              </NavLink>
                              <div className="border-t-4 w-10 border-[#f89539] my-2"></div>
                            </div>

                            <ul>
                              <li>
                                <NavLink
                                  to="solutions/design/ui-ux-design"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  UI/UX Design
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/design/graphic-design"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Graphic Design
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/design/web-design"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Web Design
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/design/mobile-app-design"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Mobile App Design
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/design/user-research"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  User Research
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/design/prototyping"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Prototyping
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/design/interaction-design"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Interaction-Design
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/design/branding-and-identity-design"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Branding and Identity Design
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/design/usability-consulting"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Usability Consulting
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/design/design-strategy"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Design Strategy
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/design/visual-prototyping"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Visual Prototyping
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/design/design-review-and-qa"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Design Review and QA
                                </NavLink>
                              </li>
                            </ul>

                          </div>


                          <div>

                            <div className="font-semibold text-gray-700">
                              <NavLink
                                to="solutions/it-consulting"
                                className="block text-black  hover:text-gray-800"
                                onClick={(e) => closeDropdown(e.target)}
                              >
                                IT Consulting
                              </NavLink>
                            </div>

                            <div className="border-t-4 w-10 border-[#f89539] my-2"></div>

                            <ul>
                              <li>
                                <NavLink
                                  to="solutions/it-consulting/ai-and-automation"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  AI and Automation
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/it-consulting/digital-strategy-and-transformation"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Digital Strategy & Transformation
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/it-consulting/digital-operations"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Digital Operations
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/it-consulting/big-data-and-analytics"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Big Data and Analytics
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/it-consulting/erp-epm-crm-platform-integrations"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  ERP/EPM/CRM Platform Integrations
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/it-consulting/ecommerce-web-portals"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Ecommerce / Web Portals
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/it-consulting/product-development-and-maintenance"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Product development & maintenance
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/it-consulting/data-and-analytics"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Data Analytics
                                </NavLink>
                              </li>
                            </ul>

                          </div>

                          <div>
                            <ul>
                              <li className="pt-6">
                                <NavLink
                                  to="solutions/it-consulting/cloud-security"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Cloud Security
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/it-consulting/quality-engineering-and-assurance"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Quality Engineering & Assurance
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/it-consulting/enterprise-applications"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Enterprise applications
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/it-consulting/infrastructure-management"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Infrastructure Management
                                </NavLink>
                              </li>
                            </ul>

                            <div className="font-semibold text-gray-700 pt-2">
                              <NavLink
                                to="solutions/platform"
                                onClick={(e) => closeDropdown(e.target)}
                              >
                                Platform
                              </NavLink>
                              <div className="border-t-4 w-10 border-[#f89539] my-2"></div>
                            </div>

                            <ul>
                              <li>
                                <NavLink
                                  to="/solutions/platform/aws-cloud-services"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  AWS Cloud Services
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/solutions/platform/google-cloud-services"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Google Cloud Services
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/solutions/platform/microsoft-azure"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Microsoft Azure
                                </NavLink>
                              </li>
                            </ul>
                          </div>

                          <div>

                            <div className="font-semibold text-gray-700 pt-2">
                              <NavLink
                                to="solutions/digital-marketing"
                                onClick={(e) => closeDropdown(e.target)}
                              >
                                Digital Marketing
                              </NavLink>
                              <div className="border-t-4 w-10 border-[#f89539] my-2"></div>
                            </div>

                            <ul>
                              <li>
                                <NavLink
                                  to="solutions/digital-marketing/brand-equity-and-growth-optimization"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Brand Equity and Growth Optimization
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/digital-marketing/brand-strategy-and-development"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Brand Strategy and Development
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/solutions/digital-marketing/market-research-and-analysis"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Market Research and Analysis
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/solutions/digital-marketing/digital-marketing-campaigns"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Digital Marketing Campaigns
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/solutions/digital-marketing/search-engine-optimization"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Search Engine Optimization (SEO)
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/solutions/digital-marketing/pay-per-click-advertising"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Pay-Per-Click (PPC) Advertising
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/solutions/digital-marketing/content-marketing-and-copywriting"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Content Marketing and Copywriting
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/solutions/digital-marketing/email-marketing-campaigns"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Email Marketing Campaigns
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/solutions/digital-marketing/influencer-marketing-and-partnerships"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Influencer Marketing and Partnerships
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/solutions/digital-marketing/brand-identity-design-and-development"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Brand Identity Design and Development
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/solutions/digital-marketing/logo-design-and-branding"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Logo Design and Branding
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/solutions/digital-marketing/creative-advertising-campaigns"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Creative Advertising Campaigns
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/solutions/digital-marketing/public-relations-and-media-relations"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Public Relations (PR) and Media Relations
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/solutions/digital-marketing/event-marketing-and-promotions"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Event Marketing and Promotions
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/solutions/digital-marketing/brand-monitoring-and-reputation-management"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Brand Monitoring and Reputation Management
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/solutions/digital-marketing/social-media-marketing"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Social Media Marketing (SMM)
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                          <div>
                            <div className="font-semibold text-gray-700 pt-2">
                              <NavLink
                                to="solutions/it-infrastructure"
                                onClick={(e) => closeDropdown(e.target)}
                              >
                                IT Infrastructure
                              </NavLink>
                              <div className="border-t-4 w-10 border-[#f89539] my-2"></div>
                            </div>

                            <ul>
                              <li>
                                <NavLink
                                  to="solutions/it-infrastructure/procurement"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Procurement
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/it-infrastructure/help-desk-support"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Help Desk Support
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/it-infrastructure/network-design-and-implementation"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Network Design and Implementation
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/it-infrastructure/security-consulting"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Security Consulting
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/it-infrastructure/hardware-support-and-maintenance"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Hardware Support and Maintenance
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/it-infrastructure/backup-and-disaster-recovery"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Backup and Disaster Recovery
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/it-infrastructure/it-asset-management"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  IT Asset Management
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/it-infrastructure/security-patch-management"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Security Patch Management
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/it-infrastructure/on-premise-server-management-and-support"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  On-Premise Server Management and Support
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="solutions/it-infrastructure/vendor-management"
                                  className="block text-black  hover:text-gray-800"
                                  onClick={(e) => closeDropdown(e.target)}
                                >
                                  Vendor Management
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li className="space-x-4">
                      <NavLink
                        to="case-studies"
                        className="font-semibold text-black hover:text-[#ff7c4e] hover:font-blod"
                        onClick={(e) => closeDropdown(e.target)}
                      >
                        Case Studies
                      </NavLink>
                    </li>


                    <li className="space-x-4">
                      <NavLink
                        to="/careers"
                        className="font-semibold text-black hover:text-[#ff7c4e] hover:font-blod md:ml-0"
                        onClick={(e) => closeDropdown(e.target)}
                      >
                        Careers
                      </NavLink>
                    </li>

                    <li className="space-x-4 xl:mr-[7rem] lg:mr-5 lg:mb-0 mb-4">
                      <NavLink
                        to="contact"
                        className="font-semibold text-black hover:text-[#ff7c4e] hover:font-blod "
                        onClick={(e) => closeDropdown(e.target)}
                      >
                        {/* Contact */}
                      </NavLink>
                    </li>

                  </ul>
                  <ul className="flex md:pb-6 lg:pl-0 pl-0 pb-6 lg:pb-0 flex-nowrap  col-span-2 items-center">
                    <li>
                      <NavLink
                        to="/contact"
                        className=" lg:ml-4 bg-orange-500 hover:bg-orange-700 text-white font-normal text-base py-2 px-6 rounded-full"
                      >
                        Get Started
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Navigation;
<link rel="icon" href="https://www.techsmew.com/favicon.svg"></link>